/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Service } from '@mojoapps1/mojoapps1common';
import { SVGUtil } from '../util/SVGUtil';

export interface IconInfo {
  /**
   * url to a successful svg icon
   */
  svgUrl?: string;
  fallback?: string;
}

/**
 * manages category and service icons
 */
@Injectable({
  providedIn: 'root',
})
export class IconsService {
  private _iconUrlCache: { [key: string]: string } = {};

  /**
   * the path in the default bucket where our icons are stored
   */
  public static readonly BUCKET_PATH: string = 'icons';

  /**
   * whether to use firebase storage for icon files or local embedded icons
   */
  private _useStorage: boolean = true;

  constructor(private storage: AngularFireStorage, private http: HttpClient) {}

  /**
   * get an icon url from firebase storage. resolves to null if it doesn't exist in the default bucket
   *
   * @param icon
   * @returns
   */
  async getIconInfo(icon: string) {
    let ret: IconInfo = {};

    // question mark icon
    // let failRet: IconInfo = {
    //   fallback: 'help-outline',
    // };

    // togl logo or question mark
    const fallbackName = 'help-outline';
    //'togl-only.svg', 'togl-logo.svg', 'on-only2.svg'
    const fallbackSvgUrl = await this.getIconStorageDownloadURL(
      'togl-only.svg'
    );

    const failRet: IconInfo = fallbackSvgUrl
      ? { svgUrl: fallbackSvgUrl }
      : { fallback: fallbackName };

    // no icon at all? fallback
    if (!icon) {
      return failRet;
    }

    // try to generate an svg url if it's an svg icon
    if (icon.toLowerCase().endsWith('.svg')) {
      if (this._useStorage) {
        // use firebase storage
        const url = await this.getIconStorageDownloadURL(icon);
        if (url) {
          ret.svgUrl = url;
          return ret;
        }
        return failRet;
      } else {
        // get the icon locally
        ret.svgUrl = 'assets/icons/' + icon;
        return ret;
      }
    }

    // treat an icon not ending with .svg as an ionic icon name
    ret.fallback = icon;
    return ret;
  }

  /**
   * get an icon url from firebase storage, or null if it doesn't exist in the default bucket
   *
   * @param icon
   * @returns
   */
  async getIconStorageDownloadURL(icon: string) {
    // await new Promise((resolve) => setTimeout(resolve, 10000));

    if (this._iconUrlCache[icon]) {
      return this._iconUrlCache[icon];
    }

    try {
      const url: string = await this.storage
        .ref(`${IconsService.BUCKET_PATH}/${icon}`)
        .getDownloadURL()
        .toPromise();
      this._iconUrlCache[icon] = url;
      return url;
    } catch (e) {
      // doesn't exist in firebase storage, return null
      console.log(`icon service: error: ${e}`);
      return null;
    }
  }

  /**
   * load an svg from a url
   * @param url
   * @returns
   */
  private async loadSVG(url: string, local: boolean = false) {
    // if local url, will need the following headers
    const headers = local
      ? new HttpHeaders()
          .set('Content-Type', 'text/xml')
          .append('Access-Control-Allow-Methods', 'GET')
          .append('Access-Control-Allow-Origin', '*')
          .append(
            'Access-Control-Allow-Headers',
            'Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method'
          )
      : null;

    return this.http
      .get(url, {
        headers,
        responseType: 'text',
      })
      .toPromise();
  }

  /**
   * create a map marker icon with an icon and color customization and return it as a data url
   * @param iconFileName the icon to put in the shell
   * @param shellColor color in hex code format with leading #
   * @param iconColor color in hex code format with leading #
   * @returns
   */
  async getMapMarkerIconDataUri(
    iconFileName?: string,
    shellColor: string = '#33CC33',
    iconColor: string = '#FFFFFF',
    forceInnerIconExists: boolean = false
  ): Promise<string> {
    // load and instantiate shell
    let shellSvgString = await this.loadSVG(
      'assets/icon/mapmarker-shell.svg',
      true
    );
    let shellSvg = SVGUtil.svgFromString(shellSvgString);

    // load and instantiate icon if provided
    let iconSvgString = null;
    let iconSvg = null;
    if (iconFileName) {
      const url = await this.getIconStorageDownloadURL(iconFileName);

      if (url) {
        // load inner icon
        iconSvgString = await this.loadSVG(url);
        iconSvg = SVGUtil.svgFromString(iconSvgString);
      } else if (forceInnerIconExists) {
        return null;
      }
    }

    // colorize the shell
    SVGUtil.colorizeSvg(shellSvg, [
      {
        id: 'use_marker_fill',
        color: shellColor,
        type: 'fill',
      },
    ]);

    // add icon if provided
    if (iconSvg) {
      SVGUtil.compositeSvgs(
        shellSvg,
        'use_icon_detail',
        iconSvg,
        'icon_detail'
      );

      // colorize the icon
      SVGUtil.colorizeSvg(shellSvg, [
        {
          id: 'use_icon_detail',
          type: 'fill',
          color: iconColor,
        },
      ]);
    }

    // make it into a data uri
    return SVGUtil.svgToDataUri(shellSvg);
  }

  /**
   * create a dual-color map marker icon with an icon and color customization and return it as a data url
   * @param iconFileName the icon to put in the shell
   * @param iconColor color in hex code format with leading #
   * @returns
   */
  async getDualColorMapMarkerIconDataUri(
    iconFileName?: string,
    iconColor: string = '#FFFFFF'
  ): Promise<string> {
    // load and instantiate dual-color shell
    let shellSvgString = await this.loadSVG(
      'assets/icon/mapmarker-shell-dual-color.svg',
      true
    );
    let shellSvg = SVGUtil.svgFromString(shellSvgString);

    // load and instantiate icon if provided
    let iconSvgString = null;
    let iconSvg = null;
    if (iconFileName) {
      // load inner icon
      const url = await this.getIconStorageDownloadURL(iconFileName);
      if (url) {
        iconSvgString = await this.loadSVG(url);
        iconSvg = SVGUtil.svgFromString(iconSvgString);
      }
    }

    // colorize the shell
    SVGUtil.colorizeSvg(shellSvg, [
      {
        id: 'use_layer1',
        color: '#33CC33',
        type: 'fill',
      },
      {
        id: 'use_layer2',
        color: '#3366CC',
        type: 'fill',
      },
    ]);

    // add icon if provided
    if (iconSvg) {
      SVGUtil.compositeSvgs(
        shellSvg,
        'use_icon_detail',
        iconSvg,
        'icon_detail'
      );

      // colorize the icon
      SVGUtil.colorizeSvg(shellSvg, [
        {
          id: 'use_icon_detail',
          type: 'fill',
          color: iconColor,
        },
      ]);
    }

    // make it into a data uri
    return SVGUtil.svgToDataUri(shellSvg);
  }
}
