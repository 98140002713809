/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';

/**
 * exposes the inapp browser capacitor plugin as an angular service
 */
@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  static privacyPolicy() {
    return Browser.open({ url: 'https://toglon.com/privacy/' });
  }

  static terms() {
    return Browser.open({ url: 'https://toglon.com/terms/' });
  }

  async openPrivacyPolicy() {
    await BrowserService.privacyPolicy();
  }

  async openTerms() {
    await BrowserService.terms();
  }

  async openFaq() {
    return Browser.open({ url: 'https://toglon.com/faq/' });
  }
}
