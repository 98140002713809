/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  IonInfiniteScroll,
  IonSearchbar,
  ModalController,
} from '@ionic/angular';
import {
  AverageRating,
  BizSamedaySlot,
  BizServiceInfo,
  Business,
  Category,
  CollectionNames,
  Service,
  UserData,
} from '@mojoapps1/mojoapps1common';
import { BackendService } from 'src/app/angular-services/backend.service';
import { PageBaseComponent } from 'src/app/pagebase.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { UIString } from 'src/app/lang/UIString';
import {} from 'google.maps';
import { Subscription } from 'rxjs';
import { RatingService } from 'src/app/angular-services/rating.service';
import { AvgBizRating } from '../avg-biz-rating/avg-biz-rating.component';
import { AlertService } from 'src/app/angular-services/alert.service';
import { FileLog } from 'src/app/angular-services/FileLog';
import { LocationService } from 'src/app/angular-services/location.service';

@Component({
  selector: 'modal-add-favorite',
  templateUrl: './modal-add-favorite.component.html',
  styleUrls: ['./modal-add-favorite.component.scss'],
})
export class ModalAddFavorite
  extends PageBaseComponent
  implements OnInit, OnDestroy {
  user: UserData;
  loading: boolean;

  @ViewChild('searchbar') searchBar: IonSearchbar;
  @ViewChild('infiniteScroll') private infiniteScroll: IonInfiniteScroll;
  businesses: Business[];
  searchValue: string;
  private batchSize: number = 15;
  private _bizCache: {
    [key: string]: Business;
  } = {};
  private _bizRatingSubs: {
    [key: string]: Subscription;
  } = {};
  bizRatings: {
    [key: string]: AverageRating;
  } = {};

  title: string;

  constructor(
    private modalCtrl: ModalController,
    private ratings: RatingService,
    backendService: BackendService,
    private alerts: AlertService,
    private filelog: FileLog,
    private location: LocationService
  ) {
    super(backendService, filelog);
    this._className = 'modal-add-favorite';
    this.loading = false;
  }

  protected async onUserReady(u: UserData): Promise<void> {
    this.log('userready');

    this.user = u;

    // title of form
    this.title = UIString.format('HEADER_ADD_FAVORITE');
  }

  protected clearBackendSubscriptions(): void {
    this.clearBizRatingSubscriptions();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  onSearchInput() {
    this.loading = this.searchBar.value != '';
  }

  async onSearchChange() {
    this.searchValue = this.searchBar.value;

    let searchValueExists = !(
      this.searchValue == null || this.searchValue == ''
    );

    this.log(
      `searchvaluexists=${searchValueExists}, value=${this.searchValue}`
    );

    if (searchValueExists) {
      // do the search

      this.loading = true;
      this.infiniteScroll.disabled = false;

      let list = await this.backendService.searchBusinessName(
        this.searchValue,
        null,
        this.batchSize
      );
      let list2 = await this.prepBizList(list);

      // subscribe to biz ratings for the new businesses
      for (const biz of list2) {
        this.subscribeBizRating(biz.businessID);
      }

      this.businesses = [...list2];
      this.loading = false;
    } else {
      this.clearBizRatingSubscriptions();
      this.businesses = [];
      this.loading = false;
    }
  }

  /**
   * add thumbnail and distance fields to biz.
   * @param biz
   * @param index
   * @returns
   */
  async prepBiz(biz: Business, index: number) {
    biz.id = biz.businessID;
    let tmp = { ...biz };
    biz = tmp;

    // determine distance
    biz.distance = this.location.getUserDistanceTo(biz.lat, biz.long);

    // determine which image to use for the business in the list
    // use uploaded image if provided, or use fallback image
    let thumbnailPlaceholder: string = this.backendService.getPlaceholderImageMapThumbnail(
      index
    );
    if (biz.uploadedMainImagePath) {
      // get download url for uploaded image
      biz.thumbnail = await this.backendService.getBizMainPhotoUrl(
        biz,
        thumbnailPlaceholder
      );
    } else {
      // use fallback image
      biz.thumbnail = thumbnailPlaceholder;
    }

    return biz;
  }

  /**
   * prep biz list for display.
   * @param list
   * @returns
   */
  async prepBizList(list: Business[]) {
    const result: Business[] = [];
    for (const [i, biz] of list.entries()) {
      result.push(await this.prepBiz(biz, i));
    }
    return result;
  }

  async searchMore() {
    if (this.businesses.length < 1) return;
    let lastBiz = this.businesses[this.businesses.length - 1];
    // console.dir(lastBiz);

    let prelist = await this.backendService.searchBusinessName(
      this.searchValue,
      lastBiz.name,
      this.batchSize
    );
    let list = await this.prepBizList(prelist);
    this.log('searchMore returned: ', list);

    // subscribe to biz ratings for the new businesses
    for (const biz of list) {
      this.subscribeBizRating(biz.businessID);
    }

    this.businesses = this.businesses.concat(list);
    if (list.length === this.batchSize) {
      this.infiniteScroll.complete();
    } else {
      this.infiniteScroll.disabled = true;
    }
  }

  handleInfiniteScroll() {
    this.searchMore();
  }

  async clickBusiness(b: Business) {
    const confirmation = await this.alerts.genericConfirm2(
      UIString.format('NOTIF_CONFIRM_ADD_FAVORITE', { bizname: b.name })
    );

    if (confirmation) {
      // dismiss and send chosen business to calling code
      await this.modalCtrl.dismiss(b, 'add');
    }
  }

  async loadBizFromCache(bizId: string) {
    // use from cache if possible
    if (this._bizCache[bizId]) {
      return this._bizCache[bizId];
    } else {
      this.log(`loading biz ${bizId} from backend`);

      const snap = await this.backendService
        .docRef<Business>(CollectionNames.BUSINESSES, bizId)
        .get()
        .toPromise();
      let biz: Business = snap.data() as Business;
      // save in cache
      this._bizCache[bizId] = biz;

      return biz;
    }
  }

  /**
   * subscribe to biz rating value changes
   * @param bizId
   */
  private subscribeBizRating(bizId: string) {
    if (!this._bizRatingSubs[bizId]) {
      this._bizRatingSubs[
        bizId
      ] = this.ratings
        .getBusinessRatingValueChangesRTDB(bizId)
        .subscribe((r) => (this.bizRatings[bizId] = r));
    }
  }

  private clearBizRatingSubscriptions() {
    for (const id in this._bizRatingSubs) {
      if (this._bizRatingSubs[id]) {
        this._bizRatingSubs[id].unsubscribe();
      }
    }
    this._bizRatingSubs = {};
  }
}
