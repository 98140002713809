/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { UserRole } from '@mojoapps1/mojoapps1common';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BackendService } from '../angular-services/backend.service';
import { FileLog } from '../angular-services/FileLog';

/**
 * ensures user is logged in
 */
@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(
    private backendService: BackendService,
    private router: Router,
    private auth: AngularFireAuth,
    private filelog: FileLog
  ) {}

  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

  /**
   * if user is logged in, return success. otherwise, return false and redirect to login page, passing along the url the user was trying to access.
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    this.filelog.log('loggedin.guard: canActivate');

    return new Observable<boolean | UrlTree>((subscriber) => {
      // take one auth event
      this.auth.authState.pipe(take(1)).subscribe((user) => {
        this.filelog.log(
          `loggedin.guard: authstate event, user exists: ${!!user}`
        );

        // no user, redirect to login
        if (!user) {
          // not logged in
          this.filelog.log(
            'loggedin.guard: no logged in user, redirecting to login, requested url is ' +
              state.url
          );
          this.backendService.setRedirectOnLogin(state.url);

          return subscriber.next(this.router.parseUrl('/login'));
        }

        // wait for user to load
        this.backendService.onUserReady().subscribe((u) => {
          this.filelog.log('loggedin.guard: user is logged in: ' + user.uid);
          return subscriber.next(true);
        });
      });
    });

    // return this.afAuth.authState.pipe(
    //   take(1),
    //   map((user) => {
    //     if (user) {
    //       // logged in!
    //       this.filelog.log('loggedin.guard: user is logged in: ' + user.uid);
    //       return true;
    //     }

    //     // not logged in
    //     this.filelog.log(
    //       'loggedin.guard: no logged in user, redirecting to login, requested url is ' +
    //         state.url
    //     );
    //     this.backendService.setRedirectOnLogin(state.url);
    //     return this.router.parseUrl('/login');
    //   })
    // );
  }
}
