/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import {
  AngularFireRemoteConfig,
  Parameter,
} from '@angular/fire/remote-config';
import { concat, defer, of, Subject } from 'rxjs';
import { FileLog } from './FileLog';

/**
 * handles remote/cloud config. also handles preferences in the RTDB.
 */
@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private _config: { [key: string]: Parameter };
  private _configSubjects: { [key: string]: Subject<Parameter> };

  /**
   * how often to poll device gps in milliseconds
   */
  public static readonly GPS_LOCAL_UPDATE_INTERVAL_MAP: string =
    'gpsLocalUpdateIntervalMap';
  /**
   * how often to poll device gps in milliseconds
   */
  public static readonly GPS_LOCAL_UPDATE_INTERVAL: string =
    'gpsLocalUpdateInterval';
  /**
   * how often to update user's gps location in realtime db
   */
  public static readonly GPS_DB_UPDATE_INTERVAL: string = 'gpsDbUpdateInterval';
  /**
   * maximum map range in miles
   */
  public static readonly MAX_MAP_RANGE: string = 'maxMapRange';
  /**
   * minimum map range in miles
   */
  public static readonly MIN_MAP_RANGE: string = 'minMapRange';

  /**
   * default map range in miles
   */
  public static readonly DEFAULT_MAP_RANGE: string = 'defaultMapRange';

  /**
   * whether to show a message on login in the consumer app
   */
  public static readonly CONSUMER_LOGIN_MESSAGE_ENABLED: string =
    'consumerLoginMessageEnabled';

  /**
   * consumer app login message content
   */
  public static readonly CONSUMER_LOGIN_MESSAGE_CONTENT: string =
    'consumerLoginMessageContent';

  /**
   * how long a now togl request takes to expire
   */
  public static readonly PENDING_GREEN_TOGL_EXPIRATION_TIME =
    'pendingGreenToglExpirationTime';

  /**
   * how long a sameday togl request takes to expire
   */
  public static readonly PENDING_BLUE_TOGL_EXPIRATION_TIME =
    'pendingBlueToglExpirationTime';

  constructor(
    private remoteConfig: AngularFireRemoteConfig,
    private filelog: FileLog,
    private rtdb: AngularFireDatabase
  ) {
    this._config = {};
    this._configSubjects = {};

    this.remoteConfig.changes.subscribe((param) => {
      const key = param.key;
      this._config[key] = param;
      if (this._configSubjects[key] == null) {
        this._configSubjects[key] = new Subject<Parameter>();
      }
      this.filelog.log(
        `config: ${param.key}=${param._value}, source=${param.getSource()}`
      );
      this._configSubjects[key].next(param);
    });
  }

  /**
   * get a config value
   * @param key
   * @returns
   */
  getConfigValue(key: string) {
    return this._config[key];
  }

  /**
   * get remote config value changes for a particular key
   * @param key
   * @returns
   */
  getConfigValueChanges(key: string) {
    if (!this._configSubjects[key]) {
      this._configSubjects[key] = new Subject<Parameter>();
    }

    // has the config value already been loaded at least once?
    if (this._config[key]) {
      // yes, add the current value in before the normal stream of changes
      return concat(
        defer(() => of(this._config[key])),
        this._configSubjects[key].asObservable()
      );
    } else {
      // no, just return the normal stream of changes for subscription
      return this._configSubjects[key].asObservable();
    }
  }
}
