/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  IonInfiniteScroll,
  IonSearchbar,
  IonTextarea,
  ModalController,
} from '@ionic/angular';
import {
  AverageRating,
  BizSamedaySlot,
  BizServiceInfo,
  Business,
  Category,
  CollectionNames,
  Service,
  UserData,
} from '@mojoapps1/mojoapps1common';
import { BackendService } from 'src/app/angular-services/backend.service';
import { PageBaseComponent } from 'src/app/pagebase.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { UIString } from 'src/app/lang/UIString';
import {} from 'google.maps';
import { Subscription } from 'rxjs';
import { RatingService } from 'src/app/angular-services/rating.service';
import { AvgBizRating } from '../avg-biz-rating/avg-biz-rating.component';
import { AlertService } from 'src/app/angular-services/alert.service';
import { FileLog } from 'src/app/angular-services/FileLog';
import { LocationService } from 'src/app/angular-services/location.service';

/**
 * show an image
 */
@Component({
  selector: 'modal-photo',
  templateUrl: './modal-photo.component.html',
  styleUrls: ['./modal-photo.component.scss'],
})
export class ModalPhoto extends PageBaseComponent implements OnInit, OnDestroy {
  user: UserData;
  loading: boolean;

  /**
   * url to image
   */
  @Input() url: string;

  constructor(
    private modalCtrl: ModalController,
    private ratings: RatingService,
    backendService: BackendService,
    private alerts: AlertService,
    private filelog: FileLog,
    private location: LocationService
  ) {
    super(backendService, filelog);
    this._className = 'modal-photo';
    this.loading = false;
  }

  protected async onUserReady(u: UserData): Promise<void> {
    this.log('userready');

    this.user = u;
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
