/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import {
  FavoritedByRTDB,
  RTDBPaths,
  ServicesWatchedByRTDB,
  UserFavoriteRTDB,
  WatchedServicesRTDB,
} from '@mojoapps1/mojoapps1common';

@Injectable({
  providedIn: 'root',
})
export class WatchedServicesService {
  constructor(
    private rtdb: AngularFireDatabase,
    private auth: AngularFireAuth
  ) {
    this.auth.authState.subscribe(async (u) => {
      if (u) {
        this._userId = u.uid;
      } else {
        this._userId = null;
      }
    });
  }

  private _userId: string;

  private _watchedPath() {
    return `${RTDBPaths.WATCHED_SERVICES}/${this._userId}`;
  }

  private _watchedByPath(serviceId: string) {
    return `${RTDBPaths.SERVICES_WATCHED_BY}/${serviceId}`;
  }

  /**
   * get changes of watched services
   */
  getWatchedValueChanges() {
    if (!this._userId) throw new Error('not initialized!');

    return this.rtdb
      .object<WatchedServicesRTDB>(this._watchedPath())
      .valueChanges();
  }

  /**
   * get changes of users that watched this service
   */
  getWatchedByValueChanges(serviceId: string) {
    if (!this._userId) throw new Error('not initialized!');

    return this.rtdb
      .object<ServicesWatchedByRTDB>(this._watchedByPath(serviceId))
      .valueChanges();
  }

  /**
   * add a service to the user's watched
   * @param serviceId
   * @returns
   */
  async addWatched(serviceId: string) {
    if (!this._userId) throw new Error('not initialized!');

    // add the biz id to favorites and the user id to favorited-by
    const watched = await this.rtdb.database
      .ref(this._watchedPath())
      .once('value');
    if (!watched.exists()) {
      const val: WatchedServicesRTDB = [serviceId];
      await watched.ref.set(val);
    } else {
      const val: WatchedServicesRTDB = watched.val() as WatchedServicesRTDB;
      if (!val.includes(serviceId)) {
        val.push(serviceId);
        val.sort();
        await watched.ref.set(val);
      }
    }

    const watchedBy = await this.rtdb.database
      .ref(this._watchedByPath(serviceId))
      .once('value');
    if (!watchedBy.exists()) {
      const val: ServicesWatchedByRTDB = [this._userId];
      await watchedBy.ref.set(val);
    } else {
      const val: ServicesWatchedByRTDB = watchedBy.val() as ServicesWatchedByRTDB;
      if (!val.includes(this._userId)) {
        val.push(this._userId);
        val.sort();
        await watchedBy.ref.set(val);
      }
    }
  }

  /**
   * remove a service from the user's watched
   * @param serviceId
   * @returns
   */
  async removeWatched(serviceId: string) {
    if (!this._userId) throw new Error('not initialized!');

    const watched = await this.rtdb.database
      .ref(this._watchedPath())
      .once('value');
    if (watched.exists()) {
      let val: WatchedServicesRTDB = watched.val() as WatchedServicesRTDB;
      if (val.includes(serviceId)) {
        val = val.filter((v) => v != serviceId);
        val.sort();
        await watched.ref.set(val);
      }
    }

    const watchedBy = await this.rtdb.database
      .ref(this._watchedByPath(serviceId))
      .once('value');
    if (watchedBy.exists()) {
      let val: FavoritedByRTDB = watchedBy.val() as FavoritedByRTDB;
      if (val.includes(this._userId)) {
        val = val.filter((v) => v != this._userId);
        val.sort();
        await watchedBy.ref.set(val);
      }
    }
  }
}
