/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  AverageRating,
  BizSamedaySlot,
  CollectionNames,
  DateUtil,
  Service,
  Togl,
  ToglDataItem,
  ToglDataType2,
  ToglInfo,
  ToglState,
  UserData,
} from '@mojoapps1/mojoapps1common';
import { BackendService } from '../../angular-services/backend.service';
import { UIString } from 'src/app/lang/UIString';
import { Subscription } from 'rxjs';
import { LocationService } from 'src/app/angular-services/location.service';
import { LoadingScreenService } from 'src/app/angular-services/loadingscreen.service';
import { ToglDataService } from 'src/app/angular-services/togldata.service';
import { RatingService } from 'src/app/angular-services/rating.service';
import { DateUtil2 } from 'src/app/util/DateUtil2';
import { AlertService } from 'src/app/angular-services/alert.service';
import { FileLog } from 'src/app/angular-services/FileLog';
import firebase from 'firebase/app';
import 'firebase/firestore';

@Component({
  selector: 'app-togl-card',
  templateUrl: './togl-card.component.html',
  styleUrls: ['./togl-card.component.scss'],
})
export class ToglCardComponent implements OnInit, OnDestroy {
  toglDataDisplay: ToglDataItem[];

  service: Service;
  user: UserData;

  distance: number;
  dateLabel: string;
  distanceLabel: string;
  pendingExpirationLabel: string;
  pendingExpirationColor: string;

  header: string;
  slotTime: string;
  slotStartLabel: string;

  /**
   * template variable, whether to prompt the user to submit a rating
   */
  needsRating: boolean;
  /**
   * template variable, value of the rating widget
   */
  rating: number;
  /**
   * template variable, average rating of the business from this togl
   */
  avgRatingLabel: string;
  avgRating: AverageRating;

  /**
   * template variable, main card content
   */
  mainContent: string;

  /**
   * subscription to back-end data
   */
  private _subscription: Subscription;

  /**
   * template variable, color of stars in rating widget
   */
  starColor: string = '#3366cc';

  private _refreshInterval;

  constructor(
    private backendService: BackendService,
    private location: LocationService,
    private loadingScreen: LoadingScreenService,
    private toglData: ToglDataService,
    private ratingService: RatingService,
    private alerts: AlertService,
    private filelog: FileLog
  ) {}

  /**
   * the togl object for the component to show
   */
  @Input() set toglItem(val: ToglInfo) {
    this._toglItem = { ...val };

    if (this._toglItem.bizReceivedRating != null) {
      this.needsRating = false;
      this.rating = this._toglItem.bizReceivedRating;
      this.filelog.log('togl-card: set rating to ' + this.rating);
    } else {
      this.needsRating = true;
      this.rating = 0;
    }

    const preConfirmStates = [
      ToglState.DENIED,
      ToglState.USER_PENDING,
      ToglState.EXPIRED,
    ];
    const isPostConfirm = !preConfirmStates.includes(this._toglItem.state);

    if (this._toglItem.toglData) {
      // filter togl data for display
      this.toglData
        .filterToglData2(val.toglData, isPostConfirm)
        .then((data) => {
          this.toglDataDisplay = data;
        });
    } else {
      this.toglDataDisplay = null;
    }

    // unsub if needed and resub
    this.manageBackendSubscriptions(false);
    this.manageBackendSubscriptions(true);

    this.refreshView();
  }
  get toglItem(): ToglInfo {
    return this._toglItem;
  }
  private _toglItem: ToglInfo;

  /**
   * initialize component
   */
  ngOnInit() {
    this.refreshView();
  }

  /**
   * destroy component
   */
  ngOnDestroy() {
    this.manageBackendSubscriptions(false);
  }

  /**
   * setup or tear down backend subscriptions
   * @param value
   */
  private manageBackendSubscriptions(value: boolean) {
    if (value) {
      // subscribe to rating changes
      if (!this._subscription) {
        this._subscription = this.ratingService
          .getBusinessRatingValueChangesRTDB(this._toglItem.businessId)
          .subscribe((r) => {
            this.avgRating = r;
          });

        // only subscribe to position updates if it's not a mobile togl
        if (!this._toglItem.serviceAddress) {
          this._subscription = this.location
            .getPositionUpdates()
            .subscribe((userPosition) => this.updateDistance());
        }

        // refresh the view every so often so the time is kept up to date
        this._refreshInterval = setInterval(() => this.refreshView(), 10000);
      }
    } else {
      // unsubscribe
      if (this._subscription) {
        this._subscription.unsubscribe();
        this._subscription = null;
        clearInterval(this._refreshInterval);
        this._refreshInterval = null;
      }
    }
  }

  /**
   * re-render the ui
   */
  refreshView() {
    console.log('togl-card: refreshView');
    this.service = this._toglItem.service;
    this.user = this._toglItem.user;

    this.dateLabel = this.backendService.getToglinfoDateLabel(
      this._toglItem,
      true
    );

    if (this._toglItem.state == ToglState.USER_PENDING) {
      console.log(this._toglItem._pendingExpirationSeconds);
      let pendingExpirationMillis =
        this._toglItem.createdDate.valueOf() +
        (this._toglItem._pendingExpirationSeconds || 0) * 1000;
      let diff = pendingExpirationMillis - Date.now();
      if (diff > 0) {
        this.pendingExpirationLabel =
          'TOGL request expires in ' +
          this.backendService.millisecondsToHumanReadable(diff);
        this.pendingExpirationColor =
          diff <= 1000 * 5 * 60 ? 'danger' : 'primary';
      }
    }

    this.updateDistance();

    this.header = this._toglItem.service.title;
    if (this._toglItem.samedaySlot) {
      this.slotTime = this.generateTimeslotLabel(this._toglItem.samedaySlot);
      this.slotStartLabel = DateUtil2.formatTime(
        this._toglItem.samedaySlot.start
      );
    }

    switch (this._toglItem.state) {
      case ToglState.USER_PENDING:
        this.mainContent = UIString.format('TEXT_TOGL_DETAIL_PENDING', {
          bizname: this._toglItem.business.name,
        });
        break;
      case ToglState.CONFIRMED:
        if (!this._toglItem.samedaySlot) {
          // regular TOGL

          if (!this._toglItem.serviceAddress) {
            // regular service
            this.mainContent = UIString.format('TEXT_TOGL_DETAIL_CONFIRMED', {
              bizname: this._toglItem.business.name,
            });
          } else {
            // mobile service
            this.mainContent = UIString.format(
              'TEXT_TOGL_DETAIL_CONFIRMED_MOBILE',
              {
                address: this._toglItem.serviceAddress,
                bizname: this._toglItem.business.name,
              }
            );
          }
        } else {
          // same-day TOGL

          if (!this._toglItem.serviceAddress) {
            // regular service
            this.mainContent = UIString.format(
              'TEXT_TOGL_DETAIL_CONFIRMED_SAMEDAY',
              {
                bizname: this._toglItem.business.name,
                time: this.slotStartLabel,
              }
            );
          } else {
            // mobile service
            this.mainContent = UIString.format(
              'TEXT_TOGL_DETAIL_CONFIRMED_SAMEDAY_MOBILE',
              {
                address: this._toglItem.serviceAddress,
                bizname: this._toglItem.business.name,
                time: this.slotStartLabel,
              }
            );
          }
        }
        break;
      case ToglState.COMPLETED:
        this.mainContent = UIString.format('TEXT_TOGL_DETAIL_COMPLETED');
        break;
      case ToglState.TAKEN:
        this.mainContent = UIString.format('TEXT_TOGL_DETAIL_TAKEN', {
          bizname: this._toglItem.business.name,
        });
        break;
      case ToglState.USER_CANCELED:
        this.mainContent = UIString.format('TEXT_TOGL_DETAIL_USER_CANCELED');
        break;
      case ToglState.USER_CANCELED_ATFAULT:
        this.mainContent = UIString.format(
          'TEXT_TOGL_DETAIL_USER_CANCELED_ATFAULT',
          { bizname: this._toglItem.business.name }
        );
        break;
      case ToglState.DENIED:
        this.mainContent = UIString.format('TEXT_TOGL_DETAIL_DECLINED');
        break;
      case ToglState.CANCELED_ATFAULT:
        this.mainContent = UIString.format(
          'TEXT_TOGL_DETAIL_CANCELED_ATFAULT',
          { bizname: this._toglItem.business.name }
        );
        break;
      case ToglState.EXPIRED:
        this.mainContent = UIString.format('TEXT_TOGL_DETAIL_EXPIRED');
        break;
      case ToglState.NOSHOW_ATFAULT:
        this.mainContent = UIString.format('TEXT_TOGL_DETAIL_NOSHOW_ATFAULT');
        break;
    }
  }

  /**
   * update the distance label. only shown if the togl is in an active state and isMobile == false
   * @returns
   */
  private updateDistance() {
    if (!this._toglItem) return;

    this.distance = this.location.getUserDistanceTo(
      this._toglItem.business.lat,
      this._toglItem.business.long
    );
    this.distanceLabel = isNaN(this.distance)
      ? 'Distance unknown'
      : this.distance.toFixed(1) + UIString.format('LABEL_RANGE_MILES');
  }

  /////////////////////
  // button handlers //
  /////////////////////

  /**
   * cancel togl button clicked
   * @param toglState
   */
  async onClickCancel(toglState) {
    this.filelog.log('togl-card: onClickCancel: ' + toglState);

    // check if they have location permissions!
    if (!this.location.locationPermitted) {
      const permitted = await this.location.initialize();
      if (!permitted) {
        return;
      }
    }

    const header: string = this._toglItem.samedaySlot
      ? UIString.format('HEADER_CONFIRM_CANCEL_TOGL_SAMEDAY', {
          service: this._toglItem.service.title,
          time: this.slotStartLabel,
        })
      : UIString.format('HEADER_CONFIRM_CANCEL_TOGL', {
          service: this._toglItem.service.title,
        });

    if (toglState === ToglState.CONFIRMED) {
      // if the togl is confirmed already, they have to give a cancelation reason
      const msg: string = UIString.format(
        'NOTIF_CONFIRM_CANCEL_RESERVED_TOGL',
        {
          bizname: this._toglItem.business.name,
        }
      );

      const result = await this.alerts.yesNoTextInput(msg, header);
      this.filelog.log('yes/no box returned', result);

      if (result.confirmation) {
        if (!result.response) {
          // didn't provide a reason
          await this.alerts.alertOk(
            'You must provide a reason to cancel your TOGL after it has been confirmed.'
          );
        } else {
          // cancel the togl and include the reason
          await this.cancelToglAtFault(result.response);
        }
      }
    } else {
      // togl not confirmed yet, no reason required for canceling
      const msg: string = UIString.format('NOTIF_CONFIRM_CANCEL_TOGL', {
        bizname: this._toglItem.business.name,
      });

      const confirmation = await this.alerts.genericConfirm2(msg, header);
      if (confirmation) {
        // the user confirmed it, cancel the togl
        await this.cancelTogl();
      }
    }
  }

  /**
   * cancel a togl pre-confirmation
   */
  private async cancelTogl() {
    console.log(JSON.stringify(this._toglItem));
    await this.loadingScreen.showLoading();

    await this.backendService
      .docRef<Togl>(CollectionNames.TOGLS, this._toglItem.id)
      .update({
        state: ToglState.USER_CANCELED,
        completedTimestamp: firebase.firestore.FieldValue.serverTimestamp() as any,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp() as any,
      });

    // await this.backendService.setToglUserCanceled(this._toglItem);
    await this.loadingScreen.hideLoading();

    await this.alerts.alertOk(UIString.format('NOTIF_TOGL_CANCELED_USER'));
  }

  /**
   * cancel a togl post-confirmation with a reason
   */
  private async cancelToglAtFault(reason: string) {
    await this.loadingScreen.showLoading();
    await this.backendService.setToglUserCanceledAtFault(
      this._toglItem,
      reason
    );
    await this.loadingScreen.hideLoading();

    await this.alerts.alertOk(
      UIString.format('NOTIF_TOGL_CANCELED_USER_ATFAULT', {
        bizname: this._toglItem.business.name,
      })
    );
  }

  /**
   * template function when rating changes
   * @param rating
   * @returns
   */
  async onRatingChange(rating) {
    this.filelog.log('togl-card: changed rating: ', rating);

    if (rating < 0 || rating > 5) return;

    const confirmation = await this.alerts.genericConfirm2(
      UIString.format('NOTIF_CONFIRM_RATING', {
        bizname: this._toglItem.business.name,
        rating: rating,
      }),

      UIString.format('HEADER_SAVE_RATING')
    );

    if (confirmation) {
      try {
        await this.loadingScreen.showLoading();
        await this.backendService.setToglBizRating(this._toglItem.id, rating);
        await this.loadingScreen.hideLoading();
        await this.alerts.alertOk(UIString.format('NOTIF_RATING_SAVED'));
      } catch (e) {
        await this.loadingScreen.hideLoading();
        await this.alerts.alertOk(
          'Error, please try again later: ' + e.message
        );
      }
    } else {
      this.rating = 0;
    }
  }

  /**
   * when user taps get directions
   */
  onClickGetDirections() {
    this.location.getDirectionsToAddress(
      this._toglItem.business.displayAddressOneLine,
      this.location.locationPermitted
    );
  }

  /**
   * when teh user taps call business
   */
  onClickCallBusiness() {
    window.open('tel:' + this.toglItem.business.displayPhone);
  }

  // utility functions //////

  uistring(uistringkey: any, replaceValues?: any): string {
    return UIString.format(uistringkey, replaceValues);
  }

  generateTimeslotLabel(slot: BizSamedaySlot): string {
    return (
      DateUtil2.formatTime(slot.start) + ' - ' + DateUtil2.formatTime(slot.end)
    );
  }

  //////////////////////////////
  // state template functions //
  //////////////////////////////

  isStateActive() {
    return this.backendService.isActiveToglState(this._toglItem.state);
  }

  isStateConfirmed() {
    return this._toglItem.state === ToglState.CONFIRMED;
  }

  isStateCompleted() {
    return this._toglItem.state === ToglState.COMPLETED;
  }

  isStateTaken() {
    return this._toglItem.state === ToglState.TAKEN;
  }

  isStateDenied() {
    return this._toglItem.state === ToglState.DENIED;
  }

  isStateExpired() {
    return this._toglItem.state === ToglState.EXPIRED;
  }

  isStateCanceledAtFault() {
    return this._toglItem.state === ToglState.CANCELED_ATFAULT;
  }

  isStateUserCanceled() {
    return this._toglItem.state === ToglState.USER_CANCELED;
  }

  isStateUserCanceledAtFault() {
    return this._toglItem.state === ToglState.USER_CANCELED_ATFAULT;
  }

  isStateNoshow() {
    return this._toglItem.state === ToglState.NOSHOW_ATFAULT;
  }

  isStateUserPending() {
    return this._toglItem.state === ToglState.USER_PENDING;
  }
}
