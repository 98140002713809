/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { ToglonFirebaseEnvironment } from '@mojoapps1/mojoapps1common';

export const environment: ToglonFirebaseEnvironment = {
  production: false,
  database: 'dev',
  gmapsApiKey: 'AIzaSyDdz_CawtbwROk7oKowOcnyJxAtfKN5VrA',
  firebase: {
    apiKey: 'AIzaSyBrV8TKujBPsbVKLC1JpNjWlj5-RaTd8fM',
    authDomain: 'mojo1demo.firebaseapp.com',
    projectId: 'mojo1demo',
    storageBucket: 'mojo1demo.appspot.com',
    messagingSenderId: '274203423021',
    appId: '1:274203423021:web:cf850a5bce29c4c5ece3a4',
    measurementId: 'G-BS07H8RSF3',
  },
};

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
