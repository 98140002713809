/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { CategoryGridItemComponent } from './category-griditem/category-griditem.component';
import { CategoryListItemComponent } from './category-listitem/category-listitem.component';
import { ServiceGridItemComponent } from './service-griditem/service-griditem.component';
import { ServiceListItemComponent } from './service-listitem/service-listitem.component';
import { SlideDrawerComponent } from './slide-drawer/slide-drawer.component';
import { ToglCardComponent } from './togl-card/togl-card.component';
import { ToglListitemComponent } from './togl-listitem/togl-listitem.component';
import { ServiceWatchedComponent } from './service-watched/service-watched.component';
import { ListPopover } from './list-popover/list-popover.component';

import { StarRatingModule } from './star-rating/star-rating.module';
import { AllowHtmlPipe } from '../pipes/allowhtml.pipe';
import { ModalAddFavorite } from './modal-add-favorite/modal-add-favorite.component';
import { ServiceToggleComponent } from './service-toggle/service-toggle.component';
import { ModalRequestTogl } from './modal-request-togl/modal-request-togl.component';
import { AvgBizRating } from './avg-biz-rating/avg-biz-rating.component';
import { CatSvcIconComponent } from './catsvc-icon/catsvc-icon.component';
import { ModalTextarea } from './modal-textarea/modal-textarea.component';
import { PhoneMaskDirective } from '../directives/phone-mask.directive';
import { ImageShellComponent } from './image-shell/image-shell.component';
import { AspectRatioComponent } from './aspect-ratio/aspect-ratio.component';
import { ModalPhoto } from './modal-photo/modal-photo.component';
import { DiscoverServiceItemComponent } from './discover-service-item/discover-service-item.component';
import { DiscoverBusinessResultComponent } from './discover-business-result/discover-business-result.component';
import { GlobalErrorHandler } from '../errors/global-error-handler';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpLoadingInterceptor } from '../http-loading.interceptor';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    StarRatingModule,
  ],
  declarations: [
    CategoryGridItemComponent,
    CategoryListItemComponent,
    ServiceGridItemComponent,
    ServiceListItemComponent,
    DiscoverServiceItemComponent,
    DiscoverBusinessResultComponent,
    ServiceToggleComponent,
    SlideDrawerComponent,
    ToglCardComponent,
    ToglListitemComponent,
    ServiceWatchedComponent,
    ListPopover,
    AllowHtmlPipe,
    ModalAddFavorite,
    ModalRequestTogl,
    ModalTextarea,
    AvgBizRating,
    CatSvcIconComponent,
    PhoneMaskDirective,
    ImageShellComponent,
    AspectRatioComponent,
    ModalPhoto,
  ],
  exports: [
    CategoryGridItemComponent,
    CategoryListItemComponent,
    ServiceGridItemComponent,
    ServiceListItemComponent,
    DiscoverServiceItemComponent,
    DiscoverBusinessResultComponent,
    ServiceToggleComponent,
    SlideDrawerComponent,
    ToglCardComponent,
    ToglListitemComponent,
    ServiceWatchedComponent,
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ListPopover,
    AllowHtmlPipe,
    ModalAddFavorite,
    ModalRequestTogl,
    ModalTextarea,
    AvgBizRating,
    CatSvcIconComponent,
    PhoneMaskDirective,
    ImageShellComponent,
    AspectRatioComponent,
    ModalPhoto,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpLoadingInterceptor,
    //   multi: true,
    // },
  ],
})
export class CustomComponentsModule {}
