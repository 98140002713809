/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { calcPossibleSecurityContexts } from '@angular/compiler/src/template_parser/binding_parser';
import {
  Component,
  AfterViewInit,
  ElementRef,
  Renderer2,
  Input,
} from '@angular/core';
import { GestureController } from '@ionic/angular';
import { Gesture, GestureConfig } from '@ionic/core';

@Component({
  selector: 'app-slide-drawer',
  templateUrl: './slide-drawer.component.html',
  styleUrls: ['./slide-drawer.component.scss'],
})
export class SlideDrawerComponent implements AfterViewInit {
  /** what state the drawer is in, top, middle, bottom */
  state: string = 'bottom';

  /** the drawer's html element */
  htmlelem: HTMLElement;

  /** height of app footer */
  tabBarHeight: number;

  /** height of draggable handle */
  dragHandleHeight: number;

  /** state definitions */
  states: any;

  @Input() disableDrag: boolean;

  @Input() city: string;
  // @Input() handleHeight: number = 338;

  constructor(
    private gestureCtrl: GestureController,
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2
  ) {}

  ngOnInit() {}

  async ngAfterViewInit() {
    console.log(`slidedrawer: after view init`);
    return this.initialize();
  }

  /**
   * (re)initializes the component. can be called by parent component
   */
  async initialize() {
    console.log(`slidedrawer: initialize`);
    this.htmlelem = this.elementRef.nativeElement;

    // trigger css changes if interaction disabled
    // if (this.disableDrag) {
    //   this.htmlelem.classList.add('disable-drag');
    // }
    this.htmlelem.classList.toggle('disable-drag', this.disableDrag);

    // grab the tab bar height css variable from our theme, strip "px", and use in calculations involving the tab bar height
    this.tabBarHeight = this.getCSSPxVariableAsNumber(
      document.documentElement,
      '--tab-bar-height'
    );
    console.log('slidedrawer: tab bar height: ' + this.tabBarHeight);

    this.dragHandleHeight = this.getCSSPxVariableAsNumber(
      this.htmlelem,
      '--slide-drawer-handle-height'
    );
    console.log('slidedrawer: drag handle height: ' + this.dragHandleHeight);

    const safeAreaTop = this.getCSSPxVariableAsNumber(
      this.htmlelem,
      '--ion-safe-area-top'
    );

    const safeAreaBottom = this.getCSSPxVariableAsNumber(
      this.htmlelem,
      '--ion-safe-area-bottom'
    );

    // when bugged on device, i.e. when coming from search when the map hasn't been built yet, this reports window height: 440
    // when coming from tapping a category and then a service, this reports window height: 732
    console.log(`slidedrawer: window height: ${window.innerHeight}`);

    // set up the allowed states
    this.states = {
      top: {
        y: safeAreaTop,
        left: '0%',
        width: '100%',
      },
      middle: {
        y: window.innerHeight * 0.6,
        left: '0%', //'4%',
        width: '100%', //'92%',
      },
      // bottom: {
      //   y:
      //     window.innerHeight -
      //     this.tabBarHeight -
      //     this.dragHandleHeight -
      //     safeAreaBottom,
      //   left: '0%',
      //   width: '100%',
      // },
    };

    // initial state
    // console.dir(this.states);
    this.setState('middle');

    // are gestures disabled?
    if (!this.disableDrag) {
      await this.initGestures();
    }
  }

  getCSSPxVariableAsNumber(elem: Element, varname: string): number {
    let val: string = getComputedStyle(elem).getPropertyValue(varname);
    if (val.endsWith('px')) {
      val = val.substring(0, val.length - 2);
      // val = val.substr(0, val.length - 2);
    }
    return parseInt(val);
  }

  /** set y coordinate of drawer in pixels */
  setY(y: number) {
    this.renderer.setStyle(this.htmlelem, 'transform', `translateY(${y}px)`);
  }

  /** set height of drawer */
  setHeightForY(y: number) {
    let baseHeight: number =
      window.innerHeight - this.tabBarHeight - this.dragHandleHeight;
    let h: number = baseHeight - y;
    this.renderer.setStyle(this.htmlelem, 'height', `${h}px`);
  }

  setEnableTransition(value: boolean) {
    this.renderer.setStyle(
      this.htmlelem,
      'transition',
      value ? '0.3s ease-out' : 'none'
    );
  }

  setState(newState: string) {
    console.log(`slidedrawer: setState ${newState}`);
    // console.dir(this.states);

    if (this.states[newState] == null) {
      throw new Error('invalid state ' + newState);
    }

    this.state = newState;
    let newY = this.states[newState].y;
    this.setY(newY);
    this.setHeightForY(newY);

    this.renderer.setStyle(this.htmlelem, 'width', this.states[newState].width);
    this.renderer.setStyle(this.htmlelem, 'left', this.states[newState].left);

    //   width: 90%;
    //  left: 5%;
  }

  private async initGestures() {
    const windowHeight = window.innerHeight;
    const options: GestureConfig = {
      el: document.querySelector('#header'),
      direction: 'y',
      gestureName: 'slide-drawer-swipe',
      onStart: (ev) => {
        // disable css transitions as gesture begins
        this.setEnableTransition(false);
      },
      onMove: (ev) => {
        // do something in response to movement

        let baseY = this.states[this.state].y;
        let y: number = baseY + ev.deltaY;

        // don't go above top state
        if (y < this.states['top'].y) {
          y = this.states['top'].y;
        }

        // don't go below middle state
        if (y > this.states['middle'].y) {
          y = this.states['middle'].y;
        }

        this.setY(y);
        this.setHeightForY(y);
      },
      onEnd: (ev) => {
        // enable transitions
        this.setEnableTransition(true);

        let threshold: number = windowHeight / 20;
        let bigThreshold: number = windowHeight / 2;
        let downward: boolean = ev.deltaY > 0;
        let magnitude: number = Math.abs(ev.deltaY);

        // swipes too small, stay in current state
        if (magnitude < threshold) {
          this.setState(this.state);
          return;
        }

        // switch to a new state
        switch (this.state) {
          case 'top':
            if (downward) {
              // jump to middle or bottom?
              // if (magnitude > bigThreshold) this.setState('bottom');
              // else this.setState('middle');

              // only allow top and middle states
              this.setState('middle');
            }
            break;
          case 'middle':
            // if all 3 states
            // if (downward) {
            //   this.setState('bottom');
            // }
            // else {
            //   this.setState('top');
            // }

            // only allow top and middle states
            if (!downward) {
              this.setState('top');
            } else {
              this.setState('middle');
            }

            break;
          case 'bottom':
            // jump to middle or top?
            // if (magnitude > bigThreshold) this.setState('top');
            // else this.setState('middle');

            // only allow top and middle states, this won't even get called
            this.setState('middle');
            break;
        }
      },
    };

    const gesture: Gesture = await this.gestureCtrl.create(options);
    gesture.enable();
  }
}
