/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

export class SVGUtil {
  /**
   * convert an svg string into an svg tag
   * @param str
   * @param elemType
   */
  public static svgFromString(str, elemType = 'svg') {
    let svg = document.createElementNS('http://www.w3.org/2000/svg', elemType);
    svg.innerHTML = str.trim();
    return svg.firstChild;
  }

  /**
   * convert an svg object into a data uri
   * @param svg an svg string or tag
   */
  public static svgToDataUri(svg) {
    if (typeof svg === 'string') svg = SVGUtil.svgFromString(svg);

    return 'data:image/svg+xml,' + encodeURIComponent(svg.outerHTML);
  }

  /**
   * get the defs object from an svg object
   * @param svg an svg string or tag
   */
  public static svgGetDefs(svg) {
    if (typeof svg === 'string') svg = SVGUtil.svgFromString(svg);

    return svg.getElementsByTagNameNS('http://www.w3.org/2000/svg', 'defs')[0];
  }

  /**
   * get a def from an svg object
   * @param svg an svg string, tag, or defs object
   * @param id the id of the def to get, with or without a preceding `#`
   */
  public static svgGetDef(svg, id) {
    // if passed an svg string, instantiate it
    if (typeof svg === 'string') svg = SVGUtil.svgFromString(svg);

    // if passed an svg tag, grab the defs from it
    if (svg.tagName === 'svg') svg = SVGUtil.svgGetDefs(svg);

    // from the defs, grab the requested def
    return svg.querySelector(id.startsWith('#') ? id : '#' + id);
  }

  public static svgCopyDef(svg, svg2, id) {
    let defs = svg.tagName === 'svg' ? SVGUtil.svgGetDefs(svg) : svg;
    defs.appendChild(SVGUtil.svgGetDef(svg2, id));
  }

  public static svgSetUseHref(use, id) {
    use.setAttributeNS(
      'http://www.w3.org/1999/xlink',
      'href',
      id.startsWith('#') ? id : '#' + id
    );
  }

  /**
   * composite one svg (inner) into another (container). alters the container
   * @param svgContainer svg of container, string or svg object
   * @param containerUseId the id of the `use` element where the inner will be attached to the container
   * @param svgInner svg of inner, string or svg object
   * @param innerDefId the id of the def to use from the inner svg
   * @returns
   */
  public static compositeSvgs(
    svgContainer,
    containerUseId: string,
    svgInner,
    innerDefId: string
  ) {
    // instantiate container svg if needed
    if (typeof svgContainer === 'string')
      svgContainer = SVGUtil.svgFromString(svgContainer);

    // add inner svg
    // instantiate icon if needed
    if (typeof svgInner === 'string')
      svgInner = SVGUtil.svgFromString(svgInner);

    // copy the inner def to the container svg
    SVGUtil.svgCopyDef(svgContainer, svgInner, innerDefId);

    // add the icon detail to the icon shell
    let iconRef = svgContainer.querySelector(`#${containerUseId}`);
    SVGUtil.svgSetUseHref(iconRef, innerDefId);
  }

  /**
   * colorize an svg. alters the svg
   * @param svg
   * @param colorizeInfo
   * @returns
   */
  public static colorizeSvg(svg, colorizeInfo: SvgColorizeInfo[]) {
    // instantiate marker shell if needed
    if (typeof svg === 'string') svg = SVGUtil.svgFromString(svg);

    // colorize the parts
    for (const info of colorizeInfo) {
      let ref = svg.querySelector(`#${info.id}`);
      if (ref) {
        ref.setAttribute(info.type, info.color);
      }
    }
  }

  /**
   * composite and colorize a map marker with inner icon
   * @param shellSvg
   * @param iconSvg
   * @param shellColor
   * @param iconColor
   * @returns
   */
  public static compositeMapMarkerIcon(
    shellSvg,
    iconSvg?,
    shellColor?: string,
    iconColor?: string
  ) {
    // instantiate marker shell if needed
    if (typeof shellSvg === 'string')
      shellSvg = SVGUtil.svgFromString(shellSvg);

    // colorize the shell
    SVGUtil.colorizeSvg(shellSvg, [
      {
        id: 'use_marker_fill',
        color: shellColor || '#33CC33',
        type: 'fill',
      },
    ]);

    // add icon if provided
    if (iconSvg) {
      SVGUtil.compositeSvgs(
        shellSvg,
        'use_icon_detail',
        iconSvg,
        'icon_detail'
      );

      // colorize the icon
      SVGUtil.colorizeSvg(shellSvg, [
        {
          id: 'use_icon_detail',
          type: 'fill',
          color: iconColor || 'white',
        },
      ]);
    }

    // make it into a data uri
    return SVGUtil.svgToDataUri(shellSvg);
  }
}

/**
 * info to colorize an svg element
 */
export interface SvgColorizeInfo {
  /**
   * whether to colorize the fill or the stroke
   */
  type: 'fill' | 'stroke';
  /**
   * the color to colorize this element with
   */
  color: string;
  /**
   * id of element to alter, not including leading #
   */
  id: string;
}
