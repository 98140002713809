/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

/**
 * app-wide loading screen
 */
@Injectable({
  providedIn: 'root',
})
export class LoadingScreenService {
  /**
   * the current loading screen, if any
   */
  get loading() {
    return this._loading;
  }
  private _loading: HTMLIonLoadingElement;

  constructor(private loadingController: LoadingController) {
    console.log('loadingscreen: constructor');
  }

  /**
   * @returns a promise that is resolved when the current loading screen, if any, dismisses,
   * or a resolved promise if there is currently no loading screen
   */
  async onDidDismiss() {
    if (this._loading) {
      return this._loading.onDidDismiss();
    }
    return Promise.resolve();
  }

  /**
   * show a loading screen. you must hide it with a call to `hideLoading`
   * @returns a promise that resolves to the loading screen
   */
  async showLoading() {
    if (this._loading) {
      console.log(
        'loadingscreen: showLoading: returning existing loading screen'
      );
      return this._loading;
    }

    console.log('loadingscreen: showLoading: creating new loading screen');

    this._loading = await this.loadingController.create({
      cssClass: 'custom-loading',
      message: 'Please wait...',
      // mode: 'ios',
      spinner: 'lines',
    });
    await this._loading.present();
    return this._loading;
  }

  /**
   * hide the current loading screen if any
   * @returns
   */
  async hideLoading() {
    if (this._loading) {
      console.log('loadingscreen: hideLoading: hiding loading screen');
      const ret: boolean = await this._loading.dismiss();
      this._loading = null;
      return;
    }
    console.log('loadingscreen: hideLoading: no loading screen to hide');
  }
}
