/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireFunctions } from '@angular/fire/functions';
import {
  FavoritedByRTDB,
  RTDBPaths,
  UserFavoriteRTDB,
} from '@mojoapps1/mojoapps1common';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileLog } from './FileLog';

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  constructor(
    private rtdb: AngularFireDatabase,
    private fns: AngularFireFunctions,
    private auth: AngularFireAuth,
    private filelog: FileLog
  ) {
    this.filelog.log(`favoritesservice: constructor`);
    this.auth.authState.subscribe((u) => {
      if (u) {
        this._userId = u.uid;
        this.filelog.log(`favoritesservice: user: ${u.uid}`);
      } else {
        this._userId = null;
        this.filelog.log(`favoritesservice: user logout`);
      }
    });
  }

  private _userId: string;

  private _favoritedByPath(bizId: string) {
    return `${RTDBPaths.FAVORITED_BY}/${bizId}`;
  }

  /**
   * get changes of user favorites
   */
  getFavoritesValueChanges() {
    if (!this._userId) throw new Error('not initialized!');

    return this.rtdb
      .object(`/${RTDBPaths.FAVORITES}/${this._userId}/biz`)
      .valueChanges()
      .pipe(map((val) => (val ? Object.keys(val) : [])));
  }

  /**
   * get changes of user notification settings for favorite businesses
   */
  getFavoritesNotifsValueChanges() {
    if (!this._userId) throw new Error('not initialized!');

    return this.rtdb
      .object(`/${RTDBPaths.FAVORITES}/${this._userId}/notif`)
      .valueChanges()
      .pipe(map((val) => (val ? Object.keys(val) : [])));
  }

  /**
   * get changes of if biz is favorite of current user
   */
  getIsBizFavoriteValueChanges(bizId: string) {
    if (!this._userId) throw new Error('not initialized!');

    return this.rtdb
      .object(`/${RTDBPaths.FAVORITES}/${this._userId}/biz/${bizId}`)
      .valueChanges()
      .pipe(map((val) => !!val));
  }

  /**
   * get changes of if current user wants notifications for this biz
   */
  getBizNotifsValueChanges(bizId: string) {
    if (!this._userId) throw new Error('not initialized!');

    return this.rtdb
      .object(`/${RTDBPaths.FAVORITES}/${this._userId}/notif/${bizId}`)
      .valueChanges()
      .pipe(map((val) => !!val));
  }

  /**
   * get the number of favorites a biz has
   */
  getBizNumFavoritesValueChanges(bizId: string) {
    if (!this._userId) throw new Error('not initialized!');

    return this.rtdb
      .object(`/${RTDBPaths.FAVORITED_BY}/${bizId}/_count`)
      .valueChanges()
      .pipe(map((val) => val as number));
  }

  /**
   * get changes of users that favorited this biz
   */
  getFavoritedByValueChanges(bizId: string) {
    if (!this._userId) throw new Error('not initialized!');

    return this.rtdb
      .object<FavoritedByRTDB>(this._favoritedByPath(bizId))
      .valueChanges();
  }

  /**
   * set or unset business as favorite
   * @param bizId
   * @param value set or unset
   * @param notif optional, whether to also turn notification on/off
   * @returns
   */
  async setUserFavorite(bizId: string, value: boolean, notif?: boolean) {
    if (!this._userId) throw new Error('not initialized!');

    return new Promise<void>((resolve, reject) => {
      let callable = this.fns.httpsCallable('setUserFavorite');
      callable({
        userId: this._userId,
        bizId,
        value,
        notif,
      }).subscribe(
        (response) => {
          this.filelog.log(
            'favoritesservice: setUserFavorite',
            value,
            response
          );
          if (response.success) {
            resolve();
          } else {
            reject(new Error('error calling setUserFavorite'));
          }
        },
        (err) => {
          this.filelog.log(
            'favoritesservice: setUserFavorite error',
            value,
            err
          );
          reject(err);
        }
      );
    });

    // const updates: any = {};
    // // businesses this user favorited
    // updates[`/${RTDBPaths.FAVORITES}/{${this._userId}}/biz/${bizId}`] = true;
    // // businesses this user favorited
    // updates[
    //   `/${RTDBPaths.FAVORITED_BY}/{${bizId}}/user/${this._userId}`
    // ] = true;
    // // do both updates at once
    // await this.rtdb.database.ref().update(updates);
  }

  /**
   * remove a business from the user's favorites
   * @param bizId
   * @returns
   */
  // async removeFavorite(bizId: string) {
  //   if (!this._userId) throw new Error('not initialized!');

  //   const updates: any = {};
  //   // businesses this user favorited
  //   updates[`/${RTDBPaths.FAVORITES}/{${this._userId}}/biz/${bizId}`] = null;
  //   // businesses this user favorited
  //   updates[
  //     `/${RTDBPaths.FAVORITED_BY}/{${bizId}}/user/${this._userId}`
  //   ] = null;
  //   // do both updates at once
  //   await this.rtdb.database.ref().update(updates);
  // }
}
