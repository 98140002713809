/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Component, OnInit, Renderer2 } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  AngularFireRemoteConfig,
  AngularFireRemoteConfigModule,
  DEFAULTS,
  SETTINGS,
} from '@angular/fire/remote-config';

import { Platform } from '@ionic/angular';
import { BackendService } from './angular-services/backend.service';
import { PageBaseComponent } from './pagebase.component';

import { SwUpdate } from '@angular/service-worker';
import { SplashScreen } from '@capacitor/splash-screen';
import { FileLog } from './angular-services/FileLog';
import { DarkmodeService } from './angular-services/darkmode.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends PageBaseComponent implements OnInit {
  constructor(
    private platform: Platform,
    backendService: BackendService,
    private swUpdate: SwUpdate,
    private filelog: FileLog,
    private darkMode: DarkmodeService
  ) {
    super(backendService, filelog);
    this._className = 'app';
    this.initializeApp();
  }

  async initializeApp() {
    // let toglon_remoteconfig_value = this.remoteConfig
    //   .getValue('toglon_remoteconfig_test')
    //   .then((value) => {
    //     console.log(value.asString());
    //   });

    // this.remoteConfig.strings.toglon_remoteconfig_test.subscribe(
    //   (data: string) => {
    //     console.log(data);
    //   }
    // );

    await this.platform.ready();
    SplashScreen.hide();

    // dark mode support
    this.darkMode.initialize();

    // this.statusBar.styleDefault();
    // this.splashScreen.hide();

    if (this.swUpdate.available) {
      //check if there is an update to the PWA and refresh
      /*
      this.swUpdate.available.subscribe(() => {
        if (confirm('A new version is available. Load it?'))
          window.location.reload();
      });
      */
    }
  }
}
