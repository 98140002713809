/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { UIString } from '../lang/UIString';

export interface NotificationAlertOptions {
  header: string;
  message: string;
  handler?: () => void;
  viewText?: string;
  cancelText?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private alertCtrl: AlertController, private router: Router) {}

  async openNotificationAlert(
    header: string,
    message: string,
    url: string,
    viewText?: string,
    cancelText?: string
  ) {
    const alert = await this.alertCtrl.create({
      cssClass: 'noneactive-alert-style',
      mode: 'md',
      header: header,
      message: message,

      buttons: [
        {
          text: cancelText || UIString.format('BTN_CLOSE'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
        {
          text: viewText || UIString.format('BTN_VIEW'),
          role: 'view',
          cssClass: 'secondary',
          handler: () => {
            this.router.navigateByUrl(url);
          },
        },
      ],
    });

    await alert.present();
  }

  async openNotificationAlert2(options: NotificationAlertOptions) {
    const alert = await this.alertCtrl.create({
      cssClass: 'noneactive-alert-style',
      mode: 'md',
      header: options.header,
      message: options.message,

      buttons: [
        {
          text: options.cancelText || UIString.format('BTN_CLOSE'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
        {
          text: options.viewText || UIString.format('BTN_VIEW'),
          role: 'view',
          cssClass: 'secondary',
          handler: () => {
            if (options.handler) {
              return options.handler();
            }
          },
        },
      ],
    });

    await alert.present();
  }

  /**
   * simple ok alert box, using plain style (no bell icon)
   * @param message
   */
  async openOkAlert(message: string) {
    const alert = await this.alertCtrl.create({
      cssClass: 'ok-alert-style',
      mode: 'md',
      message: message,

      buttons: [
        {
          text: UIString.format('BTN_OK'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  /**
   * simple promise-based alert with one button. does NOT use the bell background image alert style
   * @param message message for the alert box
   * @param header optional header
   * @param usePlainStyle whether to use plain style, without the bell icon, or normal, with the bell icon. default true
   * @returns a promise that resolves when the user has dismissed the dialog box
   */
  async alertOk(
    message: string,
    header?: string,
    usePlainStyle: boolean = true
  ) {
    return new Promise<void>(async (resolve) => {
      const alert = await this.alertCtrl.create({
        cssClass: usePlainStyle ? 'ok-alert-style' : 'noneactive-alert-style',
        mode: 'md',
        message,
        header,
        buttons: [
          {
            text: UIString.format('BTN_OK'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => resolve(),
          },
        ],
      });
      await alert.present();
    });
  }

  async openOkWithHeaderAlert(
    title: string,
    message: string,
    okHandler?: () => void
  ) {
    const alert = await this.alertCtrl.create({
      cssClass: 'noneactive-alert-style',
      mode: 'md',
      header: title,
      message: message,

      buttons: [
        {
          text: UIString.format('BTN_OK'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            if (okHandler) okHandler();
          },
        },
      ],
    });

    return alert.present();
  }

  async yesNoAlert(
    title: string,
    message: string,
    yesText?: string,
    noText?: string,
    yesHandler?: () => void,
    noHandler?: () => void
  ) {
    const alert = await this.alertCtrl.create({
      cssClass: 'noneactive-alert-style',
      mode: 'md',
      header: title,
      message: message,
      buttons: [
        {
          text: noText || UIString.format('BTN_NO'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            if (noHandler) noHandler();
          },
        },
        {
          text: yesText || UIString.format('BTN_YES'),
          role: 'alert',
          cssClass: 'secondary',
          handler: () => {
            if (yesHandler) yesHandler();
          },
        },
      ],
    });

    await alert.present();
  }

  async genericConfirm(
    message: string,
    yesHandler: () => void,
    header?: string
  ) {
    return this.alertCtrl
      .create({
        mode: 'md',
        header: header,
        message: message,
        cssClass: 'ok-alert-style',
        buttons: [
          {
            text: UIString.format('BTN_NO'),
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: UIString.format('BTN_YES'),
            handler: yesHandler,
          },
        ],
      })
      .then((alert) => alert.present());
  }

  /**
   * promise-based generic confirm function
   * @param message message to display
   * @param yesHandler
   * @param header optional header text
   * @returns a promise that evaluates to true if user confirmed, false if user did not
   */
  async genericConfirm2(message: string, header?: string) {
    return new Promise<boolean>(async (resolve, reject) => {
      const alert = await this.alertCtrl.create({
        mode: 'md',
        header: header,
        message: message,
        cssClass: 'ok-alert-style',
        buttons: [
          {
            text: UIString.format('BTN_NO'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => resolve(false),
          },
          {
            text: UIString.format('BTN_YES'),
            handler: () => resolve(true),
          },
        ],
      });
      await alert.present();
    });
  }

  /**
   * promise-based function to display an alert with yes, no, and a text input
   * @param message message to display
   * @param yesHandler
   * @param header optional header text
   * @returns a promise that resolves to an object with the user's button and text responses
   */
  async yesNoTextInput(message: string, header?: string) {
    return new Promise<{ response: string; confirmation: boolean }>(
      async (resolve, reject) => {
        const alert = await this.alertCtrl.create({
          mode: 'md',
          header,
          message,
          cssClass: 'ok-alert-style',
          inputs: [
            {
              name: 'response',
              type: 'text',
              placeholder: 'Cancelation Reason',
            },
          ],
          buttons: [
            {
              text: UIString.format('BTN_NO'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: (values) => resolve({ ...values, confirmation: false }),
            },
            {
              text: UIString.format('BTN_YES'),
              handler: (values) => resolve({ ...values, confirmation: true }),
            },
          ],
        });
        await alert.present();
      }
    );
  }
}
