/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Injectable, OnInit } from '@angular/core';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import {
  BackendServiceBase,
  DateUtil,
  PushData,
  PushTypes,
  RemoteConfig,
  RTDBPaths,
  Service,
  ToglInfo,
  ToglState,
} from '@mojoapps1/mojoapps1common';
import { UIString } from '../lang/UIString';
import {
  Geolocation,
  PermissionStatus,
  Position,
} from '@capacitor/geolocation';
import {} from 'google.maps';
import { AngularFireDatabase } from '@angular/fire/database';
import { concat, defer, Observable, of, Subject, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { AlertService } from './alert.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { FileLog } from './FileLog';
import { ConfigService } from './config.service';

/**
 * sent when user's position changes
 */
export interface PositionUpdate {
  /**
   * whether we have permission to get user's position
   */
  permitted: boolean;
  /**
   * the position if permitted, undefined if not
   */
  position?: google.maps.LatLngLiteral;
}

/**
 * options that change what message we show the user
 */
export interface LocationPermissionOptions {
  /**
   * service they were trying to access on the map
   */
  service?: Service;
  /**
   * whether this is a required location ask or an optional one
   */
  isOptional?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  public static readonly MODE_BOISE: string = 'boise';
  public static readonly MODE_GPS: string = 'gps';
  public static readonly GPS_THRESHOLD: number = 0.00001;

  public static readonly TIMEPRECISION_MAP: string = 'map';
  public static readonly TIMEPRECISION_STANDARD: string = 'standard';

  private _locationMode: string;
  private _timePrecision: string;

  private _pluginSupported: boolean;

  private _boisePos: google.maps.LatLngLiteral = {
    lat: 43.700245,
    lng: -116.5064,
  };

  private _defaultPos: google.maps.LatLngLiteral = {
    lat: 39.833333,
    lng: -98.583333,
  };

  private _lastUpdated: number;

  /**
   * current user
   */
  private _userId: string;

  /**
   * user's position
   */
  private _position: PositionUpdate;

  /**
   * broadcasts position updates
   */
  private _positionUpdates: Subject<PositionUpdate>;

  /**
   * old JS interval handle
   */
  private _intervalHandle;

  /**
   * new way using built in watch function in capacitor plugin
   */
  private _watchHandle: string;

  private _lastWatchUpdate: number;

  private _config;

  private _updateInterval: number;
  private _updateIntervalMap: number;
  private _dbUpdateInterval: number;

  /**
   * whether the user has given permission to use their location
   */
  get locationPermitted() {
    return this._locationPermitted;
  }
  private _locationPermitted: boolean;

  constructor(
    private platform: Platform,
    private db: AngularFireDatabase,
    private config: ConfigService,
    private alerts: AlertService,
    private auth: AngularFireAuth,
    private filelog: FileLog
  ) {
    this._locationMode =
      environment.database === BackendServiceBase.DB_PROD
        ? LocationService.MODE_GPS
        : LocationService.MODE_BOISE;
    this.filelog.log(`locationservice: initial mode: ${this._locationMode}`);
    this._locationPermitted = false;
    this._timePrecision = LocationService.TIMEPRECISION_STANDARD;
    this._pluginSupported = false;
    this._positionUpdates = new Subject<PositionUpdate>();

    // set user id automatically on login/out
    this.auth.authState.subscribe(async (u) => {
      if (u) {
        this._userId = u.uid;
        this.filelog.log(`locationservice: user ${u.uid} logged in`);
        const permission = await this.checkPermissions();
        this.filelog.log(`locationservice: permission granted: ${permission}`);
      } else {
        this._userId = null;
        this.filelog.log(`locationservice: user logged out`);
        if (this._positionUpdates) {
          this._positionUpdates.complete();
          this._positionUpdates = new Subject<PositionUpdate>();
        }
      }
    });

    // wait for platform ready and determine if plugin supported
    this.platform.ready().then(() => {
      this._pluginSupported = true;
      // this._pluginSupported =
      //   this.platform.is('hybrid') &&
      //   (this.platform.is('android') || this.platform.is('ios'));
      this.filelog.log(
        'locationservice: geolocation plugin supported: ' +
          this._pluginSupported
      );
      this.filelog.log(
        'locationservice: platforms: ' + this.platform.platforms()
      );
    });

    // get config values
    this.config
      .getConfigValueChanges(ConfigService.GPS_LOCAL_UPDATE_INTERVAL)
      .subscribe((val) => (this._updateInterval = val.asNumber()));
    this.config
      .getConfigValueChanges(ConfigService.GPS_LOCAL_UPDATE_INTERVAL_MAP)
      .subscribe((val) => (this._updateIntervalMap = val.asNumber()));
    this.config
      .getConfigValueChanges(ConfigService.GPS_DB_UPDATE_INTERVAL)
      .subscribe((val) => (this._dbUpdateInterval = val.asNumber()));
  }

  /**
   * whether the plugin is supported (mobile)
   */
  get isPluginSupported(): boolean {
    return this._pluginSupported;
  }

  /**
   * timestamp the position was last updated
   */
  get lastUpdated(): number {
    return this._lastUpdated;
  }

  /**
   * returns a copy of the user's current location
   */
  get position(): PositionUpdate {
    return { ...this._position };
  }

  /**
   * default position of map if user position not available
   */
  get defaultPosition() {
    return { ...this._defaultPos };
  }

  /**
   * location mode defaults to `LocationService.MODE_GPS` in production env, `LocationService.MODE_BOISE` in dev/sales
   * @returns
   */
  getLocationMode(): string {
    return this._locationMode;
  }
  setLocationMode(val: string) {
    if (
      !(val === LocationService.MODE_BOISE || val === LocationService.MODE_GPS)
    ) {
      throw new Error('locationservice: unsupported location mode ' + val);
    }

    this._locationMode = val;
    this.filelog.log(`locationservice: mode set to ${val}`);

    // force a location update
    this.getPositionOnce(true);
  }

  getTimePrecision(): string {
    return this._timePrecision;
  }
  setTimePrecision(val: string) {
    if (
      !(
        val === LocationService.TIMEPRECISION_MAP ||
        val === LocationService.TIMEPRECISION_STANDARD
      )
    ) {
      throw new Error('locationservice: unsupported time precision ' + val);
    }

    this._timePrecision = val;

    this.filelog.log(`locationservice: set time precision to ${val}`);

    // restart the interval
    this.checkStartInterval();
  }

  /**
   * initialize to track current user, request gps permissions from the device. can be called multiple times.
   * @returns true on success, false if location permissions were denied
   */
  async initialize(options?: LocationPermissionOptions) {
    if (this._locationPermitted) return;

    const permitted = await this.obtainPermission();

    if (!this._locationPermitted) {
      // permission not given!

      // only show a message if this was a non-optional ask
      const isOptional = options ? !!options.isOptional : false;
      if (isOptional) {
        return false;
      }

      if (options && options.service) {
        // service message
        await this.alerts.alertOk(
          UIString.format('NOTIF_LOCATION_PROMPT_MAP', {
            service: options.service.title,
          })
        );
      } else {
        // generic message
        await this.alerts.alertOk(
          UIString.format('NOTIF_LOCATION_PROMPT_DENIED')
        );
      }
    } else {
      // we have permission!
      // this.setTimePrecision(LocationService.TIMEPRECISION_STANDARD);
      this._lastUpdated = 0; // this might be a mistake
      this.checkStartInterval();

      return true;
    }

    return false;
  }

  /**
   * check geolocation permissions, and optionally start tracking if previously permitted and user is logged in,
   * but don't request permissions from the system. does nothing if already permitted. can be called multiple times
   * without problems
   * @returns true if permitted, false otherwise
   */
  async checkPermissions() {
    if (this._locationPermitted) return true;
    if (!this._pluginSupported) return false;
    if (!this._userId) return false;

    let permCheck: PermissionStatus;
    try {
      permCheck = await Geolocation.checkPermissions();
      this.filelog.log(
        `locationservice: checked permissions, status: ${permCheck.location}`
      );
    } catch (e) {
      this.filelog.log(`locationservice: caught error: ${e}`);
      this._locationPermitted = false;
      return this._locationPermitted;
    }

    if (permCheck.location === 'granted') {
      // we're all good!
      this._locationPermitted = true;
      // allow access to location
      this.checkStartInterval();
      return this._locationPermitted;
    } else {
      // either they haven't been asked yet or they said no
      this._locationPermitted = false;
      return this._locationPermitted;
    }
  }

  /**
   * handle requesting permissions on native platforms
   * @returns
   */
  private async obtainPermission() {
    if (this._pluginSupported) {
      let permCheck: PermissionStatus;
      try {
        permCheck = await Geolocation.checkPermissions();
        this.filelog.log(
          `locationservice: checked permissions, status: ${permCheck.location}`
        );
      } catch (e) {
        this.filelog.log(`locationservice: caught error: ${e}`);
        this._locationPermitted = false;
        return this._locationPermitted;
      }

      while (true) {
        this.filelog.log(
          `locationservice: permission status: ${permCheck.location}`
        );

        if (permCheck.location === 'granted') {
          // we're all good!
          this._locationPermitted = true;
          return this._locationPermitted;
        } else if (permCheck.location === 'denied') {
          // user denied permission, we're not gonna get a second chance, tell them to update it in settings.
          this._locationPermitted = false;
          return this._locationPermitted;
        }

        // either prompt or prompt-with-rationale

        // on android, let them know we're going to ask for permissions, and then do so
        if (permCheck.location === 'prompt-with-rationale') {
          await this.alerts.alertOk(UIString.format('NOTIF_LOCATION_PROMPT'));
        }

        // request permissions, which on ios will show them our message from the .plist file
        try {
          permCheck = await Geolocation.requestPermissions();
        } catch (e) {
          this.filelog.log(`locationservice: caught error: ${e}`);
          this._locationPermitted = false;
          return this._locationPermitted;
        }
      }
    }
    this._locationPermitted = true;
    return this._locationPermitted;
  }

  private async _getPosOnce(options = {}) {
    return new Promise<GeolocationPosition>(async (resolve, reject) => {
      const id = await Geolocation.watchPosition(options, (position, err) => {
        if (id) {
          this.filelog.log(`locationservice: clearing watch id ${id}`);
          Geolocation.clearWatch({ id });
        }
        if (err) {
          reject(err);
          return;
        }
        resolve(position);
      });
    });
  }

  /**
   * get the position of the user from gps once
   * @returns
   */
  async getPositionOnce(
    forceUpdateDB: boolean = false
  ): Promise<PositionUpdate> {
    if (this._userId == null) {
      this.filelog.log(`locationservice: getPositionOnce: no user id`);
      this._position = {
        permitted: false,
      };
      return this._position;
    }

    // if not permitted, broadcast an update with no location
    if (!this._locationPermitted) {
      this._position = {
        permitted: false,
      };
      this._positionUpdates.next({
        permitted: false,
      });
      return this._position;
    }

    // we have permission, proceed

    // NOTE: this is a bit of a hack.
    // the capacitor plugin throws an error if you request permissions on web
    // but it still will handle position requests fine via the browser's apis...
    // so we're using it

    const oldPos = this._position ? this._position.position : undefined;
    let nextPos: google.maps.LatLngLiteral;
    if (this._locationMode === LocationService.MODE_GPS) {
      let pos = this.platform.is('ios')
        ? await this._getPosOnce({
            enableHighAccuracy: true,
          })
        : await Geolocation.getCurrentPosition({
            enableHighAccuracy: true,
          });
      nextPos = this.toLatLngLiteral(pos.coords.latitude, pos.coords.longitude);
    } else {
      nextPos = { ...this._boisePos };
    }

    // only update position if it's changed by a certain threshold
    if (oldPos) {
      const latDelta: number = nextPos.lat - oldPos.lat;
      const lngDelta: number = nextPos.lng - oldPos.lng;
      if (
        Math.abs(latDelta) < LocationService.GPS_THRESHOLD &&
        Math.abs(lngDelta) < LocationService.GPS_THRESHOLD
      ) {
        // this.filelog.log(
        //   `locationservice: rejecting position delta below threshold: ${latDelta}, ${lngDelta}`
        // );
        this._position = { permitted: true, position: { ...nextPos } };
        return {
          permitted: true,
          position: { ...nextPos },
        };
      }
    }

    // set position
    this._position = { permitted: true, position: { ...nextPos } };

    // update the user's position in db if it's been long enough
    const threshold = this._dbUpdateInterval;
    const timeDelta: number =
      this.lastUpdated != 0 ? Date.now() - this._lastUpdated : -1000;
    if (this._lastUpdated == 0 || timeDelta >= threshold || forceUpdateDB) {
      this.filelog.log(
        `locationservice: updating db location, last updated ${
          timeDelta / 1000
        }s ago, force=${forceUpdateDB}`
      );
      await this.db
        .object(`${RTDBPaths.LOCATION}/${this._userId}`)
        .set(this._position);
      this._lastUpdated = Date.now();
    }

    // broadcast location change
    this._positionUpdates.next({
      permitted: true,
      position: { ...this._position.position },
    });

    // also return position
    return this._position;
  }

  /**
   * get updated on the user position when it changes.
   * @returns
   */
  getPositionUpdates() {
    if (!this._userId) throw new Error('locationservice: not initialized');

    if (!this._positionUpdates)
      throw new Error('location manager not initialized!');

    // has the position already been loaded at least once?
    if (this._position) {
      // yes, add the current value in before the normal stream of changes
      return concat(
        defer(() => of(this._position)),
        this._positionUpdates.asObservable()
      );
    } else {
      // no, just return the normal stream of changes for subscription
      return this._positionUpdates.asObservable();
    }
  }

  /**
   * check if we need to start our interval to poll location
   */
  private checkStartInterval() {
    this.clearInterval();
    if (this._userId != null) {
      this.startInterval();
    }
  }

  /**
   * start internal location-polling interval
   * @returns
   */
  private startInterval() {
    this.clearInterval();
    if (this._userId == null) {
      this.filelog.log(`locationservice: startInterval: no user id`);
      return;
    }

    // get timeperiod from config
    let millis: number =
      this._timePrecision === LocationService.TIMEPRECISION_STANDARD
        ? this._updateInterval
        : this._updateIntervalMap;
    if (!millis) millis = 20000;

    // start interval
    // this.filelog.log(`locationservice: watchPosition`);
    // Geolocation.watchPosition({ enableHighAccuracy: true }, (pos, err) => {
    //   this.filelog.log(
    //     `locationservice: watchPosition update: ` + JSON.stringify(pos)
    //   );
    //   if (this._lastWatchUpdate) {
    //     const delta = Date.now() - this._lastWatchUpdate;
    //     this.filelog.log(`locationservice: ${delta} ms`);
    //   }
    //   this._lastWatchUpdate = Date.now();
    //   if (err) {
    //     this.filelog.log(
    //       `locationservice: watchPosition error: ` + JSON.stringify(err)
    //     );
    //   }
    // }).then((val) => {
    //   this._watchHandle = val;
    //   this.filelog.log(`locationservice: watchPosition handle: ${val}`);
    // });

    this.filelog.log(
      `locationservice: start interval, precision=${
        this._timePrecision
      }, interval=${millis / 1000}s`
    );
    this._intervalHandle = setInterval(async () => {
      this.filelog.log(`locationservice: interval callback`);
      const pos = await this.getPositionOnce();
    }, millis);

    // get position once
    this.filelog.log(`locationservice: getting initial position`);
    this.getPositionOnce().then((pos) => {
      this.filelog.log('locationservice: got initial position');
    });
  }

  /**
   * remove polling interval
   */
  private clearInterval() {
    // if (this._watchHandle) {
    //   Geolocation.clearWatch({ id: this._watchHandle });
    // }
    if (this._intervalHandle) {
      clearInterval(this._intervalHandle);
      this._intervalHandle = null;
    }
  }

  /**
   * helper function
   * @param lat
   * @param long
   * @returns
   */
  private toLatLngLiteral(
    lat: string | number,
    long: string | number
  ): google.maps.LatLngLiteral {
    // force numbers for lat/long
    let userLat: number = -1;
    if (typeof lat === 'string') {
      userLat = parseFloat(lat as string);
    } else {
      userLat = lat;
    }

    let userLong: number = -1;
    if (typeof long === 'string') {
      userLong = parseFloat(long as string);
    } else {
      userLong = long;
    }

    return { lat: userLat, lng: userLong };
  }

  /**
   * open a google map url with directions to a particular street address, with no origin specified
   * @param address
   */
  getDirectionsToAddress(address: string, useUserPosition: boolean = false) {
    const destinationAddress = encodeURIComponent(address);

    let url = `https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=${destinationAddress}`;

    if (useUserPosition && this._position && this._position.position) {
      let userPosition = encodeURIComponent(
        this._position.position.lat + ',' + this._position.position.lng
      );
      url += `&origin=${userPosition}`;
    }

    window.open(url);
  }

  /**
   * compute distance between user and a geo point, can take strings or numbers
   * @returns distance if we have user position, NaN if we don't
   */
  getUserDistanceTo(lat, long) {
    if (!this._locationPermitted || !this._position) {
      return NaN;
    }

    const p1 = this.toLatLngLiteral(lat, long);
    return this.getUserDistanceToPoint(p1);
  }

  /**
   * compute distance between user and a point
   * @returns distance if we have location permission, NaN otherwise
   */
  getUserDistanceToPoint(p: google.maps.LatLngLiteral) {
    if (
      !this._locationPermitted ||
      !this._position ||
      !this._position.position
    ) {
      return NaN;
    }

    return this.calculateDistance(
      this._position.position.lat,
      this._position.position.lng,
      p.lat,
      p.lng
    );
  }

  /**
   * takes strings or numbers
   * @param lat1
   * @param long1
   * @param lat2
   * @param long2
   * @returns
   */
  private calculateDistance(lat1, long1, lat2, long2) {
    const p1 = this.toLatLngLiteral(lat1, long1);
    const p2 = this.toLatLngLiteral(lat2, long2);
    return this.getDistanceBetweenPoints(p1, p2);
  }

  /**
   * get distance between two geo points
   * @param p1
   * @param p2
   * @returns
   */
  getDistanceBetweenPoints(
    p1: google.maps.LatLngLiteral,
    p2: google.maps.LatLngLiteral
  ) {
    return this.latLongDistance(p1.lat, p1.lng, p2.lat, p2.lng);
  }

  /**
   * estimate distance between two lat/long points.
   * taken from https://gis.stackexchange.com/questions/142326/calculating-longitude-length-in-miles
   *
   * @param lat1
   * @param long1
   * @param lat2
   * @param long2
   */
  private latLongDistance(
    lat1: number,
    long1: number,
    lat2: number,
    long2: number
  ) {
    let deltaLat = Math.abs(lat1 - lat2);
    let deltaLong = Math.abs(long1 - long2);
    let avgLat = (lat1 + lat2) / 2;

    let latMile = 69;
    let longMile = Math.cos((avgLat * Math.PI) / 180) * latMile;
    let deltaLatMiles = deltaLat * latMile;
    let deltaLongMiles = deltaLong * longMile;

    let dist = Math.sqrt(
      deltaLatMiles * deltaLatMiles + deltaLongMiles * deltaLongMiles
    );

    //console.log(`latlong delta (${deltaLat},${deltaLong}), mi delta(${deltaLatMiles},${deltaLongMiles}), calculated distance=${dist}`);

    return dist;
  }
}
