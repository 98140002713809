/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  BizActiveNowForServiceRTDB,
  Business,
  CollectionNames,
  RTDBPaths,
  Service,
} from '@mojoapps1/mojoapps1common';
import { Observable } from 'rxjs';
import { BackendService } from './backend.service';

/**
 * load activeNow for an entire market
 */
export interface MarketActiveNow {
  [serviceId: string]: BizActiveNowForServiceRTDB;
}

/**
 * business search result for a particular service
 */
export interface BizServiceSearchResult {
  business: Business;
  serviceId: string;
}

@Injectable({
  providedIn: 'root',
})
export class ActiveNowService {
  private _cache: MarketActiveNow;
  private _lastFetch: Date;
  private _cacheLifetimeSeconds = 60 * 60;

  constructor(
    private rtdb: AngularFireDatabase,
    private firestore: AngularFirestore
  ) {}

  /**
   * get valuechanges for rtdb ActiveNow updates for a service. DOES NOT handle teardown, calling code is responsible for that!
   * @param serviceId the service in question
   * @param market only acceptable value is "boise" for now
   */
  getActiveNowValueChangesRTDB(
    serviceId: string,
    market: string = 'boise'
  ): Observable<BizActiveNowForServiceRTDB> {
    if (market != 'boise')
      throw new Error(`invalid market ${market}, must be boise`);
    return this.rtdb
      .object(`/${RTDBPaths.ACTIVE_NOW}/boise/${serviceId}`)
      .valueChanges() as Observable<BizActiveNowForServiceRTDB>;
  }

  /**
   * get the active now data for an entire market
   * @param market
   * @returns
   */
  getMarketActiveNowValueChanges(
    market: string = 'boise'
  ): Observable<MarketActiveNow> {
    if (market != 'boise')
      throw new Error(`invalid market ${market}, must be boise`);
    return this.rtdb
      .object(`/${RTDBPaths.ACTIVE_NOW}/boise`)
      .valueChanges() as Observable<MarketActiveNow>;
  }

  /**
   * fetch market active now data from network if cache old enough, otherwise return from cache
   * @returns
   */
  private async fetchFromCache() {
    let needFetch = false;

    const secondsSinceFetch = this._lastFetch
      ? (Date.now() - this._lastFetch.valueOf()) / 1000
      : -1;

    if (this._cache && secondsSinceFetch != -1) {
      console.log(
        `activeNow: last fetch: ${secondsSinceFetch.toFixed(0)} seconds ago`
      );
    } else {
      console.log(`activeNow: last fetch: never`);
    }

    if (!this._lastFetch) {
      needFetch = true;
      console.log(`activeNow: fetching b/c cache is empty`);
    } else if (
      secondsSinceFetch != -1 &&
      secondsSinceFetch >= this._cacheLifetimeSeconds
    ) {
      needFetch = true;
      console.log(`activeNow: fetching b/c staleness`);
    } else if (!this._cache) {
      needFetch = true;
      console.log(`activeNow: fetching b/c cache is empty`);
    }

    if (needFetch) {
      const snap = await this.rtdb.database
        .ref(`/${RTDBPaths.ACTIVE_NOW}/boise`)
        .get();
      this._cache = snap.val() as MarketActiveNow;
      this._lastFetch = new Date();
    } else {
      console.log(`activeNow: returning cached results`);
    }
    return this._cache;
  }

  /**
   * get the active now data for an entire market. fetches from internal cache if applicable.
   * @param market
   * @returns
   */
  async getMarketActiveNowOnce(market: string = 'boise') {
    if (market != 'boise')
      throw new Error(`invalid market ${market}, must be boise`);

    return this.fetchFromCache();
  }

  async searchMarketActiveNow(searchValue: string) {
    const market = await this.fetchFromCache();

    const results: BizServiceSearchResult[] = [];
    console.log(`activeNow: searching ${searchValue}`);

    for (const serviceId in market) {
      const activeNowForService = market[serviceId];
      for (const businessId in activeNowForService) {
        const activeNow = activeNowForService[businessId];
        if (activeNow.businessName) {
          if (
            activeNow.businessName.toLowerCase().trim().indexOf(searchValue) >
            -1
          ) {
            // match on name
            const bizSnap = await this.firestore.firestore
              .doc(`${CollectionNames.BUSINESSES}/${businessId}`)
              .get();
            const business: Business = bizSnap.data() as Business;
            results.push({
              business,
              serviceId,
            });
          }
        }
      }
    }
    return results;
  }
}
