/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import {
  BizActiveNowForServiceRTDB,
  RTDBPaths,
  ToglDataDef,
  ToglDataItem,
  ToglDataType2,
  ToglInfo,
} from '@mojoapps1/mojoapps1common';
import { Observable } from 'rxjs';

export interface AddonConfig {
  matchesType: ToglDataType2[];
  placement: 'start' | 'end';
  text: string;
}

/**
 * handles togldata and validation
 */
@Injectable({
  providedIn: 'root',
})
export class ToglDataService {
  private toglDataLabels: ToglDataDef[];

  constructor(private rtdb: AngularFireDatabase) {}

  private addons: AddonConfig[] = [
    {
      matchesType: [
        ToglDataType2.PRICE_GENERAL,
        ToglDataType2.PRICE_FEE,
        ToglDataType2.PRICE_ADMISSION,
        ToglDataType2.PRICE_HEALTH,
      ],
      placement: 'start',
      text: '$',
    },
  ];

  /**
   * returns addon config if any for a particular data type
   * @param matchesType
   * @returns
   */
  getAddonConfig(matchesType: ToglDataType2) {
    return this.addons.find((v) => v.matchesType.includes(matchesType));
  }

  /**
   * filter and prep togl data for display
   * @param toglData
   * @param exclude
   * @returns
   */
  filterToglData(toglData: ToglDataItem[], exclude?: ToglDataType2[]) {
    let toglDataDisplay: ToglDataItem[] = [];
    if (!toglData) return toglDataDisplay;

    for (const item of toglData) {
      if (exclude && exclude.includes(item.type)) {
        continue;
      }
      let newItem = { ...item };

      if (newItem.type === ToglDataType2.URL) {
        newItem.value = this.prepExternalUrl(newItem.value);
      }

      let addon = this.addons.find((v) => v.matchesType.includes(item.type));

      if (addon) {
        if (addon.placement == 'start') {
          if (!newItem.value.startsWith(addon.text)) {
            newItem.value = addon.text + newItem.value;
          }
        } else if (addon.placement == 'end') {
          if (!newItem.value.endsWith(addon.text)) {
            newItem.value += ' ' + addon.text;
          }
        }
      }

      toglDataDisplay.push(newItem);
    }

    return toglDataDisplay;
  }

  /**
   * filter and prep togl data for display, either pre or post confirmation
   * @param toglData
   * @param exclude
   * @returns
   */
  async filterToglData2(toglData: ToglDataItem[], isPostConfirm: boolean) {
    let toglDataDisplay: ToglDataItem[] = [];
    if (!toglData) return toglDataDisplay;

    for (const item of toglData) {
      const newItem = { ...item };

      if (newItem.type === ToglDataType2.URL) {
        // for urls, we need to decide whether or not it can be shown pre or post confirmation
        // all urls can be shown post-confirm
        console.log(
          `togldata: url label ${newItem.label}, postConfirmMode=${isPostConfirm}`
        );
        if (!isPostConfirm) {
          const onlyPostConfirm = await this.isLinkPostConfirmOnly(
            newItem.label
          );
          console.log(
            `togldata: isLinkPostConfirmOnly result: ${onlyPostConfirm}`
          );
          if (onlyPostConfirm) {
            // don't show the link value, but show that the link exists
            newItem.value = 'Link shown after confirmation';
          } else {
            newItem.value = this.prepExternalUrl(newItem.value);
          }
        } else {
          newItem.value = this.prepExternalUrl(newItem.value);
        }
      }

      let addon = this.addons.find((v) => v.matchesType.includes(item.type));

      if (addon) {
        if (addon.placement == 'start') {
          if (!newItem.value.startsWith(addon.text)) {
            newItem.value = addon.text + newItem.value;
          }
        } else if (addon.placement == 'end') {
          if (!newItem.value.endsWith(addon.text)) {
            newItem.value += ' ' + addon.text;
          }
        }
      }

      toglDataDisplay.push(newItem);
    }

    return toglDataDisplay;
  }

  prepExternalUrl(url: string) {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `https://${url}`;
    }
    return url;
  }

  /**
   * get the metadata label info from the RTDB
   * @returns
   */
  async getToglDataLabels() {
    if (this.toglDataLabels) return this.toglDataLabels;

    const labelRef = this.rtdb.database.ref(`/${RTDBPaths.METADATA_LABELS}`);
    const snap = await labelRef.once('value');
    const tmp = snap.val() as ToglDataDef[];

    this.toglDataLabels = tmp.sort((a, b) => a.order - b.order);

    return this.toglDataLabels;
  }

  /**
   * whether a togl has a post-confirm link
   * @param toglInfo
   * @returns
   */
  async toglHasPostConfirmLink(toglInfo: ToglInfo) {
    if (!toglInfo.toglData) {
      return false;
    }
    for (const toglData of toglInfo.toglData) {
      const isPostConfirm = await this.isLinkPostConfirmOnly(toglData.label);
      if (isPostConfirm) {
        return true;
      }
    }
    return false;
  }

  /**
   * calculate whether a given label for a url field should be shown pre or post confirm
   * @param label
   * @returns
   */
  async isLinkPostConfirmOnly(label: string) {
    if (!this.toglDataLabels) {
      await this.getToglDataLabels();
    }

    const urlDef = this.toglDataLabels.find(
      (def) => def.type == ToglDataType2.URL
    );
    if (urlDef == null) {
      // can't find url data def
      return true;
    }
    const i = urlDef.labels.indexOf(label);
    if (i == -1) {
      // can't find passed-in label
      return true;
    }
    if (
      !urlDef.linkIsPostConfirmOnly ||
      i >= urlDef.linkIsPostConfirmOnly.length
    ) {
      // can't find pre/post info
      return true;
    }
    // return whether link is pre or post
    return urlDef.linkIsPostConfirmOnly[i];
  }
}
