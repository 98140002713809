/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Component, Inject, OnDestroy } from '@angular/core';
import { UserData } from '@mojoapps1/mojoapps1common';
import { Subscription } from 'rxjs';
import { BackendService } from './angular-services/backend.service';
import { FileLog } from './angular-services/FileLog';
import { UIString } from './lang/UIString';

@Component({
  selector: 'app-page-base',
  template: ``,
})
export class PageBaseComponent implements OnDestroy {
  private _subscriptions: Subscription = new Subscription();

  /**
   * stores subscriptions to backend data
   */
  private _backendSubscriptions: Subscription;

  /**
   * to distinguish between initial component showings and subsequent ones
   */
  protected _componentHasBeenShown: boolean = false;

  /**
   * to distinguish between when the child class has added subscriptions and when it has not
   */
  protected _backendSubscriptionsExist: boolean = false;

  /**
   * override in child classes
   */
  protected _className: string = 'base';

  private _filelog: FileLog;

  constructor(protected backendService: BackendService, filelog: FileLog) {
    this._filelog = filelog;
  }

  /**
   * component init. essentially does nothing thanks to Ionic weirdness.
   *
   * just a stub, nothing to see here
   */
  ngOnInit() {
    this.baseLog(`init`);
  }

  /**
   * just a stub, nothing to see here.
   */
  ngOnDestroy() {
    this.baseLog(`destroy`);
  }

  // ngOnDestroy() {
  //   console.log('base: on destroy');
  //   this.clearObserverSubscriptions();
  // }

  /**
   * view is being shown
   *
   * subclasses must call superclass version
   *
   * do all your initialization here instead of `ngOnInit` since Ionic doesn't always destroy components.
   *
   * registers `onBizReady` and `onUserReady` with backend service.
   *
   * calls `setupBackendSubscriptions` if `addBackendSubscription` hasn't been called yet (i.e. if `_backendSubscriptionsExist` is `false`).
   */
  ionViewWillEnter() {
    this.baseLog(
      `view will enter, hasbeenshown=${this._componentHasBeenShown}, subscriptionsExists: ${this._backendSubscriptionsExist}`
    );

    if (!this._backendSubscriptions) {
      this._backendSubscriptions = new Subscription();
      this._backendSubscriptionsExist = false;
    }
    this.baseLog('view will enter, subscribing to biz/user ready events');
    this._backendSubscriptions.add(
      this.backendService
        .onUserReady()
        .subscribe((user) => this.onUserReady(user))
    );
    this.baseLog(
      `view will enter, subscriptionsExists: ${this._backendSubscriptionsExist}`
    );
    if (!this._backendSubscriptionsExist) {
      this.baseLog('setting up backend subscriptions');
      this.setupBackendSubscriptions();
    }
    this._componentHasBeenShown = true;
  }

  /**
   * when component is hidden. calls `clearBackendSubscriptions`
   *
   * subclasses must call superclass version
   */
  ionViewWillLeave() {
    this.baseLog(
      `view will leave, subscriptionsExists: ${this._backendSubscriptionsExist}`
    );

    if (this._backendSubscriptions) {
      this._backendSubscriptions.unsubscribe();
      this._backendSubscriptions = null;
    }
    this._backendSubscriptionsExist = false;
    this.clearBackendSubscriptions();

    this.baseLog(
      `view will leave, after clearing subscriptions, subscriptionsExists: ${this._backendSubscriptionsExist}`
    );
  }

  /**
   * can be called by child class to unsubscribe/resubscribe to backend subscriptions.
   *
   * it's like the child class being able to call `clearBackendSubscriptions` and `setupBackendSubscriptions` manually
   * (i.e. outside of ionViewWillEnter/Leave) while still being able to leave the base class implementations as stubs,
   * so the child class doesn't have to call the superclass versions
   *
   * @param includeUserReady whether to also call userReady again, defaults to false
   */
  refreshBackendSubscriptions(includeUserReady?: boolean) {
    this.baseLog(`refreshing backend subscriptions`);
    // unsub
    if (this._backendSubscriptions) {
      this._backendSubscriptions.unsubscribe();
      this._backendSubscriptions = null;
    }
    this._backendSubscriptionsExist = false;
    this.clearBackendSubscriptions();

    // resub
    if (!this._backendSubscriptions) {
      this._backendSubscriptions = new Subscription();
      this._backendSubscriptionsExist = false;
    }
    if (includeUserReady) {
      this._backendSubscriptions.add(
        this.backendService
          .onUserReady()
          .subscribe((user) => this.onUserReady(user))
      );
    }
    if (!this._backendSubscriptionsExist) {
      this.setupBackendSubscriptions();
    }
    this._componentHasBeenShown = true;
  }

  private baseLog(...args) {
    args.unshift(`base (${this._className}):`);
    this._filelog.log(...args);
    // console.log.apply(this, args);
  }

  /**
   * child class log function
   * @param args
   */
  protected log(...args) {
    args.unshift(`${this._className}:`);
    this._filelog.log(...args);
    // console.log.apply(this, args);
  }

  /**
   * stub to override in child class, called when user ready event fires.
   *
   * called by `ionViewWillEnter` before `setupBackendSubscriptions`
   */
  protected async onUserReady(u: UserData) {
    this.baseLog('onUserReady');
  }

  /**
   * convenience function to expose language file to templates
   * @param key
   * @param replaceValues
   * @returns
   */
  uistring(key: string, replaceValues?: any): string {
    return UIString.format(key, replaceValues);
  }

  addObserverSubscription(s: Subscription) {
    this._subscriptions.add(s);
  }

  // clearObserverSubscriptions() {
  //   this._subscriptions.unsubscribe();
  //   this._subscriptions = new Subscription();
  // }

  /**
   * set up your backend subscriptions!
   *
   * stub to be overridden in child class
   *
   * called by `ionViewWillEnter`
   */
  protected setupBackendSubscriptions() {
    this.baseLog('setupBackendSubscriptions');
  }

  /**
   * clear your backend subscriptions!
   *
   * stub to be overridden in child class
   *
   * called by `ionViewWillLeave`
   */
  protected clearBackendSubscriptions() {
    this.baseLog('clearBackendSubscriptions');
  }

  /**
   * add a subscription. don't override in child class
   * @param s
   */
  protected addBackendSubscription(s: Subscription) {
    if (this._backendSubscriptions) {
      this._backendSubscriptions.add(s);
      this._backendSubscriptionsExist = true;
    }
  }
}
