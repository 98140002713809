/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import {
  Business,
  DateUtil,
  ToglInfo,
  ToglState,
} from '@mojoapps1/mojoapps1common';
import { format } from 'date-fns';
import { Subscription } from 'rxjs';
import { BackendService } from 'src/app/angular-services/backend.service';
import { LocationService } from 'src/app/angular-services/location.service';
import { UIString } from 'src/app/lang/UIString';
import { DateUtil2 } from 'src/app/util/DateUtil2';

@Component({
  selector: 'app-togl-listitem',
  templateUrl: './togl-listitem.component.html',
  styleUrls: ['./togl-listitem.component.scss'],
})
export class ToglListitemComponent implements OnInit, OnDestroy {
  ratingPromptLabel: string;
  dateLabel: string;
  distance: number;
  distanceLabel: string;

  header: string;
  slotTime: string;
  slotDate: string;

  stateLabel: string;
  isActive: boolean = true;

  positionSub: Subscription;

  constructor(
    private backendService: BackendService,
    private location: LocationService
  ) {}

  /**
   * the togl item we're rendering
   */
  @Input() set toglItem(val: ToglInfo) {
    this._toglItem = val;
    this.dateLabel = this.backendService.getToglinfoDateLabel(
      this.toglItem,
      true
    );
    if (
      this._toglItem.state === ToglState.COMPLETED &&
      this._toglItem.bizReceivedRating == null
    ) {
      this.ratingPromptLabel = UIString.format('LABEL_NEEDS_RATING');
    } else {
      this.ratingPromptLabel = null;
    }
  }
  get toglItem(): ToglInfo {
    return this._toglItem;
  }
  private _toglItem: ToglInfo;

  @Input() thumbnailUrl: string;

  /**
   * component initialization
   */
  async ngOnInit() {
    if (!this._toglItem.service) {
      throw new Error('listitem: missing service: ' + this._toglItem.serviceId);
    }

    switch (this.toglItem.state) {
      case ToglState.USER_PENDING:
        this.stateLabel = UIString.format('TSTATE_USER_PENDING');
        break;
      case ToglState.CONFIRMED:
        this.stateLabel = UIString.format('TSTATE_CONFIRMED');
        break;
      case ToglState.COMPLETED:
        this.stateLabel = UIString.format('TSTATE_COMPLETED');
        this.isActive = false;
        break;
      case ToglState.TAKEN:
        this.stateLabel = UIString.format('TSTATE_TAKEN');
        this.isActive = false;
        break;
      case ToglState.DENIED:
        this.stateLabel = UIString.format('TSTATE_DENIED');
        this.isActive = false;
        break;
      case ToglState.EXPIRED:
        this.stateLabel = UIString.format('TSTATE_EXPIRED');
        this.isActive = false;
        break;
      case ToglState.CANCELED_ATFAULT:
        this.stateLabel = UIString.format('TSTATE_CANCELED_ATFAULT');
        this.isActive = false;
        break;
      case ToglState.USER_CANCELED:
        this.stateLabel = UIString.format('TSTATE_USER_CANCELED');
        this.isActive = false;
        break;
      case ToglState.USER_CANCELED_ATFAULT:
        this.stateLabel = UIString.format('TSTATE_USER_CANCELED_ATFAULT');
        this.isActive = false;
        break;
      case ToglState.NOSHOW_ATFAULT:
        this.stateLabel = UIString.format('TSTATE_NOSHOW_ATFAULT');
        this.isActive = false;
        break;
    }

    this.updateDistance();

    this.positionSub = this.location
      .getPositionUpdates()
      .subscribe((userPosition) => this.updateDistance());

    this.header = this._toglItem.service.title;
    if (this._toglItem.samedaySlot) {
      this.slotTime = this.generateTimeslotLabel(this._toglItem.samedaySlot);
      this.slotDate = DateUtil2.formatDate(
        this._toglItem.samedaySlot.start.toDate()
      );
    }
  }

  ngOnDestroy() {
    if (this.positionSub) {
      this.positionSub.unsubscribe();
    }
  }

  updateDistance() {
    this.distance = this.location.getUserDistanceTo(
      this.toglItem.business.lat,
      this.toglItem.business.long
    );
    this.distanceLabel = isNaN(this.distance)
      ? 'unknown mi'
      : this.distance.toFixed(1) + UIString.format('LABEL_RANGE_MILES');
  }

  generateTimeslotLabel(t: any): string {
    return DateUtil2.formatTime(t.start) + ' - ' + DateUtil2.formatTime(t.end);
  }
}
