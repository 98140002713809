/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Component, OnInit, Input } from '@angular/core';
import { Service } from '@mojoapps1/mojoapps1common';
import { Router, ActivatedRoute } from '@angular/router';
import {
  ActiveNearby,
  BackendService,
} from 'src/app/angular-services/backend.service';
import { Subscription } from 'rxjs';
import { IncDecAnimation } from 'src/app/util/animations';
import { UIString } from 'src/app/lang/UIString';
import { IconInfo, IconsService } from 'src/app/angular-services/icons.service';
import { AlertService } from 'src/app/angular-services/alert.service';
import { FileLog } from 'src/app/angular-services/FileLog';
import { LocationService } from 'src/app/angular-services/location.service';

@Component({
  selector: 'app-service-griditem',
  templateUrl: './service-griditem.component.html',
  styleUrls: ['./service-griditem.component.scss'],
  animations: [IncDecAnimation],
})
export class ServiceGridItemComponent implements OnInit {
  /**
   * the service object we're rendering
   */
  @Input() item: Service;

  /**
   * the color of the icon, an ionic color (i.e. "primary", "secondary", etc). defaults to primary
   */
  @Input() iconColor: string;

  /**
   * active nearby
   */
  @Input() set activeNearby(val: ActiveNearby) {
    this._activeNearby = val;
  }
  get activeNearby(): ActiveNearby {
    return this._activeNearby;
  }
  private _activeNearby: ActiveNearby;

  /**
   * map range
   */
  @Input() mapRange: number;

  // icon: IconInfo;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private backendService: BackendService,
    private icons: IconsService,
    private alerts: AlertService,
    private filelog: FileLog,
    private location: LocationService
  ) {}

  async ngOnInit() {
    // default to icon color, a special defined color in the template
    if (!this.iconColor) this.iconColor = 'icon';

    // const info = await this.icons.getIconInfo(this.item.icon);
    // this.icon = info;
  }

  private noBusinessesFound() {
    var header = UIString.format('HEADER_NO_BUSINESSES', {
      service: this.item.title,
    });
    var message = UIString.format('NOTIF_NO_BUSINESSES', {
      range: this.mapRange,
      service: this.item.title,
    });

    //`No business is available within ${this.mapRange} miles for ${this.serviceItem.title} at the moment.  Would you like to receive an alert when one becomes available?`;

    this.backendService.presentNoActiveToglAlert(header, message, this.item);
  }

  /**
   * navigate to map to show service
   * @param overrideRange
   */
  gotoMap(overrideRange: number = -1) {
    const serviceId: string = this.item.id;
    // navigate to map

    const state: any = { serviceId };
    if (overrideRange !== -1) {
      state.overrideRange = overrideRange;
    }

    this.router.navigate(['/app/map'], { state });

    // const extras =
    //   overrideRange !== -1
    //     ? {
    //         state: { overrideRange, serviceId },
    //       }
    //     : undefined;

    // this.router.navigate(['/app/map', { serviceId }], extras);

    // this.router.navigateByUrl(
    //   `/app/discover/map/${serviceId}`,
    //   overrideRange !== -1
    //     ? {
    //         state: { overrideRange },
    //       }
    //     : undefined
    // );
  }

  /**
   * click handler
   * @returns
   */
  private async processMapClick() {
    if (this.activeNearby == null) return; //not loaded yet

    if (!this.location.locationPermitted) {
      // they haven't given location permission, go to the map and let the map sort it out
      this.gotoMap();

      return;

      // request permission and if not granted, bail
      // const permitted = await this.location.initialize();
      // if (!permitted) {
      //   return;
      // }
    }

    if (this.activeNearby.withinRange == 0) {
      if (this.activeNearby.extended > 0) {
        // none found nearby, but we found some at max range
        let header = UIString.format('HEADER_EXPAND_SEARCH', {
          service: this.item.title,
        });
        //`Expand Search Range for ${this.serviceItem.title}?`;
        let message = UIString.format('NOTIF_EXPAND_SEARCH', {
          service: this.item.title,
          range: this.mapRange,
        });
        // `We didn't find any businesses for ${this.serviceItem.title} within ${this.mapRange} miles, but we did find some within a larger range. Would you like to see them?`;
        this.alerts.yesNoAlert(
          header,
          message,
          null,
          null,
          () => {
            // yes
            // show the businesses in the larger range
            this.gotoMap(this.backendService.getMaxMapRange());
          },
          () => {
            // no
            this.noBusinessesFound();
          }
        );
      } else {
        // no businesses found in either range
        this.noBusinessesFound();
      }
    } else {
      // show the found businesses
      this.gotoMap();
    }
  }

  /**
   * when the component is clicked
   */
  async onClick() {
    this.filelog.log(
      `service griditem: id ${this.item.id} (${this.item.title}), active nearby: `,
      this.activeNearby
    );
    await this.processMapClick();
  }
}
