/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
// import { Events } from '@ionic/angular'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AverageRating } from '@mojoapps1/mojoapps1common';
import { FileLog } from 'src/app/angular-services/FileLog';
import { RatingService } from 'src/app/angular-services/rating.service';

@Component({
  selector: 'app-avg-biz-rating',
  templateUrl: './avg-biz-rating.component.html',
  styleUrls: ['./avg-biz-rating.component.scss'],
})
export class AvgBizRating implements OnInit {
  avgRatingLabel: string;
  totalRatingLabel: string;

  wholeStars: number;
  hasHalfStar: boolean;

  @Input() set avgRating(val: AverageRating) {
    this._avgRating = val;

    if (val && val.count != 0) {
      this.avgRatingLabel = val.avg.toFixed(1);
      const label = val.count == 1 ? 'rating' : 'ratings';
      this.totalRatingLabel = `(${val.count})`; // `(${val.count} ${label})`;

      this.wholeStars = Math.floor(val.avg);
      const fractionStars = val.avg - this.wholeStars;
      if (fractionStars >= 0.5) {
        this.hasHalfStar = true;
      } else {
        this.hasHalfStar = false;
      }
      this.filelog.log(
        `avg-star-rating: whole: rating: ${this.avgRatingLabel}, ${this.wholeStars}, half: ${this.hasHalfStar}`
      );
    } else {
      this.avgRatingLabel = '0';
      this.totalRatingLabel = `(no ratings yet!)`;
      this.wholeStars = 0;
      this.hasHalfStar = false;
    }
  }
  get avgRating() {
    return this._avgRating;
  }
  private _avgRating: AverageRating;

  @Input() hideTotal: boolean = false;
  @Input() inline: boolean = false;
  /**
   * `normal` for blue, 4* format
   * `bizheader` for yellow, **** format
   */
  @Input() mode: 'normal' | 'bizheader' = 'normal';

  ngOnInit(): void {}

  constructor(private filelog: FileLog) {}

  iconNames() {
    const ret: string[] = [];
    for (let i = 0; i < 5; i++) {
      ret.push(this.iconName(i));
    }
    return ret;
  }

  /**
   * template function to choose icon
   * @param i
   * @returns
   */
  iconName(i: number) {
    return i < this.wholeStars
      ? 'star'
      : this.hasHalfStar && i == this.wholeStars
      ? 'star-half'
      : 'star-outline';
  }
}
