/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Injectable } from '@angular/core';

import { AverageRating, RTDBPaths } from '@mojoapps1/mojoapps1common';
import { AngularFireDatabase } from '@angular/fire/database';

/**
 * get user and business ratings from RTDB
 */
@Injectable({
  providedIn: 'root',
})
export class RatingService {
  constructor(private rtdb: AngularFireDatabase) {}

  getUserRatingValueChangesRTDB(userId: string) {
    return this.rtdb
      .object<AverageRating>(`/${RTDBPaths.USER_RATINGS}/${userId}/_avg`)
      .valueChanges();
  }

  async getUserRatingOnce(userId: string) {
    const snap = await this.rtdb.database
      .ref(`/${RTDBPaths.USER_RATINGS}/${userId}/_avg`)
      .once('value');
    return snap.val() as AverageRating;
  }

  /**
   * convenience function to just get user rating updates as a string suitable for frontend display
   * @param userId user id
   * @param onLabelChange a function that's called with the updated label
   * @returns a subscription, save this and call unsubscribe on it when done
   */
  subscribeUserRatingLabelChanges(
    userId: string,
    onLabelChange: (label: string) => void
  ) {
    return this.getUserRatingValueChangesRTDB(userId).subscribe((val) => {
      const label = val ? val.avg.toFixed(1) : 'unknown';
      return onLabelChange(label);
    });
  }

  getBusinessRatingValueChangesRTDB(businessId: string) {
    return this.rtdb
      .object<AverageRating>(`/${RTDBPaths.BIZ_RATINGS}/${businessId}/_avg`)
      .valueChanges();
  }

  async getBusinessRatingOnce(businessId: string) {
    const snap = await this.rtdb.database
      .ref(`/${RTDBPaths.BIZ_RATINGS}/${businessId}/_avg`)
      .once('value');
    return snap.val() as AverageRating;
  }
}
