/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import {
  BizSamedaySlot,
  BizServiceInfo,
  Business,
  DateUtil,
  CollectionNames,
  Togl,
  ToglDataItem,
  UserData,
} from '@mojoapps1/mojoapps1common';
import {
  add,
  isAfter,
  parseISO,
  roundToNearestMinutes,
  formatISO,
} from 'date-fns';
import {
  BackendService,
  ServiceAndSlotInfo,
} from 'src/app/angular-services/backend.service';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { AngularFirestore } from '@angular/fire/firestore';
import { UIString } from 'src/app/lang/UIString';
import { PageBaseComponent } from 'src/app/pagebase.component';
import { isPositiveNumber } from './validators';
import { FileLog } from 'src/app/angular-services/FileLog';

interface ToglDataUserInput extends ToglDataItem {
  /**
   * index back into togldataitem array
   */
  toglDataIndex: number;
  /**
   * name of corresponding form control
   */
  formControlName: string;
}

@Component({
  selector: 'modal-request-togl',
  templateUrl: './modal-request-togl.component.html',
  styleUrls: ['./modal-request-togl.component.scss'],
})
export class ModalRequestTogl extends PageBaseComponent implements OnInit {
  @Input() set serviceInfo(val: ServiceAndSlotInfo) {
    // make a (shallow) copy of the passed-in data
    this._serviceInfo = { ...val };
    this.log('set serviceInfo', val);

    // for a modal, apparently properties are set before ngOnInit fires, so we're done here
  }
  get serviceInfo(): ServiceAndSlotInfo {
    return this._serviceInfo;
  }
  private _serviceInfo: ServiceAndSlotInfo;

  @Input() business: Business;

  title: string;
  message: string;
  subtitle: string;
  formGroup: FormGroup;

  minNumericValue: number = 1;

  /**
   * togl data items requiring user input
   */
  userInput: ToglDataUserInput[];

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    backendService: BackendService,
    private firestore: AngularFirestore,
    private filelog: FileLog
  ) {
    super(backendService, filelog);
    this._className = 'modal-request-togl';
  }

  protected async onUserReady(u: UserData): Promise<void> {
    // for modals, apparently setting properties comes before ngOnInit
    if (!this._serviceInfo) {
      throw new Error('missing serviceinfo');
    }

    this.title = UIString.format('TITLE_REQUEST_TOGL');

    // parse the togl data for things that need user input
    this.userInput = [];
    if (this._serviceInfo.toglData) {
      for (let i = 0; i < this._serviceInfo.toglData.length; i++) {
        const item = this._serviceInfo.toglData[i];

        if (item.type === 'quantity') {
          this.userInput.push({
            toglDataIndex: i,
            formControlName: 'value_' + i,
            value: item.value,
            userResponse: '',
            type: item.type,
            label: item.label,
          });
        }
      }
    }

    const formInit: any = {};
    if (this.userInput.length === 0) {
      // no user input needed
      this.message = UIString.format('NOTIF_CONFIRM_REQUEST_TOGL', {
        bizname: this.business.name,
        service: this._serviceInfo.title,
      });
    } else {
      // prep for user input
      this.message = UIString.format('NOTIF_CONFIRM_REQUEST_TOGL_INPUT', {
        bizname: this.business.name,
        service: this._serviceInfo.title,
      });

      for (const item of this.userInput) {
        formInit[item.formControlName] = new FormControl(
          this.minNumericValue.toString(),
          isPositiveNumber
        );
      }
    }
    this.formGroup = this.formBuilder.group(formInit);
  }

  /**
   * when a increment/decrement button is clicked
   * @param item
   * @param amount
   */
  addValue(item: ToglDataUserInput, amount) {
    let c = this.formGroup.get(item.formControlName);
    let val = parseInt(c.value);
    if (isNaN(val)) val = this.minNumericValue;
    val += amount;
    if (val < this.minNumericValue) val = this.minNumericValue;
    c.setValue(val.toString());
  }

  /**
   * when the send button is clicked, send form data to calling code. the data is an array of ToglDataItem objects.
   */
  async onClickSend() {
    // integrate the data from the form (if any) into a copy of our model and send it
    let dataToSend: ToglDataItem[] = [];
    if (this._serviceInfo.toglData) {
      for (const item of this._serviceInfo.toglData) {
        dataToSend.push({ ...item });
      }
    }

    if (this.userInput.length > 0) {
      for (const item of this.userInput) {
        const response = this.formGroup.get(item.formControlName).value;
        dataToSend[item.toglDataIndex].userResponse = response;
      }
    }

    this.log('send', dataToSend);
    return this.modalCtrl.dismiss(dataToSend, 'send');
  }

  /**
   * when cancel is clicked
   */
  async onClickCancel() {
    return this.modalCtrl.dismiss(undefined, 'cancel');
  }

  // onDeleteClick() {
  //   if (!this.editMode) return;

  //   // TODO: don't allow deletion if there's a togl associated with it
  //   this.backendService
  //     .collectionRef<Togl>(CollectionNames.TOGLS, (ref) =>
  //       ref
  //         .where('businessId', '==', this.business.businessID)
  //         .where('samedaySlotId', '==', this.slot.id)
  //     )
  //     .get()
  //     .subscribe((snap) => {
  //       console.log(`modal-timeslot: deleteClick, slot has ${snap.size} togls`);
  //       if (snap.empty) {
  //         // we can delete
  //         this.backendService.genericConfirm(
  //           UIStringPro.format('NOTIF_DELETE_TIMESLOT'),
  //           () => {
  //             this.backendService
  //               .docRef(CollectionNames.BIZ_SAMEDAYSLOTS, this.slot.id)
  //               .delete()
  //               .then(() => {
  //                 console.log('modal-timeslot: deleted slot');
  //                 this.dismiss();
  //               });
  //           },
  //           UIStringPro.format('HEADER_ARE_YOU_SURE')
  //         );
  //       } else {
  //         // cannot delete
  //         this.backendService.openOkAlert(
  //           UIStringPro.format('NOTIF_DELETE_TIMESLOT_HAS_TOGL')
  //         );
  //       }
  //     });
  // }

  // `required`, `notANumber`, `notPositive`
  validation_messages = {
    numeric: [
      { type: 'required', message: 'This field is required' },
      { type: 'notANumber', message: 'Please enter a number' },
      { type: 'notPositive', message: 'Number must be positive' },
    ],
  };
}
