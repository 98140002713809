import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { AlertService } from '../angular-services/alert.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private _hasMsgBox: boolean = false;

  constructor(private alerts: AlertService, private zone: NgZone) {
    console.log(`global error handler: constructor`);

    window.addEventListener('unhandledrejection', (event) => {
      this.handleError(event);
    });
  }

  handleError(error: any) {
    // Check if it's an error from an HTTP response
    if (error instanceof PromiseRejectionEvent) {
      // unhandled promise
      console.log(error.reason);
    }
    // if (!(error instanceof HttpErrorResponse)) {
    //   error = error.rejection; // get the error object
    // }
    if (this._hasMsgBox) return;
    this._hasMsgBox = true;
    this.zone.run(() => {
      return this.alerts
        .genericConfirm2(
          `<p>An unexpected error has occurred, please check your network connection and try again.</p><p>Would you like to refresh the app?</p>`,
          `Error`
        )
        .then((confirmed) => {
          this._hasMsgBox = false;
          if (confirmed) {
            location.reload();
          }
        })
        .catch(() => {
          this._hasMsgBox = false;
        });
    });

    // this.alerts.alertOk(
    //   error?.message || 'A network error has occurred, please try again.'
    // );

    console.error('global error handler: Error occurred', error);
  }
}
