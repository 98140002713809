/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  docChanges,
  QueryFn,
} from '@angular/fire/firestore';
import {
  concat,
  defer,
  forkJoin,
  Observable,
  of,
  Subject,
  Subscription,
  zip,
} from 'rxjs';
// import { businesses as bizjson } from './businesses.json';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SVGUtil } from '../util/SVGUtil';
import { Storage } from '@ionic/storage';
import { ToastController, AlertController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import {
  BackendServiceBase,
  ToglState,
  Business,
  Category,
  Service,
  UserData,
  ToglInfo,
  Togl,
  BizServices,
  BizService,
  BizServiceInfo,
  bizServiceToBizServiceInfo,
  CollectionNames,
  UserWatchedService,
  BusinessesActiveNow,
  DateUtil,
  BizSamedaySlot,
  UserPrefs,
  UserRole,
  UserFavorite,
  UserPushToken,
  BizActiveNowStats,
  ToglDataItem,
  BizActiveNowForServiceRTDB,
  PushTypes,
  WatchedServicesRTDB,
  BizActiveNowRTDB,
  ToglStateListTypes,
  ToglStateList,
} from '@mojoapps1/mojoapps1common';
import { environment } from '../../environments/environment';
import { Maintenance } from '../util/Maintenance';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { UIString } from '../lang/UIString';
import { map, mergeMap } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { LocationService } from './location.service';
// import { default as bizImport } from './_dedupe-all-records-out.json';
// import {} from 'google.maps';
import { Geolocation } from '@capacitor/geolocation';
import { AngularFireDatabase } from '@angular/fire/database';
import { PushNotifService } from './pushnotif.service';
import { FavoritesService } from './favorites.service';
import { WatchedServicesService } from './watchedservices.service';
import { ActiveNowService } from './activenow.service';
import { DateUtil2 } from '../util/DateUtil2';
import { AlertService } from './alert.service';
import { FileLog } from './FileLog';
import { ConfigService } from './config.service';
import { PrefsRTDBService } from './prefsrtdb.service';
import { Parameter } from '@angular/fire/remote-config';
import { ToglDataService } from './togldata.service';
import { App } from '@capacitor/app';

export interface ActiveNearby {
  range: number;
  withinRange: number;
  extended: number;
}

export interface ServiceAndSlotInfo extends BizService {
  title?: string;
  icon?: string;
  isMobile?: boolean;
  radioValue?: string;
  slot?: BizSamedaySlot;
}

@Injectable({
  providedIn: 'root',
})
export class BackendService extends BackendServiceBase {
  private userData: UserData;
  private userCompositeSubscription: Subscription;
  private toglCompositeSubscription: Subscription;
  private watchedServices: WatchedServicesRTDB;
  // private userFavorites: UserFavorite;
  private watchedActiveNow: {
    [key: string]: BizActiveNowForServiceRTDB;
  } = {};
  private watchedActiveNowSubscriptions: {
    [key: string]: Subscription;
  } = {};

  private toglInfoSubjects: {
    [key: string]: Subject<ToglInfo[]>;
  };
  private toglInfoLists: {
    [key: string]: ToglInfo[];
  };

  private _isUserReady: boolean;
  private _onUserReady: Subject<UserData>;

  private currentTheme: string = 'default';

  private renderer: Renderer2;

  private activeNowValuechanges: {
    [key: string]: Observable<BusinessesActiveNow>;
  } = {};

  private _redirectOnLogin: string;

  private _maxMapRange: number;
  private _minMapRange: number;

  /**
   * temporarily stores user info during signup
   */
  private _extraSignupData: {
    name: string;
    email: string;
    password: string;
    phone: string;
    birthYear?: string;
    gender?: string;
    zipCode?: string;
  };

  /**
   * which notifications are handled manually when in web mode
   */
  private static readonly WEB_NOTIF_INFO: {
    state: ToglState;
    onAppStartup?: boolean;
  }[] = [
    {
      state: ToglState.CONFIRMED,
      onAppStartup: true,
    },
    {
      state: ToglState.COMPLETED,
      onAppStartup: false,
    },
    {
      state: ToglState.DENIED,
      onAppStartup: false,
    },
    {
      state: ToglState.EXPIRED,
      onAppStartup: false,
    },
    {
      state: ToglState.TAKEN,
      onAppStartup: false,
    },
    {
      state: ToglState.NOSHOW_ATFAULT,
      onAppStartup: false,
    },
    {
      state: ToglState.CANCELED_ATFAULT,
      onAppStartup: false,
    },
  ];

  constructor(
    private platform: Platform,
    private firestore: AngularFirestore,
    private http: HttpClient,
    private storage: Storage,
    public toastCtrl: ToastController,
    private router: Router,
    private rendererFactory: RendererFactory2,
    private alertCtrl: AlertController,
    private alerts: AlertService,
    private auth: AngularFireAuth,
    private fns: AngularFireFunctions,
    private afStorage: AngularFireStorage,
    private db: AngularFireDatabase,
    private location: LocationService,
    private pushNotifService: PushNotifService,
    private watchedService: WatchedServicesService,
    private activeNowService: ActiveNowService,
    private filelog: FileLog,
    private config: ConfigService,
    private favorites: FavoritesService, // not used, but needed to initialize favorites service at app startup!
    private prefs: PrefsRTDBService,
    private toglData: ToglDataService
  ) {
    super(firestore, storage, environment, false);

    this.renderer = this.rendererFactory.createRenderer(null, null);

    this.toglInfoSubjects = {};
    this.toglInfoLists = {};

    this._onUserReady = new Subject<UserData>();
    this._isUserReady = false;
    this.initializeUIStrings();

    // load google maps with the right API key for this environment
    this.http
      .jsonp(
        `https://maps.googleapis.com/maps/api/js?key=${environment.gmapsApiKey}`,
        'callback'
      )
      .subscribe((obj) => {
        this.filelog.log('backend: google maps loaded');
      });

    // respond to auth events
    this.auth.authState.subscribe(async (u) => this.onAuthStateEvent(u));

    // respond to foreground/background events
    // App.addListener('appStateChange', (state) => {
    //   if (!this.userData) {
    //     return;
    //   }

    //   if (state.isActive) {
    //     // re-load active togls when the app regains focus
    //     this.createToglSubscriptions(true);
    //   }
    // });
    // // i believe this gets called when the app is opened from a notification
    // App.addListener('appUrlOpen', (event) => {
    //   this.filelog.log(`backend: opened via url ${event.url}`);

    //   if (!this.userData) return;

    //   // re-load active togls when the app regains focus
    //   this.createToglSubscriptions(true);
    // });

    // config
    this.config
      .getConfigValueChanges(ConfigService.MAX_MAP_RANGE)
      .subscribe((val) => (this._maxMapRange = val.asNumber()));
    this.config
      .getConfigValueChanges(ConfigService.MIN_MAP_RANGE)
      .subscribe((val) => (this._minMapRange = val.asNumber()));
  }

  /**
   * handles auth state events from firebase auth. fires once on app load and once on each user log in/out
   * @param user null if user logged out, non-null if user logged in
   */
  async onAuthStateEvent(user: firebase.User) {
    this.filelog.log('backend: authState event, is login:', !!user);
    // if (this.getDatabase() == BackendServiceBase.DB_DEV) {
    //   this.filelog.log(JSON.stringify(user));
    // }

    // u != null => user logged in
    // u == null => user logged out

    if (!user) {
      // user is not logged in OR user just logged out

      this.filelog.log('backend: initial load w/o user or user has logged out');
      await this.unsetCurrentUser();
      await this.router.navigateByUrl('/login');
      return;
    }

    // user signed up OR logged in
    const uid = user.uid;
    this.filelog.log(
      `backend: authstate event for user: ${uid} (${user.email}), verified=${user.emailVerified}`
    );

    // first, did the user just sign up? do some initial setup
    if (this._extraSignupData && user.email == this._extraSignupData.email) {
      this.filelog.log('backend: setting up user for the first time');
      // add name to firebase user profile
      await user.updateProfile({
        displayName: this._extraSignupData.name,
      });

      // create user data if it doesn't exist yet
      const snap = await this.docRef<UserData>(CollectionNames.USERS, uid)
        .get()
        .toPromise();
      if (!snap.exists) {
        let defaultData: UserData = {
          id: uid,
          email: this._extraSignupData.email,
          displayName: this._extraSignupData.name,
          phoneNumber: this._extraSignupData.phone,
          role: UserRole.CONSUMER,
          position: {
            lat: 43.700245,
            long: -116.5064,
          },
          birthYear: this._extraSignupData.birthYear || null,
          gender: this._extraSignupData.gender || null,
          zipCode: this._extraSignupData.zipCode || null,
        };
        await this.docRef<UserData>(CollectionNames.USERS, uid).set(
          defaultData
        );
        this.filelog.log('backend: created user data');
      }
      // clear extra signup data
      this._extraSignupData = null;
    } else if (this._extraSignupData) {
      // something went wrong
      this.filelog.log(
        'backend: error, extra signup data does not match firebase email address'
      );
      this.filelog.log(JSON.stringify(this._extraSignupData));
      this.filelog.log(JSON.stringify(user));
      this._extraSignupData = null;
    }

    // is user email verified? this fires on initial user creation AND if you log in with an unverified email
    if (!user.emailVerified) {
      // send verification email, show a message, and log them out
      try {
        await user.sendEmailVerification();
        // await this.alerts.alertOk(
        //   `Email verification link sent to <br /><br />${user.email}.<br /><br /> Please click the link in the email to continue.`
        // );
        await this.alerts.alertOk(
          UIString.format('NOTIF_EMAIL_UNVERIFIED', { email: user.email })
        );
      } catch (e) {
        await this.alerts.alertOk('error: ' + e.message);
        throw e;
      } finally {
        // log the user out
        return this.logout();
      }
    }

    // proceed with normal user login!
    try {
      // preload service and category data
      await this.preloadServicesAndCategories();

      // set up current user
      await this.setCurrentUser(uid);

      // push notifications
      await this.registerForPushNotifications();

      // request position once to prime the pump
      await this.location.getPositionOnce(true);

      // now ready
      this.filelog.log('backend: onReady');
      this._isUserReady = true;
      this._onUserReady.next(this.userData);
      this._onUserReady.complete();

      // maintenance?
      // const m = new Maintenance(this.firestore, this);
      // await m.countBusinesses();
      // await m.maintenance_2023_02_02();
      // this.maintenance();

      // console.log('MAINTENANCE');
      // const m = new Maintenance(this.firestore, this);
      // await m.toglsPerUserReport();
      // console.log('MAINTENANCE END');
    } catch (err) {
      this.filelog.log(
        'backend: error logging in user: ' + JSON.stringify(err)
      );
      throw err;
    }
  }

  /**
   * create firebase user and userdata and log them in. throws on error
   * @param email
   * @param password
   * @param name
   */
  async createUser(
    email: string,
    password: string,
    name: string,
    phone: string,
    birthYear?: string,
    gender?: string,
    zipCode?: string
  ) {
    if (!name) throw new Error('name is required');
    if (!password) throw new Error('password is required');
    if (!email) throw new Error('email is required');
    if (!phone) throw new Error('phone is required');

    try {
      // kinda hacky, but, store temp user data for the auth state event to use to finish creating hte user
      this._extraSignupData = {
        name,
        email,
        password,
        phone,
        birthYear,
        gender,
        zipCode,
      };

      // sign up user with firebase. this will also sign the user in and trigger an authstate event
      // this authstate event will finish the user creation process with the temp data we saved
      const userCredential = await this.auth.createUserWithEmailAndPassword(
        email,
        password
      );
    } catch (e) {
      this.filelog.log(
        'backend: signup: error: ' + e.message + ', stack: ' + e.stack
      );
      this.filelog.log(e);
      throw e;
    }
  }

  /**
   * create default user data if user has none
   * @param u the user
   */
  async createUserDataIfNeeded(u: firebase.User) {
    const snap = await this.docRef<UserData>(CollectionNames.USERS, u.uid)
      .get()
      .toPromise();

    if (snap.exists) {
      // new record matching uid, all good
      this.filelog.log('backend: user data loaded');
    } else {
      // no user data exists, create some defaults
      let defaultData: UserData = {
        email: u.email,
        id: u.uid,
        position: {
          lat: 43.700245,
          long: -116.5064,
        },
        displayName: u.displayName,
        role: UserRole.CONSUMER,
      };
      await this.docRef<UserData>(CollectionNames.USERS, u.uid).set(
        defaultData
      );
      this.filelog.log('backend: created new default user record successfully');
    }
  }

  // private maintenance() {
  // maintenance

  // this.filelog.log('backend: starting maintenance');
  // let m: Maintenance = new Maintenance(this.firestore, this);
  // await m.maintenance_2022_03_25();

  // await m.maintenance_2022_03_03();
  // await m.maintenance_2021_08_06();
  // await m.maintenance_2021_08_17();
  // this.filelog.log('backend: maintenance finished');

  // add enabled flag to each category
  // this.collectionRef<Category>(CollectionNames.CATEGORIES)
  //   .get()
  //   .subscribe((qs) => {
  //     qs.docs.forEach((doc) => {
  //       doc.ref.update({ enabled: true });
  //     });
  //   });

  // delete all togls for kheftel's user id
  // this.collectionRef<Togl>(CollectionNames.TOGLS, (ref) =>
  //   ref.where('userId', '==', 'H5697e71ZGQT5jCSpqpBFQ7qiE32')
  // )
  //   .get()
  //   .subscribe((qs) => {
  //     qs.docs.forEach((doc) => {
  //       doc.ref.delete();
  //     });
  //   });

  // this.collectionRef<Service>(CollectionNames.SERVICES)
  //   .get()
  //   .subscribe((qs) => {
  //     qs.docs.forEach((snap) => {
  //       if (snap.exists && !snap.data().isMobile) {
  //         snap.ref.update({
  //           isMobile: false,
  //         });
  //       }
  //     });
  //   });

  // the following code outputs all service names with their IDs
  // let msg = 'services:\n';
  // let services = this.servicesForSearch.sort((a, b) =>
  //   parseInt(a.id) < parseInt(b.id) ? -1 : 1
  // );
  // for (const s of services) {
  //   msg += s.id + ', ' + s.title + '\n';
  // }
  // this.filelog.log(msg);

  // the following code converts the categories and services to CSV
  // for import into ninja forms.

  // let msg = 'CATEGORIES\n';
  // let csv = '';
  // for (const cat of this._allCategoriesCache) {
  //   csv += cat.title + ',' + cat.id + ",''" + '\n';
  // }
  // msg += csv + '\n\n';

  // msg += 'SERVICES\n';
  // for (const cat of this._allCategoriesCache) {
  //   let services = this._allServicesCache.filter((s) =>
  //     s.catId.includes(cat.id)
  //   );
  //   csv = '';
  //   for (const s of services) {
  //     csv += s.title + ',' + s.id + ",''" + '\n';
  //   }
  //   msg += cat.title + '\n';
  //   msg += csv + '\n\n';
  // }
  // this.filelog.log(msg);
  // }

  consumeRedirectOnLogin(): string {
    if (this._redirectOnLogin) {
      let ret = this._redirectOnLogin;
      this._redirectOnLogin = null;
      return ret;
    }
    return null;
  }

  setRedirectOnLogin(url: string) {
    this._redirectOnLogin = url;
  }

  async registerForPushNotifications() {
    if (!this.userData) throw new Error('user not logged in');

    if (!this.pushNotifService.isSupported) {
      this.filelog.log(
        'backend: push notifications not supported on this platform'
      );
      return;
    }

    await this.pushNotifService.onLogin(this.userData.id);

    this.pushNotifService.onPushNotif().subscribe(async (data) => {
      // show a user togl notification
      let url: string;
      switch (data.type) {
        case PushTypes.USER_TOGL:
          if (data.toglId) {
            let info: ToglInfo = await this.getToglInfoOnce(data.toglId);
            await this.showUserToglNotification(info);
          }
          break;
        case PushTypes.USER_FAVORITE:
          url = `/app/discover/biz/${data.businessId}/services/${data.serviceId}`;
          await this.alerts.openNotificationAlert(
            UIString.format('HEADER_FAVORITE_BUSINESS_ALERT'),
            UIString.format('NOTIF_FAVORITE_BUSINESS', {
              bizname: data.bizName,
              service: data.serviceTitle,
            }),
            url
          );
          break;
        case PushTypes.USER_WATCHED_SERVICE:
          url = `/app/discover/biz/${data.businessId}/services/${data.serviceId}`;
          await this.alerts.openNotificationAlert(
            UIString.format('HEADER_NEARBY_BUSINESS_ALERT'),
            UIString.format('NOTIF_NEARBY_BUSINESS', {
              bizname: data.bizName,
              service: data.serviceTitle,
            }),
            url
          );
          break;
        case PushTypes.USER_TOGL_EXPIRING:
          if (data.toglId) {
            let info: ToglInfo = await this.getToglInfoOnce(data.toglId);
            let duration = this.getToglExpirationString(info);
            url = `/app/togls`;
            await this.alerts.openNotificationAlert(
              UIString.format('HEADER_TOGL_EXPIRING'),
              UIString.format('NOTIF_TOGL_EXPIRING', {
                bizname: data.bizName,
                service: data.serviceTitle,
                duration,
              }),
              url
            );
          }
          break;
        case PushTypes.USER_TOGL_EXPIRED:
          url = `/app/discover`;
          await this.alerts.openNotificationAlert(
            UIString.format('HEADER_TOGL_EXPIRED'),
            UIString.format('NOTIF_TOGL_EXPIRED', {
              bizname: data.bizName,
              service: data.serviceTitle,
            }),
            url
          );
          break;
        case PushTypes.USER_SAMEDAY_REMINDER:
          if (data.toglId) {
            let info: ToglInfo = await this.getToglInfoOnce(data.toglId);
            let duration = this.getToglExpirationString(info);
            url = `/app/togls`;
            await this.alerts.openNotificationAlert(
              UIString.format('HEADER_TOGL_SAMEDAY_REMINDER'),
              UIString.format('NOTIF_TOGL_SAMEDAY_REMINDER', {
                bizname: data.bizName,
                service: data.serviceTitle,
                duration,
              }),
              url
            );
          }
          break;
      }
    });
  }

  /**
   * translate a togl pending expiration into a human readable string
   * @param togl
   * @returns
   */
  getToglExpirationString(togl: Togl) {
    const errorString = 'unknown time';
    if (!togl._pendingExpirationSeconds) return errorString;

    const createdMillis = togl.createdTimestamp!.toMillis();
    const pendingExpirationMillis =
      createdMillis + (togl._pendingExpirationSeconds || 0) * 1000;
    let diff = pendingExpirationMillis - Date.now();
    let duration = errorString;
    if (diff > 0) {
      duration = this.millisecondsToHumanReadable(diff);
    }
    return duration;
  }

  /**
   * preload servicess and categories to help with search
   */
  async preloadServicesAndCategories() {
    await this.initCategoryCache();
    this.filelog.log(
      'backend: got all categories: ' + this._allCategoriesCache.length
    );
    await this.initServiceCache();
    this.filelog.log(
      'backend: got all services: ' + this._allServicesCache.length
    );
    const numEnabledServices = this._allServicesCache.filter((s) => s.enabled)
      .length;
    this.filelog.log(`backend: enabled services: ${numEnabledServices}`);
    const disabledServices = this._allServicesCache
      .filter((s) => !s.enabled)
      .map((s) => s.title);
    this.filelog.log(`disabled serivces: ${disabledServices.join(', ')}`);
  }

  initializeUIStrings() {
    this.setDateLabel(ToglState.USER_PENDING, '');
    this.setDateLabel(ToglState.CONFIRMED, '');
    this.setDateLabel(ToglState.COMPLETED, '');
    this.setDateLabel(ToglState.TAKEN, '');
    this.setDateLabel(ToglState.DENIED, '');
    this.setDateLabel(ToglState.EXPIRED, '');
    this.setDateLabel(ToglState.CANCELED_ATFAULT, '');
    this.setDateLabel(ToglState.USER_CANCELED, '');
    this.setDateLabel(ToglState.USER_CANCELED_ATFAULT, '');
    this.setDateLabel(ToglState.NOSHOW_ATFAULT, '');
  }

  isValidCategory(catId: string) {
    if (!this._allCategoriesCache || !this._allServicesCache) return true;

    let cat = this._allCategoriesCache.find((cat) => {
      return cat.id == catId;
    });
    return cat != null;
  }

  /**
   * search businesses by name
   * @param query
   * @param startAfterName
   * @param max
   * @returns
   */
  async searchBusinessName(
    query: string,
    startAfterName?: string,
    max: number = 15
  ): Promise<Business[]> {
    // break up into tokens on spaces
    let tokens = query.toLowerCase().trim().split(' ', 10);

    let qFn: QueryFn = startAfterName
      ? (ref) =>
          ref
            .where('_keywords', 'array-contains-any', tokens)
            .orderBy('name')
            .startAfter(startAfterName)
            .limit(max)
      : (ref) =>
          ref
            .where('_keywords', 'array-contains-any', tokens)
            .orderBy('name')
            .limit(max);

    const snap = await this.collectionRef<Business>(
      CollectionNames.BUSINESSES,
      qFn
    )
      .get()
      .toPromise();

    return snap.docs.map((value) => value.data() as Business);
  }

  /**
   * returns a list of services whose titles match the search query
   * or whose categories' titles match the search query
   * @param searchValue the value to search for
   */
  async searchServices(searchValue: string) {
    // precached data not available? return empty results
    if (!this._allCategoriesCache || !this._allServicesCache) return [];

    searchValue = searchValue.toLowerCase().trim();

    // enabled services and categories only
    const enabledServices = await this.getAllServicesCached();
    const enabledCats = await this.getAllCategoriesCached();

    // find categories that match the search string
    const matchingCatIds: string[] = enabledCats
      .filter((c) => {
        return c.title.toLowerCase().trim().indexOf(searchValue) > -1;
      })
      .map((c) => c.id);
    console.log('backend: ', matchingCatIds);

    // find services that match the search string
    const matchingServiceIds: string[] = enabledServices
      .filter((s) => {
        return s.title.toLowerCase().trim().indexOf(searchValue) > -1;
      })
      .map((s) => s.id);
    console.log('backend: ', matchingServiceIds);

    // we want to return all matching services and all services belonging to matching categories
    const results = enabledServices.filter((service) => {
      // is this service in the matched service list?
      if (matchingServiceIds.includes(service.id)) {
        return true;
      }

      // does this service belong to a matching category?
      const serviceCatIds: Array<string> =
        typeof service.catId === 'string' ? [service.catId] : service.catId;
      let match: boolean = false;
      serviceCatIds.forEach((catId) => {
        if (matchingCatIds.includes(catId)) {
          match = true;
        }
      });
      return match;
    });
    return results;
  }

  // toasts

  async openAlertToast(message: string) {
    /*
    const toast = await this.toastCtrl.create({
      message: "This is a Notification Example",
      duration: 4000,
    });
    toast.present();
    */
    const toast = await this.toastCtrl.create({
      message: message,
      position: 'top',
      duration: 5000,
      // mode:'ios',
      cssClass: 'toast-style',
      buttons: [
        {
          text: UIString.format('BTN_DONE'),
          role: 'cancel',
          handler: () => {
            this.filelog.log('toast: Cancel clicked');
          },
        },
      ],
    });
    await toast.present();
  }

  // alerts

  /**
   * alert the user when there isn't anyone togled on for a service within range
   * @param title
   * @param message
   * @param serviceItem
   */
  async presentNoActiveToglAlert(
    title: string,
    message: string,
    serviceItem: any
  ) {
    this.filelog.log('presentNoActiveToglAlert:' + serviceItem.title);
    const alert = await this.alertCtrl.create({
      cssClass: 'noneactive-alert-style',
      mode: 'md',
      header: title,
      message: message,
      buttons: [
        {
          text: UIString.format('BTN_NO'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
        {
          text: UIString.format('BTN_YES'),
          role: 'alert',
          cssClass: 'secondary',
          handler: () => {
            this.watchServiceAndAlert(serviceItem);
          },
        },
      ],
    });

    await alert.present();
  }

  private async watchServiceAndAlert(serviceItem: any) {
    this.filelog.log('backend: watching service:' + serviceItem.id);

    try {
      await this.watchedService.addWatched(serviceItem.id);
      // await this.setWatchedService(serviceItem.id);
    } catch (e) {
      // some kind of error
      console.warn(`backend: watching service failed`, e);
      return this.alerts.alertOk(UIString.format('NOTIF_WATCH_FAILED'));
    }

    // `You will be notified as soon as a business near you Togls On for ${serviceItem.title} service.`;
    return this.alerts.alertOk(
      UIString.format('NOTIF_WATCHING_SERVICE', {
        service: serviceItem.title,
      }),
      UIString.format('HEADER_ALERT_ADDED')
    );
  }

  /**
   * processes notifications for user watched services
   * @param serviceId the service id being watched
   * @param activeNow the new businesses active for this service
   */
  private async processWatchedServiceNotifications(
    serviceId: string,
    activeNow: BizActiveNowForServiceRTDB
  ) {
    if (Object.keys(activeNow).length === 0) return;
    if (!this.userData) throw new Error('user not logged in');

    // load service, include disabled
    const service: Service = await this.getServiceFromCache(serviceId, true);
    this.filelog.log(
      'backend: processWatchedServiceNotifications, serviceid ' + serviceId
    );
    this.filelog.log(activeNow);

    let serviceName: string = service.title;

    let mapRange = this.prefs.get(UserPrefs.MAP_RANGE);

    for (const bizId in activeNow) {
      const bizItem = activeNow[bizId];
      if (!bizItem.activeNow && !bizItem.sameDayEnabled) continue;

      let bizSnap = await this.docRef<Business>(
        CollectionNames.BUSINESSES,
        bizId
      )
        .get()
        .toPromise();
      let biz = bizSnap.data() as Business;

      let distance = this.location.getUserDistanceTo(bizItem.lat, bizItem.long);
      if (!isNaN(distance) && distance <= mapRange) {
        // do a notification!
        let url = `/app/discover/biz/${bizId}/services/${serviceId}`;
        await this.alerts.openNotificationAlert(
          UIString.format('HEADER_NEARBY_BUSINESS_ALERT'),
          UIString.format('NOTIF_NEARBY_BUSINESS', {
            bizname: biz.name,
            service: serviceName,
          }),
          url
        );
      }
    }
  }

  /**
   * are we on the web, and, is this togl state one we care about notifications for (single states only)?
   * @param state
   * @returns
   */
  private shouldNotifyForToglStateListWeb(stateList: ToglStateList) {
    if (
      !this.pushNotifService.isSupported &&
      stateList.states.length === 1 &&
      BackendService.WEB_NOTIF_INFO.find(
        (v) => v.state === stateList.states[0]
      ) != null
    ) {
      return true;
    }
    return false;
  }

  /**
   * only notifies under the following conditions:
   * - we're on the web, not in a mobile app, and
   * - the state list is a single togl state, and found in `WEB_NOTIF_INFO`
   * @param state
   * @param list
   * @returns
   */
  private async processToglWebNotifications(
    stateList: ToglStateList,
    list: ToglInfo[]
  ) {
    const isWeb = !this.pushNotifService.isSupported;
    const isSingle = stateList.states.length === 1;
    if (!isWeb || !isSingle) {
      return;
    }

    const state = stateList.states[0];
    const notifInfo = BackendService.WEB_NOTIF_INFO.find(
      (v) => v.state === state
    );
    if (!notifInfo) return;
    const prevList = this.toglInfoLists[state] || [];

    // things that only fire during app lifetime have
    // to have a previously-loaded list
    if (!notifInfo.onAppStartup && prevList.length === 0) {
      return;
    }

    // figure out how many new togls there are
    let numNewTogls = 0;
    for (const info of list) {
      if (prevList.find((i) => i.id === info.id) == null) {
        numNewTogls++;
      }
    }

    this.filelog.log(
      `backend: process togl web notifications for ${state}, new togls: ${numNewTogls}`
    );
    if (numNewTogls > 0) {
      let first: ToglInfo = list[0];
      return this.showUserToglNotification(first);
    }
  }

  /**
   * only cares about states in `WEB_NOTIF_INFO`
   * @param toglInfo
   * @returns
   */
  async showUserToglNotification(toglInfo: ToglInfo) {
    let url: string, header: string, msg: string;

    switch (toglInfo.state) {
      case ToglState.CONFIRMED:
        url = `/app/togls`;

        if (!toglInfo.samedaySlot) {
          // regular togl
          header = UIString.format('HEADER_TOGL_CONFIRMED', {
            service: toglInfo.service.title,
          });
        } else {
          // same day togl
          header = UIString.format('HEADER_TOGL_CONFIRMED_SAMEDAY', {
            service: toglInfo.service.title,
            time: DateUtil2.formatTime(toglInfo.samedaySlot.start),
          });
        }

        // permutations of messages
        const isMobile = toglInfo.service.isMobile;
        const isNow = !toglInfo.samedaySlot;
        const hasPostConfirm = await this.toglData.toglHasPostConfirmLink(
          toglInfo
        );
        const linkText = hasPostConfirm ? '_LINK' : '';

        if (!isMobile) {
          msg = isNow
            ? UIString.format(`NOTIF_TOGL_CONFIRMED${linkText}`, {
                bizname: toglInfo.business.name,
                service: toglInfo.service.title,
              })
            : UIString.format(`NOTIF_TOGL_CONFIRMED_SAMEDAY${linkText}`, {
                bizname: toglInfo.business.name,
                service: toglInfo.service.title,
                time: DateUtil2.formatTime(toglInfo.samedaySlot.start),
              });
        } else {
          msg = isNow
            ? UIString.format(`NOTIF_TOGL_CONFIRMED_MOBILE${linkText}`, {
                bizname: toglInfo.business.name,
                service: toglInfo.service.title,
                address: toglInfo.serviceAddress,
              })
            : UIString.format(
                `NOTIF_TOGL_CONFIRMED_SAMEDAY_MOBILE${linkText}`,
                {
                  bizname: toglInfo.business.name,
                  service: toglInfo.service.title,
                  time: DateUtil2.formatTime(toglInfo.samedaySlot.start),
                  address: toglInfo.serviceAddress,
                }
              );
        }

        // if (!toglInfo.service.isMobile) {
        //   // consumer goes to business
        //   msg = !toglInfo.samedaySlot
        //     ? UIString.format('NOTIF_TOGL_CONFIRMED', {
        //         bizname: toglInfo.business.name,
        //         service: toglInfo.service.title,
        //       })
        //     : UIString.format('NOTIF_TOGL_CONFIRMED_SAMEDAY', {
        //         bizname: toglInfo.business.name,
        //         service: toglInfo.service.title,
        //         time: DateUtil2.formatTime(toglInfo.samedaySlot.start),
        //       });
        // } else {
        //   // business goes to consumer
        //   msg = !toglInfo.samedaySlot
        //     ? UIString.format('NOTIF_TOGL_CONFIRMED_MOBILE', {
        //         bizname: toglInfo.business.name,
        //         service: toglInfo.service.title,
        //         address: toglInfo.serviceAddress,
        //       })
        //     : UIString.format('NOTIF_TOGL_CONFIRMED_SAMEDAY_MOBILE', {
        //         bizname: toglInfo.business.name,
        //         service: toglInfo.service.title,
        //         time: DateUtil2.formatTime(toglInfo.samedaySlot.start),
        //         address: toglInfo.serviceAddress,
        //       });
        // }

        return this.alerts.openNotificationAlert(
          header,
          msg,
          url,
          UIString.format('BTN_DETAILS'),
          UIString.format('BTN_CLOSE')
        );
      case ToglState.COMPLETED:
        url = `/app/togls/${toglInfo.id}`;
        header = UIString.format('HEADER_TOGL_COMPLETED', {
          service: toglInfo.service.title,
        });
        msg = UIString.format('NOTIF_TOGL_COMPLETED', {
          bizname: toglInfo.business.name,
          service: toglInfo.service.title,
        });
        return this.alerts.openNotificationAlert(
          header,
          msg,
          url,
          UIString.format('BTN_YES'),
          UIString.format('BTN_NO')
        );
      case ToglState.CANCELED_ATFAULT:
        url = `/app/discover`;
        if (!toglInfo.samedaySlot) {
          // regular togl
          header = UIString.format('HEADER_TOGL_CANCELED', {
            service: toglInfo.service.title,
          });
        } else {
          // same day togl
          header = UIString.format('HEADER_TOGL_CANCELED_SAMEDAY', {
            service: toglInfo.service.title,
            time: DateUtil2.formatTime(toglInfo.samedaySlot.start),
          });
        }

        // has a cancel reason
        msg = UIString.format('NOTIF_TOGL_CANCELED_BUSINESS_REASON', {
          bizname: toglInfo.business.name,
          reason: toglInfo.stateMessage,
        });

        return this.alerts.openNotificationAlert(
          header,
          msg,
          url,
          UIString.format('BTN_YES'),
          UIString.format('BTN_NO')
        );
      case ToglState.DENIED:
        if (!toglInfo.samedaySlot) {
          // regular togl
          header = UIString.format('HEADER_TOGL_DECLINED', {
            service: toglInfo.service.title,
          });
        } else {
          // same day togl
          header = UIString.format('HEADER_TOGL_DECLINED_SAMEDAY', {
            service: toglInfo.service.title,
            time: DateUtil2.formatTime(toglInfo.samedaySlot.start),
          });
        }

        if (toglInfo.stateMessage) {
          // has a decline reason
          msg = UIString.format('NOTIF_TOGL_DECLINED_REASON', {
            bizname: toglInfo.business.name,
            reason: toglInfo.stateMessage,
          });
        } else {
          // no decline reason
          msg = UIString.format('NOTIF_TOGL_DECLINED', {
            bizname: toglInfo.business.name,
          });
        }

        return this.alerts.openNotificationAlert2({
          header,
          message: msg,
          handler: () =>
            this.router.navigate(['/app/map'], {
              state: { serviceId: toglInfo.serviceId },
            }),
          viewText: UIString.format('BTN_YES'),
          cancelText: UIString.format('BTN_NO'),
        });

      // return this.alerts.openNotificationAlert(
      //   header,
      //   msg,
      //   url,
      //   UIString.format('BTN_YES'),
      //   UIString.format('BTN_NO')
      // );
      case ToglState.EXPIRED:
        url = `/app/togls`;
        header = UIString.format('HEADER_TOGL_EXPIRED');
        msg = UIString.format('NOTIF_TOGL_EXPIRED', {
          bizname: toglInfo.business.name,
          service: toglInfo.service.title,
        });
        return this.alerts.openNotificationAlert(
          header,
          msg,
          url,
          UIString.format('BTN_YES'),
          UIString.format('BTN_NO')
        );
      case ToglState.TAKEN:
        // DEPRECATED
        break;
      case ToglState.NOSHOW_ATFAULT:
        return this.alerts.openOkWithHeaderAlert(
          UIString.format('HEADER_TOGL_NOSHOW'),
          UIString.format('NOTIF_TOGL_NOSHOW')
        );
    }
  }

  /**
   * creates an internal, persistent subscription to all togls matching the current user
   * and the passed-in state
   * @param stateList which ToglState to pay attention to
   * @param keepOldSubjects whether to complete the old subjects or not, completion requires everything to re-subscribe
   */
  private createToglSubscription(
    stateList: ToglStateList,
    keepOldSubjects?: boolean
  ): Subscription {
    if (!this.userData)
      throw new Error('createToglSubscription: user not loaded yet');

    this.filelog.log('createToglSubscription:', stateList.name);

    if (!keepOldSubjects) {
      if (this.toglInfoSubjects[stateList.name])
        this.toglInfoSubjects[stateList.name].complete();
      this.toglInfoSubjects[stateList.name] = new Subject<ToglInfo[]>();
    }

    let qFn = this.createToglQueryFunction(
      'userId',
      this.userData.id,
      stateList,
      stateList.name === ToglStateListTypes.ACTIVE ? 50 : 5
    );

    return this.collectionRef<Togl>(CollectionNames.TOGLS, qFn)
      .valueChanges()
      .subscribe(
        (togls) => {
          this.hydrateToglInfoList(togls, async (toglList) => {
            // check for notifications (web, single states only)
            await this.processToglWebNotifications(stateList, toglList);

            let subject = this.toglInfoSubjects[stateList.name];
            this.toglInfoLists[stateList.name] = toglList;
            this.filelog.log(
              `backend: ${toglList.length} togls for statelist type ${stateList.name}`
            );

            // broadcast
            subject.next([...toglList]);
          });
        },
        (err) => console.warn(err)
      );
  }

  /**
   * allows external code to query for togls the same way the backend service does.
   * this is to work around a bug with persistent subscriptions not always returning correct data after being backgrounded on ios
   * @param stateList which ToglState to pay attention to
   * @param keepOldSubjects whether to complete the old subjects or not, completion requires everything to re-subscribe
   */
  createToglQueryExternal(stateList: ToglStateList) {
    if (!this.userData)
      throw new Error('createToglSubscriptionExternal: user not loaded yet');

    this.filelog.log(
      `backend: createToglSubscriptionExternal: ${stateList.name}`
    );

    let qFn = this.createToglQueryFunction(
      'userId',
      this.userData.id,
      stateList,
      stateList.name === ToglStateListTypes.ACTIVE ? 50 : 5
    );

    return this.collectionRef<Togl>(CollectionNames.TOGLS, qFn);
  }

  /**
   * whether the user is logged in and loaded
   */
  public get isUserReady(): boolean {
    return this._isUserReady;
  }

  /**
   * when the onUserReady event fires, a user is logged in and ready
   *
   * fires once and then completes
   */
  public onUserReady() {
    // has user already been loaded once??
    if (this.userData) {
      // yes, add the current value in before the normal stream of changes
      return concat(
        defer(() => of(this.userData)),
        this._onUserReady.asObservable()
      );
    } else {
      // no, just return the normal stream of changes
      return this._onUserReady.asObservable();
    }
  }

  // getFakeLoginUser(max: number = 20): Observable<UserData[]> {
  //   let observable = this.collectionRef<UserData>('users', (ref) =>
  //     ref.limit(max)
  //   ).valueChanges();
  //   return observable;
  // }

  /**
   * confirm and then log out
   *
   * @returns a promise that resolves to true if the user confirmed logging out
   */
  async logoutWithConfirmation() {
    const confirmation = await this.alerts.genericConfirm2(
      UIString.format('NOTIF_CONFIRM_LOGOUT')
    );
    if (confirmation) {
      await this.logout();
    }
    return confirmation;
  }

  /**
   * log the current user out
   */
  async logout() {
    this.filelog.log('backend: logging out');
    // stop listening to push notifications
    await this.pushNotifService.onLogout();
    // log out, auth listener will redirect to login screen
    await this.auth.signOut();
  }

  /**
   * dispose previous subscriptions if any
   */
  private async unsetCurrentUser() {
    if (this.userCompositeSubscription) {
      this.filelog.log('backend: removing current user subscriptions');

      // no longer ready
      this._isUserReady = false;
      this.userData = null;
      this._onUserReady = new Subject<UserData>();

      // all of the user's subscriptions (except togls) have been added to this one, making unsubscribing easy
      this.userCompositeSubscription.unsubscribe();
      this.userCompositeSubscription = null;
      if (this.toglCompositeSubscription) {
        this.toglCompositeSubscription.unsubscribe();
        this.toglCompositeSubscription = null;
      }

      for (const serviceId in this.watchedActiveNowSubscriptions) {
        let sub = this.watchedActiveNowSubscriptions[serviceId];
        if (sub) sub.unsubscribe();
      }
      this.watchedActiveNowSubscriptions = {};

      // TODO: removed watched service subscriptions

      this.toglInfoLists = {};
      for (const state of Object.keys(this.toglInfoSubjects)) {
        if (this.toglInfoSubjects[state]) {
          this.toglInfoSubjects[state].complete();
          this.toglInfoSubjects[state] = null;
        }
        this.toglInfoSubjects = {};
      }
    }
  }

  get currentUser(): UserData {
    return this.userData;
  }

  /**
   * initializes the system when a user logs in
   * @param id the user's id
   */
  private async setCurrentUser(id: string): Promise<void> {
    this.filelog.log('backend: setCurrentUser: ' + id);
    await this.unsetCurrentUser();
    this.filelog.log('backend: setting up new user subscriptions');

    // subscribe internally to record changes associated with the user
    let userDataRef = this.docRef<UserData>(CollectionNames.USERS, id);
    this.userCompositeSubscription = new Subscription();

    // load user data once
    const userdataSnap = await userDataRef.get().toPromise();
    this.userData = userdataSnap.data();

    // subscribe internally to record changes associated with the user
    this.userCompositeSubscription.add(
      userDataRef.valueChanges().subscribe(
        (userData: UserData) => {
          // save updates to user data (also broadcast them?)
          this.userData = userData;
        },
        (err) => console.warn(err)
      )
    );

    // do initial-login stuff
    // subscribe internally to togl collections
    this.createToglSubscriptions();

    // subscribe to user's watched services
    this.subscribeToUserWatchedServices();

    // subscribe to user favorites
    // this.subscribeToUserFavorites();

    // iniitalize preferences
    await this.prefs.initPrefs(id);

    // set the initial theme
    this.setThemeDisplay(null, this.prefs.get(UserPrefs.THEME));

    // listen for theme changes
    this.userCompositeSubscription.add(
      this.prefs.getPrefValueChanges(UserPrefs.THEME).subscribe((theme) => {
        this.filelog.log(`backend: onPrefChange (theme): ${theme}`);
        this.setThemeDisplay(this.currentTheme, theme);
      })
    );

    // check if we have a message to show the user on login
    this.userCompositeSubscription.add(
      this.config
        .getConfigValueChanges(ConfigService.CONSUMER_LOGIN_MESSAGE_ENABLED)
        .subscribe(async (val) => this.onLoginMessageChanged(val))
    );
  }

  /**
   * show login message if any
   * @param val
   */
  private async onLoginMessageChanged(val: Parameter) {
    if (val) {
      this.filelog.log(`login message enabled: ${val.asBoolean()}`);
      if (val.asBoolean()) {
        const messageContent = this.config.getConfigValue(
          ConfigService.CONSUMER_LOGIN_MESSAGE_CONTENT
        );
        if (messageContent) {
          this.filelog.log(
            `login message content: ${messageContent.asString()}`
          );
          if (messageContent.asString()) {
            messageContent._source == 'remote';
            await this.alerts.alertOk(messageContent.asString());
          }
        }
      }
    }
  }

  /**
   * subscribe internally to certain togl collections on first load.
   *
   * subscribes to `ACTIVE` for the togls page.
   *
   * if subscriptions already exist, it destroys and re-creates them
   *
   * subscribes to a few more on the web according to `WEB_NOTIF_INFO` for the processing of notifications
   *
   * @param keepOldSubjects whether to cycle the subjects or not, which would require re-subscribing
   */
  private createToglSubscriptions(keepOldSubjects?: boolean) {
    let list: ToglStateList[] = [];
    list.push(ToglStateListTypes.active());

    // subscribe to more states on the web
    if (!this.pushNotifService.isSupported) {
      for (const info of BackendService.WEB_NOTIF_INFO) {
        list.push(ToglStateListTypes.single(info.state));
      }
    }

    if (this.toglCompositeSubscription) {
      this.toglCompositeSubscription.unsubscribe();
      this.toglCompositeSubscription = null;
    }

    this.toglCompositeSubscription = new Subscription();
    list.forEach((stateList) => {
      this.toglCompositeSubscription.add(
        this.createToglSubscription(stateList, keepOldSubjects)
      );
    });
  }

  /**
   * this could really be optimized
   */
  private subscribeToUserWatchedServices() {
    if (!this.userData) throw new Error('user not loaded yet');

    this.userCompositeSubscription.add(
      this.watchedService
        .getWatchedValueChanges()
        .subscribe((watchedServices) => {
          if (!watchedServices) return;

          this.filelog.log('backend: watched services valuechanges:');
          this.filelog.log(watchedServices);
          let oldWatchedIds = this.watchedServices || [];
          let newWatchedIds = watchedServices || [];
          if (!newWatchedIds) newWatchedIds = [];
          this.watchedServices = watchedServices;

          // was a service unwatched?
          let unwatched = oldWatchedIds.filter(
            (v) => !newWatchedIds.includes(v)
          );
          for (const serviceId of unwatched) {
            if (this.watchedActiveNowSubscriptions[serviceId]) {
              this.filelog.log(
                'backend: unsubbing from activeNow for service ' + serviceId
              );
              this.watchedActiveNowSubscriptions[serviceId].unsubscribe();
              delete this.watchedActiveNowSubscriptions[serviceId];
              delete this.watchedActiveNow[serviceId];
            }
          }

          // were new services watched? (or an intial load)
          let newlyWatched = newWatchedIds.filter(
            (v) => !oldWatchedIds.includes(v)
          );
          for (const serviceId of newlyWatched) {
            if (!this.watchedActiveNowSubscriptions[serviceId]) {
              this.filelog.log(
                'backend: subscribing to activeNow for service ' + serviceId
              );
              // subscribe to changes
              // TODO - this only notices when businesses are added to
              // the array, not when they change stuff like add a slot

              this.watchedActiveNowSubscriptions[
                serviceId
              ] = this.activeNowService
                .getActiveNowValueChangesRTDB(serviceId)
                .subscribe((businessesActiveNow) => {
                  let oldActiveNow = this.watchedActiveNow[serviceId];
                  this.watchedActiveNow[serviceId] = businessesActiveNow;
                  if (!oldActiveNow) {
                    // ignore on initial load
                    return;
                  }

                  let oldKeys = Object.keys(oldActiveNow || {}).sort();
                  let newKeys = Object.keys(businessesActiveNow || {}).sort();
                  let newlyAddedKeys = newKeys.filter(
                    (v) => !oldKeys.includes(v)
                  );
                  const newlyAdded: BizActiveNowForServiceRTDB = {};
                  for (const bizId of newlyAddedKeys) {
                    newlyAdded[bizId] = {
                      ...businessesActiveNow[bizId],
                    };
                  }
                  // only process the notifications if we're on web
                  if (!this.pushNotifService.isSupported) {
                    this.processWatchedServiceNotifications(
                      serviceId,
                      newlyAdded
                    );
                  }
                });
            }
          }
        })
    );
  }

  private setThemeDisplay(oldTheme: string, newTheme: string) {
    if (oldTheme)
      this.renderer.removeClass(document.documentElement, `theme-${oldTheme}`);

    this.currentTheme = newTheme;
    this.renderer.addClass(document.documentElement, `theme-${newTheme}`);
  }

  getUserDisplayname(
    u: UserData = null,
    useNameAndEmail: boolean = false
  ): string {
    if (!u) u = this.currentUser;

    if (!u) return '';

    if (u.displayName) {
      return u.displayName + (useNameAndEmail ? ` (${u.email})` : '');
    }
    return u.email;
  }

  getTheme(): string {
    return this.currentTheme;
  }

  async setTheme(newTheme: string = 'default') {
    await this.prefs.set(UserPrefs.THEME, newTheme);
  }

  getMaxMapRange(): number {
    return this._maxMapRange;
  }

  // getMaxMapRangeLabel(): string {
  //   let val: number = BackendService.MAX_MAP_RANGE;
  //   return '' + val + ' mile' + (val == 1 ? '' : 's');
  // }

  getMinMapRange(): number {
    return this._minMapRange;
  }

  // getMinMapRangeLabel(): string {
  //   let val: number = BackendService.MIN_MAP_RANGE;
  //   return '' + val + ' mile' + (val == 1 ? '' : 's');
  // }

  /**
   * calculate how many of a list of active businesses are nearby
   * @param mapRange
   * @param activeNow
   * @returns
   */
  computeActiveNearbyRTDB(
    mapRange: number,
    activeNow: BizActiveNowForServiceRTDB
  ): ActiveNearby {
    if (!this.userData) throw new Error('backend not ready');

    // calculate distance, figure out how many are in range
    let withinRange = 0;
    let extended = 0;
    let maxMapRange = this._maxMapRange;

    if (activeNow != null) {
      for (const bizId in activeNow) {
        let bizActiveNow = activeNow[bizId];
        let distance = this.location.getUserDistanceTo(
          bizActiveNow.lat,
          bizActiveNow.long
        );
        if (!isNaN(distance) && distance <= mapRange) {
          withinRange++;
        }
        if (!isNaN(distance) && distance <= maxMapRange) {
          extended++;
        }
      }
    }

    const ret: ActiveNearby = {
      range: mapRange,
      withinRange: withinRange,
      extended: extended,
    };

    return ret;
  }

  /**
   * call a firebase callable function
   * @param name
   * @param options
   * @returns
   */
  callCallableFunction(name: string, options: any): Promise<any> {
    this.filelog.log(
      'backend: calling firebase function: ' +
        name +
        ', with params: ' +
        JSON.stringify(options)
    );
    return new Promise((resolve, reject) => {
      this.firebaseCallableFunction(name, options).subscribe(
        (response) => {
          this.filelog.log(
            'backend: ' + name + ' returned: ' + JSON.stringify(response)
          );
          resolve(response);
        },
        (err) => reject(err)
      );
    });
  }

  /**
   * get an observable-based version of a firebase callable function
   * @param name
   * @param options
   * @returns
   */
  firebaseCallableFunction(name: string, options: any) {
    let callable = this.fns.httpsCallable(name);
    return callable(options);
  }

  /**
   * get service objects for a list of ids, sorted by title. includes disabled
   * @param list
   * @returns
   */
  async getServiceList(list: string[]): Promise<Service[]> {
    const services = await Promise.all(
      list.map((serviceId) => this.getServiceFromCache(serviceId, true))
    );

    services.sort((a, b) => {
      return a.title < b.title ? -1 : 1;
    });

    return services;
  }

  /**
   * get services and same-day slot information for a business
   * @param businessId
   * @returns
   */
  async getBusinessServicesAndSlots(businessId: string) {
    interface TempResult extends ServiceAndSlotInfo {
      slots?: BizSamedaySlot[];
    }

    if (!this._isUserReady) throw new Error('backend not ready!');

    let tmpResults: TempResult[] = [];

    // grab the bizServices for this business
    const bizServicesSnap = await this.docRef<BizServices>(
      CollectionNames.BIZ_SERVICES,
      businessId
    )
      .get()
      .toPromise();

    // bug fix - return empty array if no biz services document for this business
    if (!bizServicesSnap.exists) {
      return [];
    }

    // bizServices controls activeNow (green), and sameday slots control sameday (blue)
    let bizServices: BizServices = bizServicesSnap.data() as BizServices;
    for (const serviceId in bizServices) {
      let bizService: BizService = bizServices[serviceId];

      // add some data from the service object. include disabled so we don't break
      const s: Service = await this.getServiceFromCache(
        bizService.serviceId,
        true
      );
      let curResult: TempResult = {
        ...bizService,
        title: s.title,
        icon: s.icon,
        isMobile: s.isMobile,
      };
      tmpResults.push(curResult);

      // check for valid, enabled appointment slots
      // valid appointments must start at least 15 min in future
      let startAfter: Date = DateUtil2.soonestValidStarttime();

      const slotsSnap = await this.collectionRef<BizSamedaySlot>(
        CollectionNames.BIZ_SAMEDAYSLOTS,
        (ref) =>
          ref
            .where('businessId', '==', businessId)
            .where('serviceId', '==', serviceId)
            .where('enabled', '==', true)
            .where('start', '>=', startAfter)
            .orderBy('start')
      )
        .get()
        .toPromise();
      if (slotsSnap.size === 0) {
        // no slots, disable same day
        curResult.sameDayEnabled = false;
      } else {
        // add all the slots to the temp object
        curResult.slots = [];
        for (const doc of slotsSnap.docs) {
          curResult.slots.push({ ...doc.data() });
        }
      }
    }

    tmpResults.sort((a, b) => (a.title < b.title ? -1 : 1));

    // now, expand out the temp results to create actual results, creating one entry for each slot
    let results: ServiceAndSlotInfo[] = [];
    for (let tmpRes of tmpResults) {
      const baseRes: ServiceAndSlotInfo = {
        serviceId: tmpRes.serviceId,
        activeNow: tmpRes.activeNow,
        lastTogledOn: tmpRes.lastTogledOn,
        sameDayEnabled: tmpRes.sameDayEnabled,
        toglData: tmpRes.toglData,
        title: tmpRes.title,
        icon: tmpRes.icon,
        isMobile: tmpRes.isMobile,
      };

      // add active now entries if applicable
      if (tmpRes.activeNow) {
        const res: ServiceAndSlotInfo = {
          ...baseRes,
          radioValue: tmpRes.serviceId,
        };
        results.push(res);
      }

      // add timeslot entries if applicable
      if (tmpRes.slots) {
        for (const slot of tmpRes.slots) {
          const res: ServiceAndSlotInfo = {
            ...baseRes,
            slot: { ...slot },
            radioValue: tmpRes.serviceId + '|' + slot.id,
          };
          results.push(res);
        }
      }
    }

    return results;
  }

  /**
   * whether a user can create a togl for a particular service
   * @param serviceId
   * @returns
   */
  async canCreateTogl(
    serviceId: string
  ): Promise<{ success: boolean; existingTogl?: Togl }> {
    if (!this._isUserReady) throw new Error('backend not ready!');

    // the user can only have one togl in an active state at a time per service.
    // active state == pending or confirmed

    this.filelog.log(
      `backend: canCreateTogl, querying user ${
        this.currentUser.id
      }, service ${serviceId}, states ${ToglStateListTypes.active().states.join(
        ','
      )}`
    );

    try {
      this.filelog.log('backend: canCreateTogl, executing query');
      const qs = await this.collectionRef<Togl>(CollectionNames.TOGLS, (ref) =>
        ref
          .where('userId', '==', this.currentUser.id)
          .where('serviceId', '==', serviceId)
          .where('state', 'in', ToglStateListTypes.active().states)
      )
        .get()
        .toPromise();

      this.filelog.log(
        'backend: canCreateTogl ' + serviceId + ', num results: ' + qs.size
      );

      // no documents
      if (qs.size === 0) {
        return { success: true };
      }

      // found one or more documents
      return {
        success: false,
        existingTogl: qs.docs[0].data() as Togl,
      };
    } catch (e) {
      this.filelog.log('backend: canCreateTogl error' + JSON.stringify(e));

      return { success: false };
    }
  }

  /**
   * create a togl object in the backend
   * @param info info about service and slot
   * @param businessId id of business to request from
   * @param serviceAddress optional service address
   * @returns a promise that resolves to the newly created togl, or null if error
   */
  async createTogl(
    info: ServiceAndSlotInfo,
    businessId: string,
    serviceAddress?: string,
    serviceAddressPosition?: google.maps.LatLng
  ) {
    if (!this._isUserReady) throw new Error('backend not ready!');

    let userId = this.userData.id;

    // load biz services data
    const snap = await this.docRef<BizServices>(
      CollectionNames.BIZ_SERVICES,
      businessId
    )
      .get()
      .toPromise();
    const bizServices: BizServices = snap.data() as BizServices;
    const bizService: BizService = bizServices
      ? (bizServices[info.serviceId] as BizService)
      : null;

    // biz not togled on!!
    if (!bizService) return null;

    // TODO: check if business has togled off in the meantime

    const expirationSeconds = info.slot
      ? this.config
          .getConfigValue(ConfigService.PENDING_BLUE_TOGL_EXPIRATION_TIME)
          .asNumber()
      : this.config
          .getConfigValue(ConfigService.PENDING_GREEN_TOGL_EXPIRATION_TIME)
          .asNumber();

    // start creating togl data for saving to db
    const id = this.firestore.createId();
    const togl: Togl = {
      createdTimestamp: firebase.firestore.FieldValue.serverTimestamp() as any,
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp() as any,
      id,
      userId,
      serviceId: info.serviceId,
      businessId,
      state: ToglState.USER_PENDING,
      _pendingExpirationSeconds: expirationSeconds,
    };
    if (serviceAddress) {
      togl.serviceAddress = serviceAddress;
    }
    if (serviceAddressPosition) {
      togl.serviceAddressLat = serviceAddressPosition.lat();
      togl.serviceAddressLong = serviceAddressPosition.lng();
    }
    if (info.slot) {
      togl.samedaySlotId = info.slot.id;
    }

    // use some data from the biz service object in the new togl object
    // if (bizService.noshow != null) {
    //   togl.noShowFee = bizService.noshow;
    // }

    // set lastTogledOn to now if we don't have last togled on data for the business
    togl.businessTogledOnTimestamp = bizService.lastTogledOn
      ? bizService.lastTogledOn
      : (firebase.firestore.FieldValue.serverTimestamp() as any);

    // copy toglData to togl
    if (info.toglData && info.toglData.length > 0) {
      togl.toglData = [...info.toglData];
    }

    // save togl to database
    await this.collectionRef<Togl>(CollectionNames.TOGLS).doc(id).set(togl);

    // fetch the new data
    const toglSnap = await this.docRef<Togl>(CollectionNames.TOGLS, id)
      .get()
      .toPromise();
    const postSave: Togl = toglSnap.data();

    // return togl object
    return postSave;
  }

  /**
   * throws an error with a message on failure
   * @param toglId
   * @param rating
   */
  async setToglBizRating(toglId: string, rating: number) {
    const ref = this.docRef<Togl>(CollectionNames.TOGLS, toglId);
    const snap = await ref.get().toPromise();
    if (!snap.exists) {
      throw new Error("Togl doesn't exist.");
    }
    let data: Togl = snap.data() as Togl;
    if (data.bizReceivedRating != null) {
      throw new Error('Business has already been rated.');
    }
    if (rating < 1 || rating > 5) {
      throw new Error(`Invalid number of stars ${rating}.`);
    }

    const updateData: any = {
      bizReceivedRating: rating,
    };
    await ref.update(updateData);
  }

  getCachedToglsForUser(state: string) {
    if (this.toglInfoLists[state]) return [...this.toglInfoLists[state]];
    return [];
  }

  getNumToglsForUser(state: string) {
    if (this.toglInfoLists[state]) return this.toglInfoLists[state].length;
    return 0;
  }

  getToglsForUser(stateListType: string): Observable<ToglInfo[]> {
    if (!this._isUserReady) throw new Error('backend not ready!');
    if (!this.toglInfoSubjects[stateListType]) {
      throw new Error('backend: unknown state list type ' + stateListType);
    }

    // have the togls already been loaded once?
    if (this.toglInfoLists[stateListType]) {
      this.filelog.log(
        'backend: get togls for state: ' +
          stateListType +
          ', togls already loaded once'
      );
      // yes, add the current value in before the normal stream of changes
      return concat(
        defer(() => of(this.toglInfoLists[stateListType])),
        this.toglInfoSubjects[stateListType].asObservable()
      );
    } else {
      this.filelog.log(
        'backend: get togls for state: ' +
          stateListType +
          ', initial load still in progress'
      );
      // no, just return the normal stream of changes for subscription
      return this.toglInfoSubjects[stateListType].asObservable();
    }
  }

  /**
   * load the extra things for one togl
   * @param togl
   * @returns
   */
  hydrateToglInfo(togl: Togl) {
    // start with a framework, fill in the async stuff when it loads
    let toglInfo: ToglInfo = {
      ...togl,
      service: null,
      user: null,
      business: null,
      samedaySlot: null,
      businessTogledOnDate: this.timestampToDate(
        togl.businessTogledOnTimestamp
      ),
      createdDate: this.timestampToDate(togl.createdTimestamp),
      confirmedDate: this.timestampToDate(togl.confirmedTimestamp),
      completedDate: this.timestampToDate(togl.completedTimestamp),
      stateMessage: togl.stateMessage,
    };

    toglInfo.user = { ...this.userData };

    let hasSlot: boolean = togl.samedaySlotId != null;

    // include disabled services so we don't break history
    return forkJoin({
      service: defer(() => this.getServiceFromCache(togl.serviceId, true)),
      business: this.docRef<Business>(
        CollectionNames.BUSINESSES,
        togl.businessId
      )
        .get()
        .pipe(map((snap) => snap.data() as Business)),
      slot: hasSlot
        ? this.docRef<BizSamedaySlot>(
            CollectionNames.BIZ_SAMEDAYSLOTS,
            togl.samedaySlotId
          )
            .get()
            .pipe(map((snap) => snap.data() as BizSamedaySlot))
        : of(null),
    }).pipe(
      map((res) => {
        toglInfo.service = { ...res.service };
        toglInfo.business = res.business;
        if (res.slot) {
          toglInfo.samedaySlot = res.slot;
        }
        return toglInfo;
      })
    );
  }

  /**
   * load a togl and populate its dependent objects
   * @param id
   * @returns
   */
  async getToglInfoOnce(id: string): Promise<ToglInfo> {
    const snap = await this.docRef<Togl>(CollectionNames.TOGLS, id)
      .get()
      .toPromise();

    if (!snap.exists) {
      throw new Error(`Togl ${id} doesn't exist`);
    } else {
      const info = await this.hydrateToglInfo(snap.data() as Togl).toPromise();
      return info;
    }
  }

  /**
   * subscribe to the value changes of a hydrated toglinfo
   * @param id
   * @returns
   */
  getToglInfoValueChanges(id: string): Observable<ToglInfo> {
    return this.docRef<Togl>(CollectionNames.TOGLS, id)
      .valueChanges()
      .pipe(mergeMap((togl) => this.hydrateToglInfo(togl as Togl)));
  }

  async getBizMainPhotoUrl(biz: Business, fallbackUrl: string) {
    if (!biz.uploadedMainImagePath) return fallbackUrl;
    if (biz.uploadedMainImagePath.startsWith('bizprofile')) {
      // old style of header image
      return this.getFirebaseStorageFileDownloadURL(
        biz.uploadedMainImagePath,
        fallbackUrl
      );
    } else {
      return this.getFirebaseStorageFileDownloadURL(
        `bizphotos/${biz.businessID}/${biz.uploadedMainImagePath}`,
        fallbackUrl
      );
    }
  }

  /**
   * translate a firebase storage file to a url, or a fallback url if it doesn't exist
   *
   * internally catches errors and sends back the fallback url if needed
   *
   * @param storageFilePath the firebase storage path to the file
   * @param fallbackUrl the fallback url to use in case of failure
   * @returns promise that resolves to a url
   */
  async getFirebaseStorageFileDownloadURL(
    storageFilePath: string,
    fallbackUrl: string
  ): Promise<string> {
    if (!this.storage) {
      return fallbackUrl;
    }
    // use uploaded image if provided, or use fallback image
    // get download url for uploaded image
    try {
      const url = await this.afStorage
        .ref(storageFilePath)
        .getDownloadURL()
        .toPromise();
      return url;
    } catch (e) {
      // use fallback, there was no file in firebase storage for this path
      return fallbackUrl;
    }
  }

  /**
   * returns a sequential or random placeholder image
   * @param index the desired index
   * @returns
   */
  getPlaceholderImageMapThumbnail(index: number = -1): string {
    // brighten the placeholder image? filter: contrast(.6) brightness(1.2);
    let max: number = 5;
    let i = Math.floor(Math.random() * max) + 1;
    if (index !== -1) i = (index % max) + 1;
    return `assets/business-map/placeholder-thumb-0${i}.png`;
  }

  //////////////////////////
  // TOGL STATE FUNCTIONS //
  //////////////////////////

  /**
   * user action: cancel an unconfirmed togl. no reason required
   * @param t
   * @returns
   */
  async setToglUserCanceled(t: ToglInfo) {
    const updateData: any = {
      state: ToglState.USER_CANCELED,
      completedTimestamp: firebase.firestore.FieldValue.serverTimestamp() as any,
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp() as any,
    };
    await this.docRef<Togl>(CollectionNames.TOGLS, t.id).update(updateData);
  }

  /**
   * user action: cancel a togl post-confirmation with the user at fault with a reason
   *
   * @param t
   * @param stateMessage the cancelation reason
   * @returns
   */
  async setToglUserCanceledAtFault(t: ToglInfo, stateMessage: string) {
    this.filelog.log(
      'setToglUserCanceledAtFault: stateMessage:' + stateMessage
    );
    const updateData: any = {
      state: ToglState.USER_CANCELED_ATFAULT,
      completedTimestamp: firebase.firestore.FieldValue.serverTimestamp() as any,
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp() as any,
      stateMessage,
    };
    await this.docRef<Togl>(CollectionNames.TOGLS, t.id).update(updateData);
  }
}
