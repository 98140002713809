/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  BizServiceInfo,
  DateUtil,
  Service,
  ToglDataItem,
  ToglDataType2,
} from '@mojoapps1/mojoapps1common';

import {
  BackendService,
  ServiceAndSlotInfo,
} from 'src/app/angular-services/backend.service';

import firebase from 'firebase/app';
import 'firebase/firestore';

import { format } from 'date-fns';
import { UIString } from 'src/app/lang/UIString';
import { IonRadio, IonToggle } from '@ionic/angular';
import { ToglDataService } from 'src/app/angular-services/togldata.service';
import { IconInfo, IconsService } from 'src/app/angular-services/icons.service';
import { DateUtil2 } from 'src/app/util/DateUtil2';
import { FileLog } from 'src/app/angular-services/FileLog';

@Component({
  selector: 'app-service-toggle',
  templateUrl: './service-toggle.component.html',
  styleUrls: ['./service-toggle.component.scss'],
})
export class ServiceToggleComponent implements OnInit {
  @ViewChild('toggle') toggle: IonToggle;

  /**
   * the service/slot object we're rendering
   */
  @Input() set info(val: ServiceAndSlotInfo) {
    this._info = val;
    if (val.toglData) {
      // filter togl data for display
      this.toglData.filterToglData2(val.toglData, false).then((data) => {
        this.toglDataDisplay = data;
      });
    } else {
      this.toglDataDisplay = null;
    }
    const theme = this.backendService.getTheme();
    if (theme == 'option3') {
      this.iconColor = 'icon';
    } else {
      this.iconColor = this.info.slot ? 'primary' : 'tertiary';
    }
    this.header = this.info.title; // this.info.slot ? this.info.title : this.info.title + ' (Now)';
  }
  get info() {
    return this._info;
  }
  private _info: ServiceAndSlotInfo;
  /**
   * togl data, sanitized for display
   */
  toglDataDisplay: ToglDataItem[];
  /**
   * the color of the icon, an ionic color (i.e. "primary", "secondary", etc). defaults to green or blue for now vs sameday
   */
  @Input() iconColor: string;

  /**
   * emits when the toggle switch is clicked on (not off)
   */
  @Output() onToggledOn: EventEmitter<
    ServiceToggleComponent
  > = new EventEmitter<ServiceToggleComponent>();
  /**
   * path to an svg icon
   */
  // iconSvgPath: string;
  /**
   * fallback ion-icon name if no svg given
   */
  // iconFallback: string;

  header: string;
  slotTime: string;

  labelCost: string;
  labelQuantity: string;
  labelDuration: string;
  labelNoshow: string;

  // icon: IconInfo;

  constructor(
    private backendService: BackendService,
    private toglData: ToglDataService,
    private icons: IconsService,
    private filelog: FileLog
  ) {}

  async ngOnInit() {
    this.labelCost = UIString.format('LABEL_COST');
    this.labelQuantity = UIString.format('LABEL_QUANTITY');
    this.labelDuration = UIString.format('LABEL_DURATION');
    this.labelNoshow = UIString.format('LABEL_NOSHOW');

    // default to green or blue depending on slot info
    if (!this.iconColor) this.iconColor = 'primary';

    // this.icon = await this.icons.getIconInfo(this.info.icon);

    // this.header = this.info.title;
    // if (this.info.isMobile) {
    //   this.header += ' (mobile service)';
    // }
    if (this.info.slot) {
      this.slotTime = this.generateTimeslotLabel(this.info.slot);
    }
  }

  public get value(): string {
    return this.info.radioValue;
  }

  public get checked(): boolean {
    return this.toggle.checked;
  }

  public clear() {
    this.toggle.checked = false;
  }

  /**
   * called when the toggle switch is flipped
   * @param event
   */
  onToggle(event) {
    let checked: boolean = event.detail.checked;
    this.filelog.log(
      `service-toggle: toggled: value=${this.value}, checked=${checked}`
    );
    if (checked) {
      this.onToggledOn.emit(this);
    }
  }

  /**
   * when the overlay button is clicked, broadcast this
   * so parent can set radio group's value to our value.
   */
  onOverlayClick() {
    this.filelog.log('service-radio: click');
    this.onToggledOn.emit(this);
  }

  generateTimeslotLabel(t: any): string {
    return DateUtil2.formatTime(t.start) + ' - ' + DateUtil2.formatTime(t.end);
  }
}
