/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class FileLog {
  _filename: string;
  _logKey: string;
  _ready: Promise<void>;
  _filesystemSupported: boolean;
  _start: Date;
  _logs: string[];

  private initLogging() {
    this._start = new Date();
    this._logKey = this._start
      .toISOString()
      .replace(/:/g, '-')
      .replace(/\./g, '-');
    this._logs = [];
    this.log(`Log start: ${this._start.toISOString()}`);
  }

  private getRef() {
    return this.rtdb.database.ref(`_logs/${this._logKey}`);
  }

  constructor(private platform: Platform, private rtdb: AngularFireDatabase) {
    // this._filename = this._start.toISOString() + '.txt';

    this.platform.ready().then(() => {
      this._filesystemSupported = this.platform.is('hybrid');
      if (this._filesystemSupported) {
        this.initLogging();
      } else {
        console.log('FileLog: not supported on web');
      }
    });

    // this.initLogging();

    // this._logs = [];
    // this._logs.push(`Log start: ${this._start.toISOString()}`);
    // this._logKey = new Date()
    //   .toISOString()
    //   .replace(/:/g, '-')
    //   .replace(/\./g, '-');

    // this.rtdb.database
    //   .ref(`_logs/${this._logKey}`)
    //   .push(this._logs[this._logs.length - 1]);

    // this.platform.ready().then(() => {
    //   this._filesystemSupported = this.platform.is('hybrid');
    //   if (this._filesystemSupported) {
    //     this._ready = new Promise<void>((resolve) => {
    //       Filesystem.writeFile({
    //         path: this._filename,
    //         data: `Log start: ${this._start.toISOString()}`,
    //         directory: Directory.Documents,
    //         encoding: Encoding.UTF8,
    //       }).then(() => resolve());
    //     });
    //   } else {
    //     console.log('FileLog: not supported on web');
    //   }
    // });
  }

  get ready() {
    return this._ready;
  }

  async log(...args) {
    console.log(...args);
    if (this._filesystemSupported) {
      let msg = args.join(' ');
      this._logs.push(msg);
      await this.getRef().push(this._logs[this._logs.length - 1]);
    }

    // if (this._filesystemSupported) {
    //   let msg = args.join(' ');
    //   this._logs.push(msg);

    //   console.log(...args);

    //   if (this._logs.length >= 256) {
    //     this._logs.push('---- log batch end ----');
    //     await Filesystem.appendFile({
    //       path: this._filename,
    //       data: this._logs.join('\n'),
    //       directory: Directory.Documents,
    //       encoding: Encoding.UTF8,
    //     });
    //     this._logs = [];
    //   }
    // } else {
    //   // log to console
    //   console.log(...args);
    // }
  }

  async submit() {
    if (!this._filesystemSupported) {
      throw new Error('not supported on web');
    }

    await this.log('---- log end ----');

    this.initLogging();

    // if (this._logs.length > 0) {
    //   this._logs.push('---- log end ----');
    //   this.rtdb.database
    //     .ref(`_logs/${this._logKey}`)
    //     .push(this._logs[this._logs.length - 1]);

    // await Filesystem.appendFile({
    //   path: this._filename,
    //   data: this._logs.join('\n'),
    //   directory: Directory.Documents,
    //   encoding: Encoding.UTF8,
    // });

    // await this.rtdb.database.ref('_logs').push({
    //   log: this._logs.join('\n'),
    // });

    //   this._logs = [];
    // }

    // const result = await Filesystem.readFile({
    //   path: this._filename,
    //   directory: Directory.Documents,
    //   encoding: Encoding.UTF8,
    // });

    // const toWrite: string[] = result.data.split('\n');

    // await this.rtdb.database.ref('_logs').push({
    //   log: toWrite,
    // });
  }

  async fetchFromDatabase() {
    const snap = await this.rtdb.database.ref('_logs').once('value');
    return snap.val();
  }

  /**
   * CLEARS ALL LOGS FROM THE DATABASE, use with caution
   */
  async clearAllLogs() {
    const logs = await this.fetchFromDatabase();
    // console.log(Object.keys(logs));
    for (const k in logs) {
      console.log(`removing log ${k}`);
      await this.rtdb.database.ref(`_logs/${k}`).remove();
    }
  }
}
