/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { FileLog } from 'src/app/angular-services/FileLog';
import { UIString } from 'src/app/lang/UIString';

export interface PopoverListItem {
  label: string;
  click: () => void;
}

@Component({
  selector: 'list-popover',
  templateUrl: 'list-popover.component.html',
  styleUrls: ['list-popover.component.scss'],
})
export class ListPopover implements OnInit {
  @Input() list: PopoverListItem[];
  @Input() cancelText: string = UIString.format('BTN_CANCEL');

  constructor(
    public popoverController: PopoverController,
    private filelog: FileLog
  ) {}

  ngOnInit(): void {
    this.filelog.log(this.list);
  }

  async onClick(item: PopoverListItem) {
    if (item.click) {
      await item.click();
    }
    this.popoverController.dismiss();
  }
}
