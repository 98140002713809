/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Category } from '@mojoapps1/mojoapps1common';
import { IconInfo, IconsService } from 'src/app/angular-services/icons.service';

@Component({
  selector: 'app-catsvc-icon',
  templateUrl: './catsvc-icon.component.html',
  styleUrls: ['./catsvc-icon.component.scss'],
})
export class CatSvcIconComponent implements OnInit {
  @Input() color: string;
  /**
   * either an SVG filename or an ionic icon name
   */
  @Input() set icon(v: string) {
    this._icon = v;

    // console.log(`catsvc-icon: set icon ${v}`);

    // load from service
    this.icons.getIconInfo(this._icon).then((iconInfo) => {
      this.iconInfo = iconInfo;

      // console.log(`icon-component: ${JSON.stringify(iconInfo)}`);
    });
  }
  get icon() {
    return this._icon;
  }
  private _icon: string;

  iconInfo: IconInfo;

  constructor(private icons: IconsService) {}

  ngOnInit() {}
}
