/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireFunctions } from '@angular/fire/functions';
import {
  FavoritedByRTDB,
  RTDBPaths,
  UserFavoriteRTDB,
  UserPrefs,
} from '@mojoapps1/mojoapps1common';
import { concat, defer, of, pipe, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import firebase from 'firebase/app';
import { FileLog } from './FileLog';
import { UserPref } from '@mojoapps1/mojoapps1common/dist/model/userpref.model';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class PrefsRTDBService {
  private _userId: string;
  private _prefsSubject: Subject<UserPref>;
  private _prefs: UserPref;
  private _dbSubscription: Subscription;
  private _onPrefsChange: {
    [key: string]: Subject<any>;
  };
  private _defaultMapRange;

  constructor(
    private rtdb: AngularFireDatabase,
    private auth: AngularFireAuth,
    private filelog: FileLog,
    private config: ConfigService
  ) {
    this._prefsSubject = new Subject<UserPref>();
    this._onPrefsChange = {};
    this.auth.authState.subscribe((u) => this.onAuthEvent(u));
    this.config
      .getConfigValueChanges(ConfigService.DEFAULT_MAP_RANGE)
      .subscribe((param) => {
        this._defaultMapRange = param.asNumber();
      });
  }

  private onAuthEvent(u: firebase.User) {
    this.filelog.log('prefsrtdb: auth event, login=' + !!u);
    if (u) {
      // do nothing on login
    } else {
      // auto-destroy when user logs out
      if (this._prefsSubject) {
        this._prefsSubject.complete();
        this._prefsSubject = new Subject<UserPref>();
      }
      if (this._onPrefsChange) {
        for (const k in this._onPrefsChange) {
          if (this._onPrefsChange[k]) {
            this._onPrefsChange[k].complete();
            this._onPrefsChange[k] = null;
          }
        }
      }
      this._onPrefsChange = {};
      if (this._dbSubscription) {
        this._dbSubscription.unsubscribe();
        this._dbSubscription = null;
      }
      this._userId = null;
      this._prefs = null;
    }
  }

  private getDefaultPrefs(): UserPref {
    return {
      displayMode: 'grid',
      theme: 'default',
      mapRange: this._defaultMapRange ? this._defaultMapRange : 15,
    };
  }

  /**
   * called by backend service on user login, with a delay so remote config has a chance to load first
   * @returns
   */
  async initPrefs(userId: string) {
    if (userId == null) {
      this.filelog.log('initPrefs: no user!');
      return;
    }
    this._userId = userId;
    this.filelog.log(`initPrefs: ${this._userId}`);

    UserPrefs.keys().forEach((key) => {
      this._onPrefsChange[key] = new Subject<UserPref>();
    });

    let initialized: boolean = false;

    return new Promise<void>((resolve, reject) => {
      this._dbSubscription = this.rtdb
        .object<UserPref>(this.prefsPath())
        .valueChanges()
        .subscribe(async (prefs) => {
          this.filelog.log('valuechanges event, prefs exists: ' + !!prefs);

          if (!prefs) {
            this._prefs = this.getDefaultPrefs();
            this.filelog.log(
              'setting default prefs: ' + JSON.stringify(this._prefs)
            );

            await this.rtdb.database.ref(this.prefsPath()).set(this._prefs);

            this.filelog.log('default prefs saved to db');
            if (!initialized) {
              initialized = true;
              resolve();
            }
          } else {
            let oldPrefs: UserPref = this._prefs;
            this._prefs = { ...prefs };

            this.filelog.log(
              'prefs valuechange: ' +
                JSON.stringify(this._prefs) +
                ', old prefs exist: ' +
                !!oldPrefs
            );
            if (!initialized) {
              initialized = true;
              resolve();
            }

            // initial load? don't notify anyone
            // if (!oldPrefs) return;

            // notify observers if anything changed
            UserPrefs.keys().forEach((key) => {
              if (!oldPrefs || oldPrefs[key] != this._prefs[key]) {
                this.filelog.log(
                  'dispatching onPrefsChange(' + key + '): ' + this._prefs[key]
                );
                this._onPrefsChange[key].next(this._prefs[key]);
              }
            });
          }

          this._prefsSubject.next({ ...this._prefs });
        });
    });
  }

  /**
   * get changes for one pref
   * @param key
   */
  getPrefValueChanges(key: string) {
    if (!this._userId) throw new Error('not initialized!');

    // have the prefs already been loaded once?
    if (this._prefs) {
      // yes, add the current value in before the normal stream of changes
      return concat(
        defer(() => of(this._prefs[key])),
        this._onPrefsChange[key].asObservable()
      );
    } else {
      // no, just return the normal stream of changes for subscription
      return this._onPrefsChange[key].asObservable();
    }
  }

  /**
   * get changes of prefs
   */
  getPrefsValueChanges() {
    if (!this._userId) throw new Error('not initialized!');

    // have the prefs already been loaded once?
    if (this._prefs) {
      // yes, add the current value in before the normal stream of changes
      return concat(
        defer(() => of({ ...this._prefs })),
        this._prefsSubject.asObservable()
      );
    } else {
      // no, just return the normal stream of changes for subscription
      return this._prefsSubject.asObservable();
    }
  }

  private prefsPath() {
    return `/prefs2/${this._userId}`;
  }

  /**
   * get a prefs val synchronously. if not loaded yet, will return null
   * @param key
   * @returns
   */
  get(key: string) {
    if (!this._prefs) {
      return null;
    }
    return this._prefs[key];
  }

  /**
   * set a prefs val in the rtdb
   * @param key
   * @param val
   * @returns
   */
  async set(key: string, val: any) {
    return this.rtdb.database.ref(this.prefsPath()).update({ [key]: val });
  }
}
