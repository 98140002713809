/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Category } from '@mojoapps1/mojoapps1common';
import { IconInfo, IconsService } from 'src/app/angular-services/icons.service';

@Component({
  selector: 'app-category-griditem',
  templateUrl: './category-griditem.component.html',
  styleUrls: ['./category-griditem.component.scss'],
})
export class CategoryGridItemComponent implements OnInit {
  /**
   * the category object we're rendering
   */
  @Input() item: Category;
  /**
   * the color of the icon, an ionic color (i.e. "primary", "secondary", etc). defaults to primary
   */
  @Input() iconColor: string;
  /**
   * path to an svg icon
   */
  // iconSvgPath: string;
  /**
   * fallback ion-icon name if no svg given
   */
  // iconFallback: string;

  // icon: IconInfo;

  /**
   * click handler, emits own category
   */
  @Output() click: EventEmitter<Category> = new EventEmitter<Category>();

  constructor(private icons: IconsService) {}

  ngOnInit() {
    // default to icon color which defaults to primary
    if (!this.iconColor) this.iconColor = 'icon';

    // this.icon = await this.icons.getIconInfo(this.categoryItem.icon);
    // this.icons
    //   .getIconInfo(this.categoryItem.icon)
    //   .then((icon) => (this.icon = icon));
  }

  /**
   * emit category data
   */
  onClick() {
    this.click.emit(this.item);
  }
}
