/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { BackendService } from 'src/app/angular-services/backend.service';
import { Observable } from 'rxjs';
import { Category } from '@mojoapps1/mojoapps1common';
import { IconInfo, IconsService } from 'src/app/angular-services/icons.service';

@Component({
  selector: 'app-category-listitem',
  templateUrl: './category-listitem.component.html',
  styleUrls: ['./category-listitem.component.scss'],
})
export class CategoryListItemComponent implements OnInit {
  /**
   * the category object we're rendering
   */
  @Input() item: Category;
  /**
   * the color of the icon, an ionic color (i.e. "primary", "secondary", etc). defaults to primary
   */
  @Input() iconColor: string;
  /**
   * path to an svg icon
   */
  // iconSvgPath: string;
  /**
   * fallback ion-icon name if no svg given
   */
  // iconFallback: string;
  // services: Observable<Service[]>;
  servicesLabel: string;

  // icon: IconInfo;

  /**
   * click handler, emits own category
   */
  @Output() click: EventEmitter<Category> = new EventEmitter<Category>();

  constructor(
    private backendService: BackendService,
    private icons: IconsService
  ) {}

  async ngOnInit() {
    // default to primary color for icon
    if (!this.iconColor) this.iconColor = 'icon';

    // this.icon = await this.icons.getIconInfo(this.categoryItem.icon);

    // ignore disabled services
    this.backendService
      .getServicesForCategoryCached(this.item.id)
      .then((services) => {
        this.servicesLabel = services.map((s) => s.title).join(', ');
      });
  }

  /**
   * emit category data
   */
  onClick() {
    this.click.emit(this.item);
  }
}
