/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Service } from '@mojoapps1/mojoapps1common';
import { BackendService } from 'src/app/angular-services/backend.service';
import { IconInfo, IconsService } from 'src/app/angular-services/icons.service';
import { UIString } from 'src/app/lang/UIString';

@Component({
  selector: 'app-service-watched',
  templateUrl: './service-watched.component.html',
  styleUrls: ['./service-watched.component.scss'],
})
export class ServiceWatchedComponent implements OnInit {
  /**
   * the service object we're rendering
   */
  @Input() item: Service;
  /**
   * the color of the icon, an ionic color (i.e. "primary", "secondary", etc). defaults to primary
   */
  @Input() iconColor: string;
  /**
   * path to an svg icon
   */
  // iconSvgPath: string;
  /**
   * fallback ion-icon name if no svg given
   */
  // iconFallback: string;

  labelUnwatch: string;

  // icon: IconInfo;

  @Output() unWatch: EventEmitter<Service> = new EventEmitter();

  constructor(private icons: IconsService) {}

  async ngOnInit() {
    this.labelUnwatch = UIString.format('BTN_UNWATCH');

    if (!this.iconColor) this.iconColor = 'primary';

    // this.icon = await this.icons.getIconInfo(this.serviceItem.icon);
  }

  onUnWatch() {
    this.unWatch.emit(this.item);
    // this.backendService.removeWatchedService(this.serviceItem.id).then(() => {
    //   //temp
    //   console.log('Watched service removed');
    //   this.onUnWatch.emit(this.serviceItem);
    // });
  }
}
