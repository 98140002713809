/*
 * (c)2020, InterMedia Development Inc.  All rights reserved
 *
 * You may not use, distribute and modify this code without written permission from InterMedia Development Inc. <imd@webwurks.com>
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR HOLDERS INCLUDED IN THIS NOTICE
 * BE LIABLE FOR ANY CLAIM, OR ANY SPECIAL INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS,
 * WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
 *
 * Author: Kawika Heftel 2023/05/31
 */

import { Component, OnInit, Input } from '@angular/core';
import {
  AverageRating,
  Business,
  Service,
  ToglDataItem,
} from '@mojoapps1/mojoapps1common';
import { Router, ActivatedRoute } from '@angular/router';
import {
  ActiveNearby,
  BackendService,
} from 'src/app/angular-services/backend.service';
import { Subscription } from 'rxjs';
import { IncDecAnimation } from 'src/app/util/animations';
import { UIString } from 'src/app/lang/UIString';
import { IconInfo, IconsService } from 'src/app/angular-services/icons.service';
import { AlertService } from 'src/app/angular-services/alert.service';
import { FileLog } from 'src/app/angular-services/FileLog';
import { LocationService } from 'src/app/angular-services/location.service';

/**
 * represents a business search result
 */
export interface BizSearchResult {
  /**
   * the business
   */
  business: Business;
  /**
   * service the biz is togled on for
   */
  service: Service;
  /**
   * biz thumbnail url
   */
  thumbnail: string;

  /**
   * distance to business
   */
  distance?: number;

  /**
   * business rating
   */
  rating?: AverageRating;

  /**
   * metadata if any
   */
  metaData?: ToglDataItem[];
}

@Component({
  selector: 'app-discover-business-result',
  templateUrl: './discover-business-result.component.html',
  styleUrls: ['./discover-business-result.component.scss'],
  animations: [IncDecAnimation],
})
export class DiscoverBusinessResultComponent {
  /**
   * data to display
   */
  @Input() item: BizSearchResult;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private backendService: BackendService,
    private icons: IconsService,
    private alerts: AlertService,
    private filelog: FileLog,
    private location: LocationService
  ) {}

  /**
   * when the component is clicked
   */
  async onClick() {
    console.log('click');
    this.router.navigate([
      '/app/favorites/biz',
      this.item.business.businessID,
      'services',
    ]);
  }

  /**
   * template function, convert distance into label
   * @param value
   */
  distanceLabel(value: number) {
    if (!isNaN(value)) {
      return `${value.toFixed(2)} mi`;
    }
    return 'unknown mi';
  }
}
